import React, {
    createContext,
    useContext,
    useMemo,
    useState
} from "react";
import {
    Button
} from "react-bootstrap";
import {
    ErrorBoundary
} from "@sentry/react";

import ChartModal from "./ChartModal";
import UsersContext from "../../../context/UsersContext";
import ChartError from "./ChartError";

export const ChartCardContext = createContext(null);

function ChartCard({ title, children, userSelectable = false }) {
    const usersContext = useContext(UsersContext);

    const [showModal, setShowModal] = useState(false);

    const [selectedUser, setSelectedUser] = useState(null);

    const users = useMemo(() => {
        if(!userSelectable || !usersContext.users) {
            return [];
        }
        return usersContext.users.filter((user) => user.intranet);
    }, [userSelectable, usersContext.users]);
    const onUserSelect = useMemo(() => {
        return (event) => {
            const value = parseInt(event.target.value);
            setSelectedUser(value === -1 ? null : value);
        }
    }, []);

    return (
        <ChartCardContext.Provider value={{ selectedUser }}>
            <ChartModal
                title={ title }
                show={ showModal }
                handleClose={ () => setShowModal(false) }
                optionSelection={ userSelectable }
                options={ users }
                selectedOption={ selectedUser }
                onOptionSelection={ onUserSelect }
            >
                <ErrorBoundary fallback={<ChartError/>}>
                    { children }
                </ErrorBoundary>
            </ChartModal>
            <div className="card mb-3">
                <div className="card-body">
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <h5 className="card-title">{ title }</h5>
                        </div>
                        { userSelectable && (
                            <div className="ml-2">
                                <select
                                    className="form-control form-control-sm"
                                    value={ selectedUser === null ? -1 : selectedUser }
                                    onChange={ onUserSelect }
                                >
                                    <option value={ -1 }>Iedereen</option>
                                    { users.map((user) => (
                                        <option key={ user.id } value={ user.id }>
                                            { user.name }
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div className="ml-2">
                            <Button
                                variant="secondary"
                                size="sm"
                                onClick={ () => setShowModal(true) }
                            >
                                <i className="fas fa-expand fa-fw"/>
                            </Button>
                        </div>
                    </div>
                    <div className="w-100" style={{ height: "500px" }}>
                        <ErrorBoundary fallback={<ChartError/>}>
                            { children }
                        </ErrorBoundary>
                    </div>
                </div>
            </div>
        </ChartCardContext.Provider>
    )
}

export default React.memo(ChartCard);

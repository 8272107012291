import React from "react";
import {
    PageContainer,
    PageContent,
    PageFooter
} from "@zandor300/backoffice-framework";

import IntranetVersion from "./IntranetVersion";

function IntranetPageContainer({ children }) {

    return (
        <PageContainer>
            <PageContent>
                { children }
            </PageContent>
            <PageFooter>
                <IntranetVersion/>
            </PageFooter>
        </PageContainer>
    )
}

export default React.memo(IntranetPageContainer);

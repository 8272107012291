import axios from "axios";

import BaseAddProductModal from "../../../../components/modal/BaseAddProductModal";

class InstallationDetailReplaceProductModal extends BaseAddProductModal {
    abstractGetTitle() {
        return "Materiaal vervangen";
    }

    getInitialAmountValue() {
        if(!this.props.installationProduct) {
            return "";
        }
        return this.props.installationProduct.installationAmount.toString();
    }

    canSubmit() {
        if(this.props.installationProduct) {
            if(this.state.amount > this.props.installationProduct.installationAmount) {
                return false;
            }
        }
        return super.canSubmit();
    }

    abstractSave(productId, amount, onComplete, onError) {
        if(amount > this.props.installationProduct.installationAmount) {
            onError("Aantal kan niet hoger zijn dan aantal van oorspronkelijke materiaal.");
            return;
        }
        axios.post("/replaceInstallationProduct", {
            installationId: this.props.installationProduct.installationId,
            productId: this.props.installationProduct.id,
            newProductId: productId,
            amount
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.onSave(response.data.reserved, response.data.taken);
                    onComplete();
                } else {
                    onError("Er ging iets fout bij het vervangen van het materiaal. (" + response.data.error + ")");
                }
            })
            .catch((error) => {
                onError("Er ging iets fout bij het vervangen van het materiaal.");
                console.error(error);
            });
    }
}

export default InstallationDetailReplaceProductModal;

import React from "react";

function ChartError() {
    return (
        <div className="h-100 w-100 d-flex align-items-center text-center text-danger">
            <div className="w-100">
                <h1><i className="fas fa-exclamation-circle"/></h1>
                <h3>Error</h3>
                <p>Er is een fout opgetreden bij het laden van de grafiekdata.</p>
            </div>
        </div>
    )
}

export default React.memo(ChartError);

import React from "react";
import axios from "axios";
import moment from "moment";
import {
    Annotation
} from "@zandor300/react-apple-mapkitjs";
import {
    Title
} from "@zandor300/backoffice-framework";

import AppleMapsMap from "../../components/apple-maps/AppleMapsMap";
import Loading from "../../components/Loading";
import DateFormatter from "../../components/formatters/DateFormatter";

class RegisteredDevicesMap extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            devices: null,
            error: null
        }
    }

    componentDidMount() {
        this.getDeviceLocations();
    }

    getDeviceLocations() {
        axios.get("/getDeviceLocations")
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ devices: response.data.data });
                } else {
                    this.setState({ outages: null, error: this.getErrorMessage(response.data.error) });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ outages: null, error: this.getErrorMessage(null) });
            });
    }

    getErrorMessage(errorCode) {
        switch(errorCode) {
            case "SESSION_EXPIRED":
                return "Sessie verlopen. Log opnieuw in.";
            default:
                return "Er is een fout opgetreden. Probeer het later opnieuw." + (errorCode ? " (" + errorCode + ")" : "");
        }
    }

    getCenterCoordinate() {
        const {
            devices
        } = this.state;
        if(!devices) {
            return {
                centerLatitude: null, centerLongitude: null,
                latitudeSpan: null, longitudeSpan: null
            };
        }

        let minLat = null;
        let maxLat = null;
        let minLong = null;
        let maxLong = null;

        for(const device of devices) {
            const latitude = device.latitude;
            const longitude = device.longitude;
            if(!latitude || !longitude) {
                continue;
            }
            if(!minLat || latitude < minLat) {
                minLat = latitude;
            }
            if(!maxLat || latitude > maxLat) {
                maxLat = latitude;
            }
            if(!minLong || longitude < minLong) {
                minLong = longitude;
            }
            if(!maxLong || longitude > maxLong) {
                maxLong = longitude;
            }
        }

        if(!minLat || !maxLat || !minLong || !maxLong) {
            return {
                centerLatitude: null, centerLongitude: null,
                latitudeSpan: null, longitudeSpan: null
            };
        }

        const centerLatitude = (minLat + maxLat) / 2;
        const centerLongitude = (minLong + maxLong) / 2;
        const latitudeSpan = devices.length === 1 ? 0.007 : Math.abs(maxLat - minLat) * 1.3;
        const longitudeSpan = devices.length === 1 ? 0.007 : Math.abs(maxLong - minLong) * 1.3;
        return {
            centerLatitude, centerLongitude,
            latitudeSpan, longitudeSpan
        };
    }

    render() {
        const {
            centerLatitude, centerLongitude,
            latitudeSpan, longitudeSpan
        } = this.getCenterCoordinate();
        const {
            devices
        } = this.state;

        return (
            <React.Fragment>
                <Title preTitle="Overzicht">
                    Geregistreerde apparaten
                </Title>

                { !devices ? (
                    <Loading/>
                ) : (
                    <div className="row">
                        <div className="col-md">
                            <AppleMapsMap
                                latitude={ centerLatitude ? centerLatitude : 51.994459 }
                                longitude={ centerLongitude ? centerLongitude : 4.180937 }
                                spanLat={ latitudeSpan }
                                spanLong={ longitudeSpan }
                                height="750px"
                                width="100%"
                                initialMapType="hybrid"
                            >
                                { devices.map((device) => (
                                    <Annotation
                                        key={ device.id }
                                        latitude={ device.latitude }
                                        longitude={ device.longitude }
                                        color="#FF5000"
                                        title={ device.name }
                                    />
                                ))}
                            </AppleMapsMap>
                        </div>
                        <div className="col-md-4">
                            { devices.map((device) => {
                                const locationDate = moment(device.locationDate);
                                const old = locationDate < moment().subtract(5, "days");
                                return (
                                    <div
                                        className={ "card mb-2" + (old ? " border-danger" : "") }
                                        key={ device.id }
                                    >
                                        <div className="card-body">
                                            <h6 className="card-title">
                                                { device.name }
                                                <small className="text-muted float-right">
                                                    Status: { device.locationPermissionStatus }
                                                </small>
                                            </h6>
                                            <div className="row">
                                                <div className="col">
                                                    <small className="text-muted">
                                                        Locatie datum
                                                    </small>
                                                    <div className={ old ? "text-danger" : "" } style={{ fontSize: "0.9rem" }}>
                                                        <DateFormatter date={ device.locationDate }/>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <small className="text-muted">
                                                        Laatste login
                                                    </small>
                                                    <div style={{ fontSize: "0.9rem" }}>
                                                        <DateFormatter date={ device.lastLogin }/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

export default RegisteredDevicesMap;

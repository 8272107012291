import React, {
    useContext
} from "react";
import {
    Sidebar,
    SidebarDivider,
    SidebarLink
} from "@zandor300/backoffice-framework";

import AnimatedLogo from "../../AnimatedLogo";
import AuthenticatedUserContext from "../../../context/AuthenticatedUserContext";
import RemindersCountBadge from "./components/RemindersCountBadge";

function IntranetSidebar() {
    const authenticatedUserContext = useContext(AuthenticatedUserContext);
    return (
        <Sidebar
            logo={
                <AnimatedLogo activityIndicator/>
            }
        >
            <SidebarLink to="/reminders" icon="fas fa-alarm-clock">
                <div className="d-flex">
                    <div className="flex-grow-1">Herinneringen</div>
                    <RemindersCountBadge/>
                </div>
            </SidebarLink>
            <SidebarLink to="/images" icon="fas fa-images">
                Foto's
            </SidebarLink>

            <SidebarDivider/>

            <SidebarLink to="/dashboard" icon="fas fa-tachometer-alt">
                Dashboard
            </SidebarLink>
            <SidebarLink to="/leads" icon="fas fa-file-alt" additionalUrls={["/lead"]}>
                Leads
            </SidebarLink>
            <SidebarLink to="/installations" icon="fas fa-hard-hat" additionalUrls={["/installation"]}>
                Installaties
            </SidebarLink>
            <SidebarLink to="/planning" icon="fas fa-calendar-alt">
                Planning
            </SidebarLink>
            { authenticatedUserContext.user.invoicing && (
                <SidebarLink to="/invoicing" icon="fas fa-receipt">
                    Facturatie
                </SidebarLink>
            )}
            <SidebarLink to="/vat-refunds" icon="fas fa-coins" additionalUrls={["/vat-refund"]}>
                BTW Terugvraag
            </SidebarLink>
            <SidebarLink to="/outages" icon="fas fa-bolt" additionalUrls={["/outage"]}>
                Storingen
            </SidebarLink>
            <SidebarLink to="/orders" icon="fas fa-pallet-box" additionalUrls={["/order"]}>
                Bestellingen
            </SidebarLink>
            <SidebarLink to="/inventory" icon="fas fa-inventory">
                Voorraad
            </SidebarLink>
            <SidebarLink to="/products" icon="fas fa-solar-panel">
                Producten
            </SidebarLink>
            <SidebarLink to="/absence" icon="fas fa-user-check">
                Absentie
            </SidebarLink>

            <SidebarDivider/>

            { authenticatedUserContext.user.admin && (
                <SidebarLink to="/users" icon="fas fa-users">
                    Gebruikers
                </SidebarLink>
            )}
            <SidebarLink to="/statistics" icon="fas fa-chart-simple">
                Statistieken
            </SidebarLink>
        </Sidebar>
    );
}

export default React.memo(IntranetSidebar);

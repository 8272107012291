import React, {
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";

import SelectionChartCard from "./SelectionChartCard";
import ChartLoading from "./ChartLoading";

function LeadStatusSelectionChartCard({ children, ...restProps }) {
    const [leadStatuses, setLeadStatuses] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLeadStatuses(null);
        setError(null);
        axios.get("/getLeadStatuses")
            .then((response) => {
                if(response.data.valid) {
                    const statuses = response.data.data.sort((status1, status2) => {
                        if(status1.priority > status2.priority) return -1;
                        if(status1.priority < status2.priority) return 1;
                        return 0;
                    });
                    setLeadStatuses(statuses);
                } else {
                    setError("Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setError("Er ging iets fout. Probeer het later opnieuw.");
            })
    }, [setLeadStatuses, setError]);

    return (
        <SelectionChartCard { ...restProps } options={ leadStatuses ? leadStatuses : [] }>
            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : !leadStatuses ? (
                <ChartLoading/>
            ) : (
                children
            )}
        </SelectionChartCard>
    )
}

export default React.memo(LeadStatusSelectionChartCard);

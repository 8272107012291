import React, {
    useMemo,
    useState
} from "react";
import {
    Button
} from "react-bootstrap";

import ChartModal from "./ChartModal";
import {
    ChartCardContext
} from "./ChartCard";

function ChartCard({ title, children, options = [] }) {
    const [showModal, setShowModal] = useState(false);

    const [selectedOption, setSelectedOption] = useState(null);

    const onOptionSelection = useMemo(() => {
        return (event) => {
            const value = parseInt(event.target.value);
            setSelectedOption(value === -1 ? null : value);
        }
    }, []);

    const contextState = useMemo(() => {
        return { selectedOption };
    }, [selectedOption]);

    console.log(contextState);

    return (
        <ChartCardContext.Provider value={contextState}>
            <ChartModal
                title={ title }
                show={ showModal }
                handleClose={ () => setShowModal(false) }
                optionSelection={ options && options.length > 0 }
                options={ options }
                selectedOption={ selectedOption }
                onOptionSelection={ onOptionSelection }
            >
                { children }
            </ChartModal>
            <div className="card mb-3">
                <div className="card-body">
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <h5 className="card-title">{ title }</h5>
                        </div>
                        { options && options.length > 0 && (
                            <div className="ml-2">
                                <select
                                    className="form-control form-control-sm"
                                    value={ selectedOption === null ? -1 : selectedOption }
                                    onChange={ onOptionSelection }
                                >
                                    <option value={ -1 }>Alle</option>
                                    { options.map((option) => (
                                        <option key={ option.id } value={ option.id } style={{ backgroundColor: option.color, color: option.color ? "#FFF" : undefined }}>
                                            { option.name }
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div className="ml-2">
                            <Button
                                variant="secondary"
                                size="sm"
                                onClick={ () => setShowModal(true) }
                            >
                                <i className="fas fa-expand fa-fw"/>
                            </Button>
                        </div>
                    </div>
                    <div className="w-100" style={{ height: "500px" }}>
                        { children }
                    </div>
                </div>
            </div>
        </ChartCardContext.Provider>
    )
}

export default React.memo(ChartCard);

import React, {
    useEffect,
    useMemo,
    useState
} from "react";
import {
    Alert,
    Table
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import Loading from "../../components/Loading";
import moment from "moment";
import axios from "axios";

function ProductsForecast() {
    const year = useMemo(() => {
        return 2023;
    }, []);
    const productIds = useMemo(() => {
        return [
            65, 66, 67, 4, 301, 1, 302, 380, 2, 303, 73, 304, 381, 5, 305, 382, 31, 306, 383, 32, 384, 343, 314, 74, 33, 183, 226, 329, 34, 35, 223, 36, 224, 330, 37, 38, 225, 331, 39, 40, 41, 42, 131, 43, 44, 116, 113, 156, 45, 114, 46, 47, 159, 115, 163
        ];
    }, []);

    const [data, setData] = useState(null);
    const [total, setTotal] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        setData(null);
        setError(null);
        axios.post("/getInstallationProductsForecast", {
            productIds: productIds.join(","),
            year
        })
            .then((response) => {
                if(response.data.valid) {
                    const data = response.data.data;
                    setData(data);
                    let total = 0;
                    data.forEach((product) => {
                        total += product.productTotal;
                    });
                    setTotal(total);
                } else {
                    setError("Error");
                }
            })
            .catch((error) => {
                console.error(error);
                setError("Error");
            })
    }, [productIds, year]);

    return (
        <React.Fragment>
            <Title preTitle="Overzicht">
                Product voorspelling
            </Title>

            { error ? (
                <Alert variant="danger">{ error }</Alert>
            ) : !data ? (
                <Loading/>
            ) : (
                <Table>
                    <thead>
                    <tr>
                        <th/>
                        { data.map((product) => (
                            <th key={ product.product.id }>
                                <span className="text-vertical">
                                    { product.product.shortName ? product.product.shortName : product.product.name }
                                </span>
                            </th>
                        ))}
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    { [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => (
                        <tr key={ month }>
                            <th>{ month }</th>
                            { data.map((product) => {
                                const firstDate = moment(`${year}-${month}-01`);
                                const forecastMonth = product.forecast[firstDate.format("YYYY-MM")];
                                return (
                                    <td key={ product.product.id } className="text-right">
                                        { forecastMonth === 0 ? (
                                            <span className="text-muted">{ forecastMonth }</span>
                                        ) : forecastMonth }
                                    </td>
                                )
                            })}
                            <td/>
                        </tr>
                    ))}
                    <tr>
                        <th>Totaal</th>
                        { data.map((product) => (
                            <td key={ product.product.id } className="text-right">
                                <b>{ product.productTotal }</b>
                            </td>
                        ))}
                        <td className="text-right"><b>{ total }</b></td>
                    </tr>
                    </tbody>
                </Table>
            )}
        </React.Fragment>
    )
}

export default React.memo(ProductsForecast);

import React, {
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";

import Loading from "../../../components/Loading";
import DateFormatter from "../../../components/formatters/DateFormatter";

function UserDetailLoginAttemptsPincode({ user }) {
    const [loginAttempts, setLoginAttempts] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if(!user) {
            return;
        }
        axios.post("/getUserLoginAttemptsPincode", { userId: user.id })
            .then((response) => {
                if(response.data.valid) {
                    setLoginAttempts(response.data.data);
                } else {
                    setError("Error (" + response.data.error + ")");
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setError("Error");
            });
    }, [user]);

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        )
    }
    if(!user || !loginAttempts) {
        return (
            <Loading/>
        )
    }
    return loginAttempts.map((loginAttempt) => (
        <div className="card mb-2" key={ loginAttempt.id }>
            <div className="card-body">
                <div className="row">
                    <div className="col">
                        <DateFormatter date={ loginAttempt.date }/>
                    </div>
                    <div className="col">
                        <span className="text-muted">
                            #{ loginAttempt.device.id }
                        </span>
                        {" "}
                        { loginAttempt.device.name }
                    </div>
                </div>
            </div>
        </div>
    ));
}

export default React.memo(UserDetailLoginAttemptsPincode);

import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal
} from "react-bootstrap";

import Loading from "../../../../components/Loading";
import DateFormatter from "../../../../components/formatters/DateFormatter";

function InstallationProductsHistoryItemCard({ historyItem, type, index }) {
    const reserved = !historyItem.currentState.reserved ? [] : historyItem.currentState.reserved;
    const taken = !historyItem.currentState.taken ? [] : historyItem.currentState.taken;

    let classNames = ["card mb-2"];
    switch(type) {
        case "original":
            classNames.push("border-primary");
            break;
        case "current":
            classNames.push("border-success");
            break;
        default:
            break;
    }

    return (
        <div className={ classNames.join(" ") } key={ index }>
            { type === "original" ? (
                <div className="card-header text-primary">
                    <b>Origineel</b>
                </div>
            ) : type === "current" && (
                <div className="card-header text-success">
                    <b>Huidig</b>
                </div>
            )}
            <div className="card-body">
                { historyItem.date && (
                    <div className="float-right">
                        <DateFormatter date={ historyItem.date }/>
                    </div>
                )}
                { reserved.length === 0 && taken.length === 0 ? (
                    <i className="text-muted">Leeg</i>
                ) : (
                    <React.Fragment>
                        { reserved.length > 0 && (
                            <React.Fragment>
                                <h5 className="card-title">
                                    Gereserveerd materiaal
                                </h5>
                                { reserved.map((historyProduct, index) => (
                                    <div key={ index }>
                                        { historyProduct.amount }x { historyProduct.product.brand.name } { historyProduct.product.name }
                                    </div>
                                ))}
                            </React.Fragment>
                        )}
                        { reserved.length > 0 && taken.length > 0 && (
                            <div style={{ height: "20px" }}/>
                        )}
                        { taken.length > 0 && (
                            <React.Fragment>
                                <h5 className="card-title">
                                    Afgenomen materiaal
                                </h5>
                                { taken.map((historyProduct, index) => (
                                    <div key={ index }>
                                        { historyProduct.amount }x { historyProduct.product.brand.name } { historyProduct.product.name }
                                    </div>
                                ))}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </div>
            { historyItem.author && (
                <div className="card-footer text-muted">
                    <b>{ historyItem.author.name } </b>
                </div>
            )}
        </div>
    )
}

class InstallationDetailHistoryModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            history: null,
            error: null
        }
        this.onShow = this.onShow.bind(this);
        this.getHistory = this.getHistory.bind(this);
    }

    onShow() {
        this.getHistory();
    }

    getHistory() {
        this.setState({ history: null, error: null });
        axios.post("/getInstallationProductsHistory", {
            installationId: this.props.installationId
        })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ history: response.data.data.reverse() });
                } else {
                    this.setState({ saving: true, error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ saving: true, error: "Er ging iets fout. Probeer het later opnieuw." });
                console.error(error);
            });
    }

    render() {
        const {
            show,
            handleClose
        } = this.props;
        const {
            history,
            error
        } = this.state;
        return (
            <React.Fragment>
                <Modal size="lg" show={ show } onHide={ handleClose } onShow={ this.onShow }>
                    <Modal.Header closeButton>
                        <Modal.Title>Geschiedenis</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        { error ? (
                            <Alert variant="danger">{ error }</Alert>
                        ) : !history ? (
                            <Loading/>
                        ) : history.map((historyItem, index) => {
                            let type = "regular";
                            if(index === 0) {
                                type = "current";
                            } else if(index >= history.length - 1) {
                                type = "original";
                            }
                            return (
                                <InstallationProductsHistoryItemCard
                                    historyItem={ historyItem }
                                    type={ type }
                                    key={ index }
                                    index={ index }
                                />
                            );
                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={ handleClose }>
                            Sluiten
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}

export default InstallationDetailHistoryModal;

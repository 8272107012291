import React, {
    useContext
} from "react";
import {
    Alert,
    Button,
    Modal
} from "react-bootstrap";

import {
    PlanningContext
} from "../context/PlanningContext";

function PlanningFilterModal({ show, handleClose }) {
    const planningContext = useContext(PlanningContext);
    return (
        <Modal show={ show } onHide={ handleClose }>
            <Modal.Header>
                <Modal.Title closeButton>
                    Filters aanpassen
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { planningContext.showAvailabilityCheckboxMatch && (
                    <React.Fragment>
                        <div className="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="showAvailability"
                                checked={ planningContext.showAvailability }
                                onChange={ (event) => planningContext.setShowAvailability(event.target.checked) }
                            />
                            <label className="custom-control-label" htmlFor="showAvailability">
                                Beschikbaarheid tonen
                            </label>
                        </div>
                        <div className="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="showAllProducts"
                                checked={ planningContext.showAllProducts && planningContext.showAvailability }
                                onChange={ (event) => planningContext.setShowAllProducts(event.target.checked) }
                                disabled={ !planningContext.showAvailability }
                            />
                            <label className="custom-control-label" htmlFor="showAllProducts">
                                Alle producten tonen
                            </label>
                        </div>
                    </React.Fragment>
                )}
                { planningContext.showAbsenceCheckboxMatch && (
                    <div className="custom-control custom-checkbox">
                        <input
                            type="checkbox"
                            className="custom-control-input"
                            id="showAbsences"
                            checked={ planningContext.showAbsences }
                            onChange={ (event) => planningContext.setShowAbsences(event.target.checked) }
                        />
                        <label className="custom-control-label" htmlFor="showAbsences">
                            Absenties tonen
                        </label>
                    </div>
                )}
                { planningContext.showFilterSelect && (
                    <React.Fragment>
                        <div className="mt-3">
                            <div className="mb-2">Planning Type Filter</div>
                            <div>
                                { planningContext.planningItemTypeFilterOptions.map((option) => (
                                    <Button
                                        key={ option.value }
                                        variant={ planningContext.planningItemTypeFilter === option.value ? "primary" : "outline-secondary" }
                                        size="sm"
                                        className="mr-2 rounded-pill"
                                        onClick={ () => planningContext.setPlanningItemTypeFilter(option.value) }
                                    >
                                        { option.name }
                                    </Button>
                                ))}
                            </div>
                        </div>
                        <div className="mt-3">
                            <div className="mb-2">Installatie Type Filter</div>
                            <div>
                                { planningContext.installationTypeFilterOptions.map((option) => (
                                    <Button
                                        key={ option.value }
                                        variant={ planningContext.installationTypeFilter === option.value ? "primary" : "outline-secondary" }
                                        size="sm"
                                        className="mr-2 rounded-pill"
                                        onClick={ () => planningContext.setInstallationTypeFilter(option.value) }
                                    >
                                        { option.name }
                                    </Button>
                                ))}
                            </div>
                            { !planningContext.currentInstallationTypeFilter?.showsAll && (
                                <Alert variant="warning" className="mt-2 mb-0">
                                    Deze filterfunctie is gebaseerd op Lead type. Indien geen Lead type is geselecteerd voor
                                    een bepaald planning item wordt deze ook niet getoond indien een filter is geselecteerd.
                                </Alert>
                            )}
                        </div>
                    </React.Fragment>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={ handleClose } variant="secondary">
                    Sluiten
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default React.memo(PlanningFilterModal);

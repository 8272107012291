import React, {
    useContext,
    useEffect,
    useState
} from "react";
import {
    Link
} from "react-router-dom";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import CallMonitorContext from "../../context/CallMonitorManager";

function CurrentCallInfo() {
    const [show, setShow] = useState(false);
    const callMonitor = useContext(CallMonitorContext);

    useEffect(() => {
        setTimeout(() => {
            setShow(callMonitor.showCallInfo);
        }, 20);
    }, [callMonitor.showCallInfo]);

    if(!callMonitor || !callMonitor.phoneNumber) {
        return null;
    }

    return (
        <div
            className="call-info-container mr-2"
            style={{
                opacity: show ? 1 : 0
            }}
        >
            <OverlayTrigger placement="bottom" overlay={
                <Tooltip id="call-info">
                    Huidig telefoongesprek
                    <br/>
                    <span className="text-muted">
                        { callMonitor.type === "inbound" ? (
                            "Inkomend"
                        ) : callMonitor.type === "outbound" && (
                            "Uitgaand"
                        )}
                    </span>
                </Tooltip>
            }>
                <span className="badge badge-pill badge-dark badge-call-info py-2 px-3">
                    { callMonitor.calling ? (
                        <i className="fas fa-phone fa-fw mr-1 text-success"/>
                    ) : (
                        <i className="fas fa-phone-slash fa-fw mr-1 text-danger"/>
                    )}
                    { callMonitor.type === "inbound" ? (
                        <i className="fas fa-arrow-to-bottom mr-2"/>
                    ) : callMonitor.type === "outbound" && (
                        <i className="fas fa-arrow-from-bottom mr-2"/>
                    )}
                    { callMonitor.displayedPhoneNumber }
                </span>
            </OverlayTrigger>
            <OverlayTrigger placement="bottom" overlay={
                <Tooltip id="call-search">
                    Zoeken in leads naar telefoonnummer
                </Tooltip>
            }>
                <Link to={ callMonitor.searchLink } className="ml-1">
                    <span className="badge badge-pill badge-dark badge-call-info p-2">
                        <i className="fas fa-search fa-fw"/>
                    </span>
                </Link>
            </OverlayTrigger>
        </div>
    )
}

export default React.memo(CurrentCallInfo);

import React from "react";
import {
    Redirect,
    Route,
    Switch
} from "react-router-dom";

import Users from "./UsersPage";

function UsersSwitch(props) {
    return (
        <Switch>
            <Route path={`${props.match.path}/:filter`} component={Users}/>
            <Route path={`${props.match.path}`}>
                <Redirect
                    to={{
                        pathname: `${props.match.path}/enabled`,
                        state: { from: props.match.path }
                    }}
                />
            </Route>
        </Switch>
    )
}

export default React.memo(UsersSwitch);

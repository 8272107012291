import React from "react";
import moment from "moment";

import ChartCard from "../components/ChartCard";

import InstalledSolarPanelsCountPerMonthChart from "../charts/InstalledSolarPanelsCountPerMonthChart";
import InstalledSolarPanelsCountPerYearChart from "../charts/InstalledSolarPanelsCountPerYearChart";
import InstallationsCountPerMonthChart from "../charts/InstallationsCountPerMonthChart";
import InstalledInvertersCountPerYearChart from "../charts/InstalledInvertersCountPerYearChart";
import InstalledInvertersPercentageChart from "../charts/InstalledInvertersPercentageChart";

function InstallationsStatistics() {
    return (
        <React.Fragment>
            <ChartCard title="Aantal installaties per maand">
                <InstallationsCountPerMonthChart
                    startDate={ moment().subtract(1, "year").startOf("week") }
                    endDate={ moment() }
                />
            </ChartCard>
            <ChartCard title="Aantal installaties per maand met SolarEdge">
                <InstallationsCountPerMonthChart
                    productBrandId={ 1 }
                    startDate={ moment().subtract(1, "year").startOf("week") }
                    endDate={ moment() }
                />
            </ChartCard>
            <ChartCard title="Aantal geïnstalleerde zonnepanelen per maand">
                <InstalledSolarPanelsCountPerMonthChart
                    startDate={ moment().subtract(1, "year").startOf("week") }
                    endDate={ moment().add(2, "month").endOf("month") }
                />
            </ChartCard>
            <ChartCard title="Aantal geïnstalleerde zonnepanelen per maand met SolarEdge">
                <InstalledSolarPanelsCountPerMonthChart
                    productBrandId={ 1 }
                    startDate={ moment().subtract(1, "year").startOf("week") }
                    endDate={ moment().add(2, "month").endOf("month") }
                />
            </ChartCard>
            <ChartCard title="Aantal geïnstalleerde zonnepanelen per year">
                <InstalledSolarPanelsCountPerYearChart
                    startDate={ moment("2021-01-01") }
                    endDate={ moment() }
                />
            </ChartCard>
            <ChartCard title="Hoeveelheid geïnstalleerde zonnepanelen capaciteit per maand">
                <InstalledSolarPanelsCountPerMonthChart
                    startDate={ moment().subtract(1, "year").startOf("week") }
                    endDate={ moment().add(2, "month").endOf("month") }
                    capacity
                />
            </ChartCard>
            <ChartCard title="Hoeveelheid geïnstalleerde zonnepanelen capaciteit per maand met SolarEdge">
                <InstalledSolarPanelsCountPerMonthChart
                    productBrandId={ 1 }
                    startDate={ moment().subtract(1, "year").startOf("week") }
                    endDate={ moment().add(2, "month").endOf("month") }
                    capacity
                />
            </ChartCard>
            <ChartCard title="Hoeveelheid geïnstalleerde zonnepanelen capaciteit per jaar">
                <InstalledSolarPanelsCountPerYearChart
                    startDate={ moment("2021-01-01") }
                    endDate={ moment() }
                    capacity
                />
            </ChartCard>
            <ChartCard title="Hoeveelheid geïnstalleerde zonnepanelen capaciteit per jaar met SolarEdge">
                <InstalledSolarPanelsCountPerYearChart
                    productBrandId={ 1 }
                    startDate={ moment("2021-01-01") }
                    endDate={ moment() }
                    capacity
                />
            </ChartCard>
            <ChartCard title="Hoeveelheid geïnstalleerd omvormervermogen per kwartaal">
                <InstalledInvertersCountPerYearChart
                    startDate={ moment("2022-01-01") }
                    endDate={ moment() }
                    capacity
                    periodGrouping="quarter"
                />
            </ChartCard>
            <ChartCard title="Hoeveelheid geïnstalleerd omvormervermogen per kwartaal met SolarEdge">
                <InstalledInvertersCountPerYearChart
                    productBrandId={ 1 }
                    startDate={ moment("2022-01-01") }
                    endDate={ moment() }
                    capacity
                    periodGrouping="quarter"
                />
            </ChartCard>
            <ChartCard title="Percentage geïnstalleerd omvormervermogen per kwartaal met SolarEdge t.o.v. totaal">
                <InstalledInvertersPercentageChart
                    productBrandId={ 1 }
                    startDate={ moment("2022-01-01") }
                    endDate={ moment() }
                    capacity
                    periodGrouping="quarter"
                />
            </ChartCard>
            <ChartCard title="Hoeveelheid geïnstalleerd omvormervermogen per jaar">
                <InstalledInvertersCountPerYearChart
                    startDate={ moment("2022-01-01") }
                    endDate={ moment() }
                    capacity
                />
            </ChartCard>
            <ChartCard title="Hoeveelheid geïnstalleerd omvormervermogen per jaar met SolarEdge">
                <InstalledInvertersCountPerYearChart
                    productBrandId={ 1 }
                    startDate={ moment("2022-01-01") }
                    endDate={ moment() }
                    capacity
                />
            </ChartCard>
        </React.Fragment>
    )
}

export default React.memo(InstallationsStatistics);

import React, {
    useCallback,
    useMemo
} from "react";
import {
    Alert,
    Dropdown
} from "react-bootstrap";

import Loading from "../../Loading";
import TagPill from "../../tagPill";

function SearchFilter({ searchQuery, setSearchQuery, title, filterKey, values = [], error }) {
    const selectedValues = useMemo(() => {
        const searchQueryTokens = searchQuery.split(" ");
        for(let i = 0; i < searchQueryTokens.length; i++) {
            const searchQueryToken = searchQueryTokens[i].trim();
            if(searchQueryToken.startsWith(filterKey + "=")) {
                const valuesString = searchQueryToken.split("=")[1];
                if(valuesString.length === 0) {
                    return [];
                } else {
                    return valuesString.split(",");
                }
            }
        }
        return [];
    }, [searchQuery, filterKey]);

    const onSelect = useCallback((value) => {
        setSearchQuery((prevSearchQuery) => {
            let searchQueryTokens = prevSearchQuery.split(" ");
            let foundKey = false;
            for(let i = 0; i < searchQueryTokens.length; i++) {
                const searchQueryToken = searchQueryTokens[i].trim();
                if(!searchQueryToken.startsWith(filterKey + "=")) {
                    continue;
                }

                foundKey = true;
                const valuesString = searchQueryToken.split("=")[1];
                if(valuesString.length === 0) {
                    searchQueryTokens[i] = filterKey + "=" + value.value;
                    break;
                }

                let valuesStringValues = valuesString.split(",");
                if(!valuesStringValues.includes(value.value)) {
                    valuesStringValues.push(value.value);
                } else {
                    if(valuesStringValues.length === 1) {
                        // Remove the entire token and break early
                        searchQueryTokens = searchQueryTokens.filter((searchToken) => {
                            return !searchToken.startsWith(filterKey + "=");
                        });
                        break;
                    }
                    valuesStringValues = valuesStringValues.filter((searchValue) => {
                        return searchValue !== value.value;
                    });
                }
                searchQueryTokens[i] = filterKey + "=" + valuesStringValues.join(",");
                break;
            }
            if(!foundKey) {
                searchQueryTokens.push(filterKey + "=" + value.value);
            }
            return searchQueryTokens.join(" ");
        });
    }, [setSearchQuery, filterKey]);
    const onClear = useCallback(() => {
        selectedValues.forEach((value) => {
            onSelect({ value });
        });
    }, [onSelect, selectedValues]);

    return (
        <Dropdown className="align-self-center" alignRight>
            <Dropdown.Toggle
                variant={ "tabbar-filter" + (selectedValues.length > 0 ? " active" : "") }
                id={ "filter-" + filterKey }
            >
                { title }
                { selectedValues.length > 0 && (
                    <span className="ml-2">
                        ({ selectedValues.length })
                    </span>
                )}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                { error ? (
                    <Alert variant="danger" className="mx-3 my-2" style={{ minWidth: "250px" }}>{ error }</Alert>
                ) : !values ? (
                    <Loading/>
                ) : (
                    <React.Fragment>
                        { selectedValues.length > 0 && (
                            <Dropdown.Item onClick={onClear} className="pl-3 text-danger">
                                <i
                                    className="fas fa-trash fa-fw mr-3"
                                    style={{
                                        width: "16px"
                                    }}
                                />
                                Selectie verwijderen
                            </Dropdown.Item>
                        )}
                        { values.map((value) => {
                            const onClick = () => {
                                onSelect(value);
                            }
                            const selected = selectedValues.includes(value.value);
                            return (
                                <Dropdown.Item onClick={ onClick } className="pl-3" key={ value.value }>
                                    <div className="custom-control custom-checkbox">
                                        <input
                                            type="checkbox"
                                            className="custom-control-input pointer-cursor"
                                            id="customCheck1" checked={ selected }
                                            onChange={ onClick }
                                        />
                                        <label className="custom-control-label pointer-cursor pl-2" htmlFor="customCheck1">
                                            { value.tagPill ? (
                                                <TagPill color={ value.color }>{ value.name }</TagPill>
                                            ) : (
                                                value.name
                                            )}
                                        </label>
                                    </div>
                                </Dropdown.Item>
                            )
                        })}
                    </React.Fragment>
                )}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default React.memo(SearchFilter);

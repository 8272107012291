import React, {
    useCallback,
    useMemo
} from "react";

function RadioChip({ checked, onClick, className, children, disabled }) {
    const classNames = useMemo(() => {
        let classNameArray = ["rounded-pill", "btn", "btn-sm", "px-3"];
        if(checked) {
            classNameArray.push("btn-primary");
        } else {
            classNameArray.push("btn-outline-secondary");
        }
        if(className) {
            classNameArray.push(className);
        }
        return classNameArray;
    }, [className, checked]);
    const onClickInternal = useCallback((event) => {
        event.preventDefault();
        onClick?.();
    }, [onClick]);
    return (
        <button
            className={ classNames.join(" ") }
            onClick={ onClickInternal }
            disabled={ disabled }
        >
            { children }
        </button>
    )
}

export default React.memo(RadioChip);

import React, {
    useContext
} from "react";
import moment from "moment";

import AuthenticatedUserContext from "../../../context/AuthenticatedUserContext";
import ChartCard from "../components/ChartCard";

import CompletedLeadsCountChart from "../charts/CompletedLeadsCountChart";
import CompletedLeadsSolarPanelsCountPerUserPerMonthChart
    from "../charts/CompletedLeadsSolarPanelsCountPerUserPerMonthChart";
import CompletedLeadsSolarPanelsCountPerUserPerYearChart
    from "../charts/CompletedLeadsSolarPanelsCountPerUserPerYearChart";

function SalesStatistics() {
    const authenticatedUser = useContext(AuthenticatedUserContext);
    return (
        <React.Fragment>
            <ChartCard title="Aantal leads verkocht per week per werknemer">
                <CompletedLeadsCountChart
                    startDate={ moment().subtract(2, "year").startOf("week") }
                    endDate={ moment() }
                    periodGrouping="week"
                    groupBy="User"
                />
            </ChartCard>
            <ChartCard title="Aantal leads verkocht per week per leadtype">
                <CompletedLeadsCountChart
                    startDate={ moment().subtract(2, "year").startOf("week") }
                    endDate={ moment() }
                    periodGrouping="week"
                    groupBy="LeadType"
                />
            </ChartCard>
            <ChartCard title="Aantal leads verkocht per maand per werknemer">
                <CompletedLeadsCountChart
                    startDate={ moment().year(2019).month(9).startOf("month") }
                    endDate={ moment() }
                    periodGrouping="month"
                    groupBy="User"
                />
            </ChartCard>
            <ChartCard title="Aantal leads verkocht per maand per leadtype">
                <CompletedLeadsCountChart
                    startDate={ moment().year(2019).month(9).startOf("month") }
                    endDate={ moment() }
                    periodGrouping="month"
                    groupBy="LeadType"
                />
            </ChartCard>
            <ChartCard title="Aantal leads verkocht per maand per leadbron">
                <CompletedLeadsCountChart
                    startDate={ moment().year(2019).month(9).startOf("month") }
                    endDate={ moment() }
                    periodGrouping="month"
                    groupBy="LeadSource"
                />
            </ChartCard>
            { authenticatedUser.user.admin && (
                <ChartCard title="Aantal leads verkocht per jaar">
                    <CompletedLeadsCountChart
                        startDate={ moment().year(2020).startOf("year") }
                        endDate={ moment() }
                        periodGrouping="year"
                        groupBy="User"
                    />
                </ChartCard>
            )}
            { authenticatedUser.user.admin && (
                <ChartCard title="Aantal zonnepanelen verkocht per maand">
                    <CompletedLeadsSolarPanelsCountPerUserPerMonthChart
                        startDate={ moment().year(2022).startOf("year") }
                        endDate={ moment() }
                    />
                </ChartCard>
            )}
            { authenticatedUser.user.admin && (
                <ChartCard title="Aantal zonnepanelen verkocht per jaar">
                    <CompletedLeadsSolarPanelsCountPerUserPerYearChart
                        startDate={ moment().year(2022).startOf("year") }
                        endDate={ moment() }
                    />
                </ChartCard>
            )}
        </React.Fragment>
    )
}

export default React.memo(SalesStatistics);

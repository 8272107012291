import React from "react";
import {
    Link
} from "react-router-dom";

import TagPill from "../../components/tagPill.js";
import DateFormatter from "../../components/formatters/DateFormatter";
import CurrentUserHighlight from "../../components/CurrentUserHighlight";
import {
    searchMatch
} from "../../utilities/searchMatch";

class LeadRow extends React.PureComponent {

    SearchActive() {
        if(this.props.searchQuery) {
            const splitSearchQuery = this.props.searchQuery.split(" ");
            // Loop through every search query item
            for(let searchQueryIndex = 0; searchQueryIndex < splitSearchQuery.length; searchQueryIndex++) {
                const searchQuery = (splitSearchQuery[searchQueryIndex] || "").toLowerCase();
                if(searchQuery.indexOf("=") !== -1) {
                    const [searchKey, searchValue] = searchQuery.split("=");
                    const searchValues = searchValue.split(",");
                    switch(searchKey) {
                        default:
                        case "id":
                            if(parseInt(this.props.lead.id) !== parseInt(searchValue))
                                return false;
                            break;
                        case "auteur":
                        case "author":
                            if((this.props.lead.author ? this.props.lead.author.name.replace(/\s/g,"") : "automatisch").toLowerCase().indexOf(searchValue) === -1
                                && (this.props.lead.author ? this.props.lead.author.name.replace(/\s/g,"") : "automatic").toLowerCase().indexOf(searchValue) === -1)
                                return false;
                            break;
                        case "phonenumber":
                        case "telefoonnummer":
                        case "telefoon":
                            if(this.props.lead.phoneNumber.replace(/\s/g,"").toLowerCase().indexOf(searchValue) === -1)
                                return false;
                            break;
                        case "email":
                            if(this.props.lead.email.replace(/\s/g,"").toLowerCase().indexOf(searchValue) === -1)
                                return false;
                            break;
                        case "status":
                            const status = this.props.lead.status;
                            if(parseInt(status.id) !== parseInt(searchValue)
                            && !searchMatch(status.name, searchValues))
                                return false;
                            break;
                        case "type":
                            const type = this.props.lead.type;
                            if((!type || parseInt(type.id) !== parseInt(searchValue))
                                && !searchMatch(type?.name ?? "Onbepaald", searchValues)
                                && !searchMatch(type?.name ?? "Undetermined", searchValues))
                                return false;
                            break;
                        case "source":
                        case "bron":
                            const source = this.props.lead.source;
                            if(parseInt(source.id) !== parseInt(searchValue)
                                && !searchMatch(source.name ?? "Onbepaald", searchValues))
                                return false;
                            break;
                        case "name":
                        case "naam":
                            if(this.props.lead.name.replace(/\s/g,"").toLowerCase().indexOf(searchValue) === -1)
                                return false;
                            break;
                        case "address":
                        case "adres":
                            if((this.props.lead.street + this.props.lead.houseNumber + "," + this.props.lead.city).replace(/\s/g,"").toLowerCase().indexOf(searchValue) === -1)
                                return false;
                            break;
                        case "assigneduser":
                        case "toegewezenwerknemer":
                            let retValue = false;
                            if((this.props.lead.assignedUser ? this.props.lead.assignedUser.id.toString() : "nobody").toLowerCase().indexOf(searchValue) !== -1
                                || (this.props.lead.assignedUser ? this.props.lead.assignedUser.id.toString() : "niemand").toLowerCase().indexOf(searchValue) !== -1)
                                retValue = true;

                            if(this.props.lead.assignedUser && this.props.lead.assignedUser.name.replace(/\s/g,"").toLowerCase().indexOf(searchValue) !== -1)
                                retValue = true;

                            if(this.props.lead.assignedUser && parseInt(this.props.lead.assignedUser.id) === parseInt(this.props.currentUserId) && (searchValue === "me" || searchValue === "ik"))
                                retValue = true;

                            if(!retValue)
                                return false;

                            break;
                    }
                }
                else {
                    // Lead ID
                    if(parseInt(this.props.lead.id) === parseInt(searchQuery))
                        continue;

                    // Author
                    if((this.props.lead.author ? this.props.lead.author.name.replace(/\s/g,"") : "automatisch").toLowerCase().indexOf(searchQuery) !== -1
                        || (this.props.lead.author ? this.props.lead.author.name.replace(/\s/g,"") : "automatic").toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Phone number
                    if(this.props.lead.phoneNumber.replace(/\s/g,"").toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Email
                    if(this.props.lead.email.replace(/\s/g,"").toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Type
                    if((this.props.lead.type && (parseInt(this.props.lead.type.id) === parseInt(searchQuery)))
                        || (this.props.lead.type? this.props.lead.type.name.replace(/\s/g,"") : "Onbepaald").toLowerCase().indexOf(searchQuery) !== -1
                        || (this.props.lead.type? this.props.lead.type.name.replace(/\s/g,"") : "Undetermined").toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Status
                    if(parseInt(this.props.lead.status.id) === parseInt(searchQuery)
                        || this.props.lead.status.name.replace(/\s/g,"").toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Source
                    if(parseInt(this.props.lead.source.id) === parseInt(searchQuery)
                        || this.props.lead.source.name.replace(/\s/g,"").toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Name
                    if(this.props.lead.name.replace(/\s/g,"").toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Address
                    if((this.props.lead.street + this.props.lead.houseNumber + "," + this.props.lead.city).replace(/\s/g,"").toLowerCase().indexOf(searchQuery) !== -1)
                        continue;


                    // Assigned user id
                    if((this.props.lead.assignedUser ? this.props.lead.assignedUser.id.toString() : "nobody").toLowerCase().indexOf(searchQuery) !== -1
                        || (this.props.lead.assignedUser ? this.props.lead.assignedUser.id.toString() : "niemand").toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Assigned user name
                    if(this.props.lead.assignedUser && this.props.lead.assignedUser.name.replace(/\s/g,"").toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Assigned user when its current user
                    if(this.props.lead.assignedUser && parseInt(this.props.lead.assignedUser.id) === parseInt(this.props.currentUserId) && (searchQuery === "me" || searchQuery === "ik"))
                        continue;

                    // Warnings
                    if(this.props.lead.warnings && this.props.lead.warnings.length > 0) {
                        if("warning".indexOf(searchQuery) !== -1 || "waarschuwing".indexOf(searchQuery) !== -1)
                            continue;

                        let foundWarning = false;
                        for(const warning of this.props.lead.warnings) {
                            if (warning.replace(/\s/g,"").indexOf(searchQuery) !== -1) {
                                foundWarning = true;
                                break;
                            }
                        }
                        if(foundWarning) {
                            continue;
                        }
                    }

                    return false;
                }
            }
        }
        return true;
    }

    render() {
        if(!this.SearchActive())
            return null;

        return (
            <tr className={"table-clickable-row" + (this.props.lead.warnings && this.props.lead.warnings.length > 0 ? " table-warning" : "")}>
                <th scope="row" className="align-middle text-center">
                    <Link to={ this.props.to }>
                        { this.props.lead.id }
                    </Link>
                </th>
                <td className="align-middle">
                    <Link to={ this.props.to }>
                        <TagPill color={ this.props.lead.status.color }>
                            { this.props.lead.status.name }
                        </TagPill>
                    </Link>
                </td>
                <td className="align-middle">
                    <Link to={ this.props.to }>
                        { this.props.lead.type ? (
                            <TagPill color={ this.props.lead.type.color }>
                                { this.props.lead.type.name }
                            </TagPill>
                        ) : (
                            <TagPill color="#595959">
                                Onbepaald
                            </TagPill>
                        )}
                    </Link>
                </td>
                <td className="align-middle">
                    <Link to={ this.props.to }>
                        <TagPill color={ this.props.lead.source.color }>
                            { this.props.lead.source.name }
                        </TagPill>
                    </Link>
                </td>
                <td className="align-middle">
                    <Link to={ this.props.to }>
                        { this.props.lead.name }
                    </Link>
                </td>
                <td className="align-middle">
                    <Link to={ this.props.to }>
                        { this.props.lead.street + " " + this.props.lead.houseNumber + ", " + this.props.lead.city }
                    </Link>
                </td>
                <td className="align-middle">
                    <Link to={ this.props.to }>
                        <DateFormatter
                            date={ this.props.lead.date }
                            format={ this.props.lead.source.manualInput ? "DD-MM-YYYY" : "DD-MM-YYYY HH:mm" }
                        />
                    </Link>
                </td>
                <td className="align-middle">
                    <Link to={ this.props.to }>
                        <CurrentUserHighlight user={ this.props.lead.assignedUser } emptyState="Niemand"/>
                    </Link>
                </td>

            </tr>
        )
    }
}

export default LeadRow;

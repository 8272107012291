import React from "react";
import axios from "axios";
import moment from "moment";
import {
    Alert,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import TagPill from "../../components/tagPill";

import AbsenceUserRow from "./components/AbsenceUserRow.js";
import AbsenceAddModal from "./modal/AbsenceAddModal.js";
import AbsenceUpdateModal from "./modal/AbsenceUpdateModal.js";
import DateRangeSwitcher from "../../components/calendar/DateRangeSwitcher";
import Loading from "../../components/Loading";

function WeekdayHeaderItem({ momentDay }) {
    if(momentDay.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD")) {
        return (
            <td>
                <TagPill>
                    { momentDay.format("D MMM") }
                </TagPill>
            </td>
        );
    } else {
        return (
            <td>
                { momentDay.format("D MMM") }
            </td>
        );
    }
}

class AbsenceCalendar extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            absenceTypes: null,
            absenceItemsPerUser: null,
            urenlijstItemsPerUser: null,
            startDate: moment(),
            error: null,

            showAddAbsenceModal: false,
            updateAbsenceModalData: null,
        };
    }

    getWeek() {
        return this.props.match.params.weekNumber === undefined ? moment().isoWeek() : parseInt(this.props.match.params.weekNumber);
    }

    getYear() {
        return this.props.match.params.year === undefined ? moment().isoWeekYear() : parseInt(this.props.match.params.year);
    }

    componentDidMount() {
        this.setState({
            startDate: moment().isoWeekYear(this.getYear()).isoWeek(this.getWeek()).isoWeekday(1)
        });
        this.getAbsenceItems();
        this.getAbsenceTypes();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.match.path !== this.props.match.path || prevProps.match.params !== this.props.match.params) {
            this.setState({
                startDate: moment().isoWeekYear(this.getYear()).isoWeek(this.getWeek()).isoWeekday(1)
            });
            this.getAbsenceItems();
        }
    }

    convertArrayToObjectPerUser(array) {
        let perUser = {};
        array.forEach((item) => {
            if(!perUser[item.user.id])
                perUser[item.user.id] = [];
            perUser[item.user.id].push(item);
        });
        return perUser;
    }

    getAbsenceItems() {
        const startDate = moment().isoWeekYear(this.getYear()).isoWeek(this.getWeek()).isoWeekday(1);
        const endDate = startDate.clone().isoWeekday(5);
        this.setState({ absenceItemsPerUser: null });
        axios.post("/getAbsenceItems", {
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: endDate.format("YYYY-MM-DD")
        })
            .then((response) => {
                if(response.data.valid) {
                    const allItems = response.data.data;
                    const absenceItems = allItems.filter((item) => item.editable);
                    const urenlijstItems = allItems.filter((item) => !item.editable);
                    this.setState({
                        absenceItemsPerUser: this.convertArrayToObjectPerUser(absenceItems),
                        urenlijstItemsPerUser: this.convertArrayToObjectPerUser(urenlijstItems)
                    });
                } else {
                    this.setState({ error: "Er is een foutopgetreden. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er is een foutopgetreden. Probeer het later opnieuw." });
            });
    }

    getAbsenceTypes() {
        axios.get("/getAbsenceItemTypes")
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ absenceTypes: response.data.data });
                } else {
                    this.setState({ error: "Er is een foutopgetreden. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er is een foutopgetreden. Probeer het later opnieuw." });
            });
    }

    render() {
        const {
            startDate,
            absenceItemsPerUser,
            urenlijstItemsPerUser,
            error,
            showAddAbsenceModal,
            absenceTypes,
            updateAbsenceModalData
        } = this.state;

        const baseUrl = "/absence";
        const previousDate = startDate.clone().subtract(1, "week");
        const nextDate = startDate.clone().add(1, "week");

        const previousUrl = baseUrl + "/" + previousDate.format("GGGG/WW");
        const nextUrl = baseUrl + "/" + nextDate.format("GGGG/WW");

        return (
            <React.Fragment>
                <AbsenceAddModal
                    show={ showAddAbsenceModal }
                    updateCurrentWeek={ this.getAbsenceItems.bind(this) }
                    absenceTypes={ absenceTypes }
                    onHide={ () => this.setState({showAddAbsenceModal: false}) }
                />
                <AbsenceUpdateModal
                    show={ updateAbsenceModalData !== null }
                    updateCurrentWeek={ this.getAbsenceItems.bind(this) }
                    absenceTypes={ absenceTypes }
                    absenceData={ updateAbsenceModalData }
                    onHide={ () => this.setState({updateAbsenceModalData: null}) }
                />

                <Helmet title={ "Absenties - " + startDate.format("[Week] W, GGGG") }/>
                <Title
                    preTitle="Overzicht"
                    preChildren={
                        <div className="btn-group float-right mt-2" role="group">
                            <OverlayTrigger overlay={
                               <Tooltip id="addTooltip">Nieuwe absentie toevoegen</Tooltip>
                            }>
                                <div
                                    className="btn btn-primary"
                                    onClick={ () => this.setState({ showAddAbsenceModal: true }) }
                                >
                                    <i className="fa fa-fw fa-plus"/>
                                </div>
                            </OverlayTrigger>
                        </div>
                    }
                >
                    Absentie
                </Title>

                <DateRangeSwitcher
                    baseUrl={ baseUrl }
                    previousUrl={ previousUrl }
                    nextUrl={ nextUrl }
                    currentMoment={ startDate }
                    previousMoment={ previousDate }
                    nextMoment={ nextDate }
                    displayFormat="[Week] W, GGGG"
                    viewType="week"
                />

                <table className="table table-hover" style={{ tableLayout: "fixed" }}>
                    <thead className="thead-light">
                    <tr className="tr-sticky">
                        <th>Maandag</th>
                        <th>Dinsdag</th>
                        <th>Woensdag</th>
                        <th>Donderdag</th>
                        <th>Vrijdag</th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                        <WeekdayHeaderItem momentDay={ startDate.clone().isoWeekday(1) }/>
                        <WeekdayHeaderItem momentDay={ startDate.clone().isoWeekday(2) }/>
                        <WeekdayHeaderItem momentDay={ startDate.clone().isoWeekday(3) }/>
                        <WeekdayHeaderItem momentDay={ startDate.clone().isoWeekday(4) }/>
                        <WeekdayHeaderItem momentDay={ startDate.clone().isoWeekday(5) }/>
                    </tr>

                    { error ? (
                        <tr>
                            <td colSpan={5} className="pt-4">
                                <Alert variant="danger">{ error }</Alert>
                            </td>
                        </tr>
                    ) : (!absenceItemsPerUser || !urenlijstItemsPerUser) ? (
                        <tr>
                            <td colSpan={5} className="text-center pt-4">
                                <Loading/>
                            </td>
                        </tr>
                    ) : Object.values(absenceItemsPerUser).length + Object.values(urenlijstItemsPerUser).length <= 0 ? (
                        <tr>
                            <td colSpan={5} className="text-center pt-4">
                                <div className="w-100 h2">
                                    <i className="fas fa-user-check"/>
                                </div>
                                <div className="text-weight-bold h4">
                                    Iedereen is present
                                </div>
                            </td>
                        </tr>
                    ) : (
                        <React.Fragment>
                            { Object.values(absenceItemsPerUser).map((userItems, index) => (
                                <AbsenceUserRow
                                    key={ index }
                                    absenceItems={ userItems }
                                    currentWeekStartMoment={ startDate }
                                    onClick={ (absenceItem) => this.setState({ updateAbsenceModalData: absenceItem }) }
                                />
                            ))}
                            { Object.values(urenlijstItemsPerUser).map((userItems, index) => (
                                <AbsenceUserRow
                                    key={ index }
                                    absenceItems={ userItems }
                                    currentWeekStartMoment={ startDate }
                                />
                            ))}
                        </React.Fragment>
                    )}
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}

export default AbsenceCalendar;

import React from "react";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

function getFontAwesomeIcon(availability) {
    switch(availability) {
        case "taken":
        case "fully-inventory":
            return (
                <i className="fas fa-check fa-fw text-success"/>
            )
        case "fully":
            return (
                <i className="fas fa-truck fa-fw text-success"/>
            )
        case "partially-inventory":
        case "partially":
            return (
                <i className="fas fa-tilde fa-fw text-warning"/>
            )
        case "none":
            return (
                <i className="fas fa-times fa-fw text-danger"/>
            )
        default:
            return (
                <i className="fas fa-question fa-fw text-muted"/>
            )
    }
}

function getTooltipText(availability) {
    switch(availability) {
        case "taken": return "Product reeds afgenomen";
        case "fully-inventory": return "Product volledig voorradig";
        case "fully": return "Product besteld bij leverancier";
        case "partially-inventory": return "Product aantal deels beschikbaar";
        case "partially": return "Product deels besteld bij leverancier";
        case "none": return "Product niet beschikbaar";
        default: return (
            <React.Fragment>
                Onbekende beschikbaarheid:
                <br/>
                <span className="text-muted">{ availability }</span>
            </React.Fragment>
        )
    }
}

function ProductAvailabilityIcon({ availability }) {
    const icon = getFontAwesomeIcon(availability);
    const text = getTooltipText(availability);

    return (
        <OverlayTrigger placement="top" overlay={
            <Tooltip id="availability">
                { text }
            </Tooltip>
        }>
            { icon }
        </OverlayTrigger>
    )
}

export default React.memo(ProductAvailabilityIcon);

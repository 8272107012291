import React from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";

import Loading from "../../../components/Loading";
import VATRefundForm from "../components/VATRefundForm";

class VATRefundDetailEdit extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            error: null,
            success: false,

            vatRefund: null
        };
        this.onSave = this.onSave.bind(this);
        this.setVATRefundState = this.setVATRefundState.bind(this);
    }

    componentDidMount() {
        if(this.props.vatRefund !== null) {
            this.setState(() => {
                return { vatRefund: {
                    ...this.props.vatRefund,
                    emptyInvoiceDate: this.props.vatRefund.invoiceDate === null,
                    emptyQuarterDate: this.props.vatRefund.quarter === null
                }};
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.vatRefund !== prevProps.vatRefund) {
            this.setState(() => {
                return { vatRefund: {
                    ...this.props.vatRefund,
                    emptyInvoiceDate: this.props.vatRefund.invoiceDate === null,
                    emptyQuarterDate: this.props.vatRefund.quarter === null
                }};
            });
        }
    }

    setVATRefundState(state) {
        this.setState((prevState) => {
            return { vatRefund: { ...prevState.vatRefund, ...state } }
        })
    }

    onSave() {
        const vatRefund = this.state.vatRefund;
        if(!vatRefund.emptyInvoiceDate && (!vatRefund.invoiceDate || vatRefund.invoiceDate.trim().length === 0)) {
            this.setState({ error: "Niet alle velden zijn ingevuld." });
            window.scroll({ top: 0, behavior: "smooth" });
            return;
        }
        if(!vatRefund.emptyQuarterDate && (!vatRefund.quarter || vatRefund.quarter.trim().length === 0)) {
            this.setState({ error: "Niet alle velden zijn ingevuld." });
            window.scroll({ top: 0, behavior: "smooth" });
            return;
        }
        this.setState({ error: null, success: false, saving: true });
        axios.post("/setVATRefund", {
            vatRefundId: vatRefund.id,

            initials: vatRefund.initials,
            lastName: vatRefund.lastName,
            birthday: vatRefund.birthday,
            email: vatRefund.email,
            phoneNumber: vatRefund.phoneNumber,
            street: vatRefund.street,
            houseNumber: vatRefund.houseNumber,
            postalCode: vatRefund.postalCode,
            city: vatRefund.city,
            quarter: vatRefund.emptyQuarterDate ? null : vatRefund.quarter,
            entrepreneur: vatRefund.entrepreneur ? 1 : 0,
            bsn: vatRefund.bsn,
            iban: vatRefund.iban,
            ibanName: vatRefund.ibanName,
            invoiceDate: vatRefund.emptyInvoiceDate ? null : vatRefund.invoiceDate,
            invoiceVATPrice: vatRefund.emptyInvoiceDate ? null : vatRefund.invoiceVATPrice,
            vatNumber: vatRefund.vatNumber,
            wp: vatRefund.wp
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.setVATRefund(response.data.vatRefund);
                    this.setState({ success: true });
                } else {
                    const error = response.data.error;
                    if(error === "FUTURE_INVOICE_DATE") {
                        this.setState({ error: "Factuurdatum mag niet in de toekomst zijn." });
                    } else {
                        this.setState({ error: "Er was een fout bij het opslaan van de data. (" + error + ")" });
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er was een fout bij het opslaan van de data." });
            })
            .finally(() => {
                this.setState({ saving: false });
                window.scroll({ top: 0, behavior: "smooth" });
            });
    }

    render() {
        const vatRefund = this.state.vatRefund;
        return (
            <React.Fragment>
                { this.state.success && (
                    <Alert variant="success">Wijzigingen zijn opgeslagen.</Alert>
                )}
                { this.state.error && (
                    <Alert variant="danger">{ this.state.error }</Alert>
                )}
                { vatRefund === null ? (
                    <Loading/>
                ) : (
                    <React.Fragment>
                        <VATRefundForm
                            setFormField={ this.setVATRefundState }
                            formFields={ vatRefund }
                            onSave={ this.onSave }
                            saving={ this.state.saving }
                        />
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

export default VATRefundDetailEdit;

import React from "react";

import StatusDot from "../../../components/status/StatusDot";

function PlanningItemParentStatusDot({ planningItem }) {
    if(!planningItem || !planningItem.parentStatus) {
        return null;
    }
    return (
        <StatusDot
            name={ planningItem.parentStatus.name }
            color={ planningItem.parentStatus.color }
        />
    )
}

export default React.memo(PlanningItemParentStatusDot);

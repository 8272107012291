import React, {
    useState
} from "react";
import {
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import {
    Collapse
} from "react-collapse";

import TagPill from "../../../../components/tagPill";
import CurrentUserHighlight from "../../../../components/CurrentUserHighlight";
import DateFormatter from "../../../../components/formatters/DateFormatter";
import numberFormatter from "../../../../components/formatters/NumberFormatter";
import ResizableImage from "../../../../components/ResizableImage";

function RestockOrderDeliveredProductCard({ product, products, onUndo }) {
    const [showDetails, setShowDetails] = useState(false);
    const toggleShowDetails = () => {
        setShowDetails((prevShowDetails) => !prevShowDetails);
    }

    let totalAmount = 0;
    for(const arrayProduct of products) {
        totalAmount += arrayProduct.orderAmount;
    }

    const collapsedContent = (
        <div className="pt-2">
            { products.map((arrayProduct) => {
                const undoTaking = () => {
                    if(onUndo) {
                        onUndo(arrayProduct);
                    }
                }
                return (
                    <div className="d-flex" key={ arrayProduct.deliveredProductId }>
                        <div className="flex-grow-1 d-flex align-items-center">
                            <div>
                                { arrayProduct.orderAmount }x
                                {" door "}
                                <CurrentUserHighlight user={ arrayProduct.author } emptyState="onbekend"/>
                                {" op "}
                                <DateFormatter date={ arrayProduct.date }/>
                            </div>
                        </div>
                        <div>
                            <OverlayTrigger overlay={
                                <Tooltip id="edit">Materiaal afnemen ongedaan maken</Tooltip>
                            }>
                                <Button variant="link" className="text-danger py-0" size="sm" onClick={ undoTaking }>
                                    <i className="fas fa-undo"/>
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </div>
                )
            })}
        </div>
    );

    return (
        <div className="product-card-container">
            <div className="product-thumbnail-card d-none d-md-flex">
                { product.thumbnailImage ? (
                    <ResizableImage
                        image={ product.thumbnailImage }
                        size="original"
                    />
                ) : (
                    <i className="fas fa-image"/>
                )}
            </div>
            <div className="card flex-grow-1">
                <div className="card-body">
                    <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                            <h5 className="card-title mb-0">
                                    <span className="mt-2">
                                        { numberFormatter({ number: totalAmount }) + "x " }
                                        { product.brand.name } { product.name }
                                    </span>
                                <small className="ml-2">
                                    <TagPill color={ product.type.color } className="mr-2">
                                        { product.type.name }
                                    </TagPill>
                                </small>
                            </h5>
                        </div>

                        { product.sku && (
                            <div className="text-muted nowrap mr-3">{ product.sku }</div>
                        )}

                        <div className="text-muted nowrap">Voorradig: { numberFormatter({ number: product.amount }) }</div>
                    </div>

                    <div className="d-print-none">
                        <Collapse isOpened={ showDetails }>
                            { collapsedContent }
                        </Collapse>
                        <Button variant="link" size="sm" className="p-0" onClick={ toggleShowDetails }>
                            { showDetails ? (
                                <React.Fragment>
                                    <i className="fas fa-angle-up mr-2"/>
                                    Details verbergen
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <i className="fas fa-angle-down mr-2"/>
                                    Details tonen
                                </React.Fragment>
                            )}
                        </Button>
                    </div>
                    <div className="d-none d-print-block">
                        { collapsedContent }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(RestockOrderDeliveredProductCard);

import React from "react";
import moment from "moment";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Button,
    ButtonGroup,
    Card,
    Spinner
} from "react-bootstrap";

import {
    withIntranetSidebarContext
} from "../../../components/layout/sidebar/IntranetSidebarContext";
import TagPill from "../../../components/tagPill";
import DateFormatter from "../../../components/formatters/DateFormatter";
import ReactMarkdownGFM from "../../../components/ReactMarkdownGFM";

class ReminderCard extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            loading: false
        }
    }

    setReminderSolved(solved) {
        this.setState({ loading: true });
        axios.post("/setReminderSolved", { "reminderId": this.props.reminder.id, "solved": solved ? 1 : 0 })
            .then((response) => {
                if(response.data.valid) {
                    if(this.props.reminderUpdated !== undefined) {
                        this.props.reminderUpdated(response.data.reminder);
                    }
                    this.props.intranetSidebarContext.refreshReminders();
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    render() {
        let reminder = this.props.reminder;
        let showTitle = this.props.showTitle ?? false;

        let name = null;
        let parent = null;
        let parentUrl = null;
        let parentStatus = null;
        let parentPill2 = null;
        let parentPill3 = null;
        if (reminder.lead != null) {
            name = reminder.lead.name;
            parent = "Lead";
            parentUrl = "/lead/" + reminder.lead.id;
            parentStatus = reminder.lead.status;
            parentPill2 = reminder.lead.source;
            parentPill3 = reminder.lead.type;
        } else if (reminder.outage != null) {
            if(reminder.outage.lead) {
                name = reminder.outage.lead.name;
            } else if(reminder.outage.installation) {
                name = reminder.outage.installation.lead.name;
            }
            parent = "Storing";
            parentUrl = "/outage/" + reminder.outage.id;
            parentStatus = reminder.outage.status;
            parentPill2 = reminder.outage.type;
        } else if (reminder.installation != null) {
            name = reminder.installation.lead.name;
            parent = "Installatie";
            parentUrl = "/installation/" + reminder.installation.id;
            parentStatus = reminder.installation.status;
        } else if (reminder.vatRefund != null) {
            name = reminder.vatRefund.initials.split("").join(".") + ". " + reminder.vatRefund.lastName;
            parent = "BTW Terugvraag";
            parentUrl = "/vat-refund/" + reminder.vatRefund.id;
            parentStatus = reminder.vatRefund.status;
        } else {
            parentStatus = { name: "Error", color: "black" }
            parentPill2 = { name: "Error", color: "black" }
        }

        let daysSince = moment.duration(moment(reminder.date).diff(moment())).asDays();
        let warning = daysSince < -30 && !reminder.solved;

        if(!this.props.hideWhenSolved || !reminder.solved) {
            return (
                <Card className="mb-3" border={reminder.solved ? "success" : warning ? "warning" : null}>
                    <Card.Body style={{ paddingBottom: "4px" }}>
                        { showTitle && (
                            <Card.Title>
                                <span className="mr-2">{name}</span>
                                <br className="d-lg-none"/>
                                <small>
                                    <TagPill className="mr-2">
                                        {parent}
                                    </TagPill>
                                    <TagPill className="mr-2" color={parentStatus.color}>
                                        {parentStatus.name}
                                    </TagPill>
                                    { parentPill2 && (
                                        <TagPill className="mr-2" color={parentPill2.color}>
                                            {parentPill2.name}
                                        </TagPill>
                                    )}
                                    { parentPill3 && (
                                        <TagPill className="mr-2" color={parentPill3.color}>
                                            {parentPill3.name}
                                        </TagPill>
                                    )}
                                </small>
                            </Card.Title>
                        )}
                        <ReactMarkdownGFM message={ reminder.message }/>
                    </Card.Body>
                    <Card.Footer className="text-muted">

                        <div className="row">
                            <div className="col-12 col-lg">
                                <TagPill className="mr-2">
                                    { reminder.assignedUser === null ? "Iedereen" : reminder.assignedUser.name }
                                </TagPill>
                                <TagPill className="mr-2" color={reminder.type.color}>
                                    { reminder.type.name }
                                </TagPill>
                                <TagPill className="mr-2" variant={warning ? "warning" : "secondary"}>
                                    { moment(reminder.date).format('L') }
                                </TagPill>
                                <p className="d-inline-block mr-2" style={{ marginBottom: 0, lineHeight: "28px" }}>
                                    Aangemaakt door <b>{ reminder.author.name }</b> op <DateFormatter
                                    date={ reminder.dateCreated }/>
                                </p>
                            </div>
                            <div className="col col-lg-auto d-flex align-items-center">
                                <ButtonGroup size="sm">
                                    { showTitle && (
                                        <Link to={ parentUrl } className="btn btn-secondary">
                                            { parent } bekijken
                                        </Link>
                                    )}
                                    { reminder.solved ? (
                                        <Button variant="warning" onClick={ () => this.setReminderSolved(!reminder.solved) } disabled={ this.state.loading }>
                                            { this.state.loading ? (
                                                <Spinner animation="border" variant="dark" size="sm" className="mr-2"/>
                                            ) : (
                                                <i className="fas fa-times mr-2"/>
                                            )}
                                            Markeer onopgelost
                                        </Button>
                                    ) : (
                                        <React.Fragment>
                                            { this.props.handleRedoModalOpen && (
                                                <Button variant="secondary" onClick={ () => this.props.handleRedoModalOpen(reminder) } disabled={ this.state.loading }>
                                                    <i className="fas fa-redo mr-1"/> Opnieuw
                                                </Button>
                                            )}
                                            <Button variant="primary" onClick={ () => this.props.handleEditModalOpen(reminder) } disabled={ this.state.loading }>
                                                <i className="fas fa-pen mr-1"/> Bewerken
                                            </Button>
                                            <Button variant="success" onClick={ () => this.setReminderSolved(!reminder.solved) } disabled={ this.state.loading }>
                                                { this.state.loading ? (
                                                    <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                                                ) : (
                                                    <i className="fas fa-check mr-2"/>
                                                )}
                                                Markeer opgelost
                                            </Button>
                                        </React.Fragment>
                                    )}
                                </ButtonGroup>
                            </div>
                        </div>

                    </Card.Footer>
                </Card>
            )
        } else {
            return (
                <Card className="mb-3" border="success">
                    <Card.Body>
                        <button className="btn btn-secondary btn-sm float-right" onClick={ () => this.setReminderSolved(!reminder.solved) } disabled={ this.state.loading }>
                            { this.state.loading && (
                                <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                            )}
                            Ongedaan maken
                        </button>
                        <p className="text-success mb-0" style={{ lineHeight: "31px" }}>
                            <i className="fas fa-check"/> Herinnering als opgelost gemarkeerd.
                        </p>
                    </Card.Body>
                </Card>
            )
        }

    }
}

export default withIntranetSidebarContext(ReminderCard);

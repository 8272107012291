import React from "react";
import {
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import numberFormatter from "../../../../components/formatters/NumberFormatter";

function ProductActionSetAmount({ product, onClick }) {
    return (
        <React.Fragment>
            <span className="d-none d-print-block">
                { numberFormatter({number: product.amount}) }
            </span>
            <div className="d-print-none">
                <OverlayTrigger overlay={
                    <Tooltip id="edit">Aantal wijzigen</Tooltip>
                }>
                    <Button
                        variant="editable-blue"
                        onClick={ onClick }
                        className="py-1 px-2"
                    >
                        { numberFormatter({number: product.amount}) }
                    </Button>
                </OverlayTrigger>
            </div>
        </React.Fragment>
    );
}

export default React.memo(ProductActionSetAmount);

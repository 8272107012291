import React from "react";
import {
    Alert
} from "react-bootstrap";
import SimpleMDE from "react-simplemde-editor";
import ImageUploading from "react-images-uploading";

class DetailNewCommentField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: "",
            images: []
        }
        this.onChange = this.editorChange.bind(this);
        this.onImagesChange = this.imagesChange.bind(this);
        this.onSubmit = this.submit.bind(this);
        this.simpleMdeOptions = {
            spellChecker: false
        }
    }

    clearContent() {
        this.setState({ content: "", images: [] });
    }

    editorChange(value) {
        this.setState({ content: value });
    }
    
    imagesChange(imageList, addUpdateIndex) {
        console.log(imageList, addUpdateIndex);
        this.setState({ images: imageList });
    }

    submit() {
        this.props.onSubmit(this.state.content, this.state.images, this.clearContent.bind(this));
    }

    render() {
        return (
            <div className="d-print-none">
                {this.props.error && (
                    <Alert variant="danger">{this.props.error}</Alert>
                )}
                <SimpleMDE
                    value={ this.state.content }
                    onChange={ this.onChange }
                    options={ this.simpleMdeOptions }
                />
                <div className="card">
                    <div className="card-body">
                        <ImageUploading
                            multiple
                            value={ this.state.images }
                            onChange={ this.onImagesChange }
                            maxNumber={ 20 }
                            dataURLKey="data_url"
                            acceptType={[
                                "jpg",
                                "png",
                                "jpeg",

                                "xlsx",
                                "xls",
                                "csv",
                                "pdf",
                                "docx",
                                "doc",
                                "mp4",
                            ]}
                            allowNonImageType
                        >
                            {({
                                  imageList,
                                  onImageUpload,
                                  onImageRemoveAll,
                                  onImageUpdate,
                                  onImageRemove,
                                  isDragging,
                                  dragProps,
                              }) => (
                                <div className="image-upload">
                                    <button
                                        className={ "btn btn-image-upload" + (isDragging ? " btn-image-upload-dragging" : "") }
                                        onClick={ onImageUpload }
                                        disabled={ this.props.loading }
                                        { ...dragProps }
                                    >
                                        Klik hier om een foto toe te voegen
                                    </button>
                                    { imageList.length > 0 && (
                                        <div className="mt-2">
                                            <div className="clearfix">
                                                <button className="btn btn-secondary btn-sm float-right" onClick={ onImageRemoveAll } disabled={ this.props.loading }>
                                                    Verwijder alle
                                                </button>
                                            </div>
                                            <div className="d-flex flex-wrap mt-2">
                                                { imageList.map((file, index) => {
                                                    const showImage = (() => {
                                                        if(!file?.file) {
                                                            return false;
                                                        }
                                                        switch(file.file.type) {
                                                            case "image/jpeg":
                                                            case "image/png":
                                                                return true;
                                                            default:
                                                                return false;
                                                        }
                                                    })()
                                                    return (
                                                        <div key={ index } className="image-item card mr-1 mb-1">
                                                            <div className="card-body p-2">
                                                                <button
                                                                    className="btn btn-sm btn-remove"
                                                                    onClick={ () => onImageRemove(index) }
                                                                    disabled={ this.props.loading }
                                                                >
                                                                    <i className="fas fa-fw fa-times"/>
                                                                </button>
                                                                <button
                                                                    className="btn btn-image"
                                                                    onClick={ () => onImageUpdate(index) }
                                                                    disabled={ this.props.loading }
                                                                >
                                                                    { showImage ? (
                                                                        <img src={file['data_url']} alt="" width="200"/>
                                                                    ) : (
                                                                        <div style={{ width: "200px" }}>
                                                                            {file.file.name}
                                                                        </div>
                                                                    )}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </ImageUploading>
                    </div>
                </div>
                <div className="mt-3">
                    <button className="btn btn-primary btn-sm" onClick={ this.onSubmit } disabled={ this.props.loading }>
                        { this.props.loading && (
                            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        )}
                        Notitie plaatsen
                    </button>
                </div>
            </div>
        )
    }
}

export default React.memo(DetailNewCommentField);

import React from "react";
import Skeleton from "react-loading-skeleton";

function DashboardCardInternal({ border, className, children, ...rest }) {
    return (
        <div { ...rest } className={ "card" + (border ? " border-" + border : "") + (className ? " " + className : "") }>
            { children }
        </div>
    )
}

function DashboardCardBodyRegular({ title, children, titleAccessory, loading = false }) {
    return (
        <div className="card-body h-100 d-flex flex-column">
            <div>
                { titleAccessory }
                <h5 className="card-title">
                    { loading ? (
                        <Skeleton width={ title ? title.length * 10 : 150 }/>
                    ) : title }
                </h5>
            </div>
            { children }
        </div>
    )
}

function DashboardCardBodyHighlight({ title, content, subline, loading = false, error }) {
    return (
        <div className="card-body text-center d-flex flex-column justify-content-center">
            { error ? (
                <h3 className="card-text text-danger">Error</h3>
            ) : (
                <React.Fragment>
                    <h5 className="card-title my-0">
                        { loading ? (
                            <Skeleton width={ title ? title.length * 10 : 150 }/>
                        ) : title }
                    </h5>
                    <h3 className={ "card-text " + (subline ? "my-2" : "mt-2") }>
                        { loading ? (
                            <Skeleton width={ Math.random() * (200 - 75) + 75 }/>
                        ) : content }
                    </h3>
                    { subline && ( loading ? (
                        <Skeleton width={ subline.length ? subline.length * 10 : undefined }/>
                    ) : (
                        <p className="card-text">{ subline }</p>
                    ))}
                </React.Fragment>
            )}
        </div>
    )
}

const DashboardCard = React.memo(DashboardCardInternal);
DashboardCard.BodyRegular = React.memo(DashboardCardBodyRegular);
DashboardCard.BodyHighlight = React.memo(DashboardCardBodyHighlight);

export default DashboardCard;

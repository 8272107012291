import React, {
    useMemo
} from "react";

import CopyButton from "../../../../components/copy/CopyButton";

function LeadExcelCopyButton({ lead, children, ...props }) {
    const vatTariffSelection = useMemo(() => {
        if(!lead || !lead.bagUsages) {
            return null;
        }
        return lead.bagUsages.includes("woonfunctie");
    }, [lead]);

    const lines = useMemo(() => {
        if(!lead) {
            return null;
        }
        let lines = [
            lead.name,
            lead.street + " " + lead.houseNumber,
            lead.postalCode,
            lead.city,
            lead.phoneNumber,
            lead.email
        ]
        if(vatTariffSelection !== null) {
            lines.push(vatTariffSelection ? 1 : 0);
        }
        return lines;
    }, [lead, vatTariffSelection]);

    if(!lines) {
        return null;
    }

    return (
        <CopyButton
            text={ lines.join("\n") }
            { ...props }
        >
            { children }
        </CopyButton>
    )
}

export default React.memo(LeadExcelCopyButton);

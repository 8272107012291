import React from "react";
import {
    Link
} from "react-router-dom";

import TagPill from "../../components/tagPill";
import DateFormatter from "../../components/formatters/DateFormatter";
import CurrentUserHighlight from "../../components/CurrentUserHighlight";
import WarningTooltip from "../../components/overview/WarningTooltip";

class InstallationRow extends React.PureComponent {
    searchActive() {
        const installation = this.props.installation;
        if(this.props.searchQuery) {
            const splitSearchQuery = this.props.searchQuery.split(" ");
            // Loop through every search query item
            for(let searchQueryIndex = 0; searchQueryIndex < splitSearchQuery.length; searchQueryIndex++) {
                const searchQuery = (splitSearchQuery[searchQueryIndex] || "").toLowerCase();
                if(searchQuery.indexOf("=") !== -1) {
                    let [searchKey, searchValue] = searchQuery.split("=");
                    switch(searchKey) {
                        default:
                        case "id":
                            if(parseInt(installation.id) !== parseInt(searchValue))
                                return false;
                            break;
                        case "auteur":
                        case "author":
                            if((installation.author ? installation.author.name.replace(/\s/g,"") : "automatisch").toLowerCase().indexOf(searchValue) === -1
                                && (installation.author ? installation.author.name.replace(/\s/g,"") : "automatic").toLowerCase().indexOf(searchValue) === -1)
                                return false;
                            break;
                        case "phonenumber":
                        case "telefoonnummer":
                        case "telefoon":
                            if(installation.lead.phoneNumber.replace(/\s/g,"").toLowerCase().indexOf(searchValue) === -1)
                                return false;
                            break;
                        case "email":
                            if(installation.lead.email.replace(/\s/g,"").toLowerCase().indexOf(searchValue) === -1)
                                return false;
                            break;
                        case "status":
                            if(parseInt(installation.status.id) !== parseInt(searchValue)
                            && installation.status.name.replace(/\s/g,"").toLowerCase().indexOf(searchValue) === -1)
                                return false;
                            break;
                        case "name":
                        case "naam":
                            if(installation.lead.name.replace(/\s/g,"").toLowerCase().indexOf(searchValue) === -1)
                                return false;
                            break;
                        case "address":
                        case "adres":
                            if((installation.street + installation.houseNumber + "," + installation.city).replace(/\s/g,"").toLowerCase().indexOf(searchValue) === -1)
                                return false;
                            break;
                    }
                }
                else {
                    // Lead ID
                    if(parseInt(installation.id) === parseInt(searchQuery))
                        continue;

                    // Author
                    if((installation.author ? installation.author.name.replace(/\s/g,"") : "automatisch").toLowerCase().indexOf(searchQuery) !== -1
                        || (installation.author ? installation.author.name.replace(/\s/g,"") : "automatic").toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Phone number
                    if(installation.lead.phoneNumber.replace(/\s/g,"").toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Email
                    if(installation.lead.email.replace(/\s/g,"").toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Status
                    if(parseInt(installation.status.id) === parseInt(searchQuery)
                        || installation.status.name.replace(/\s/g,"").toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Name
                    if(installation.lead.name.replace(/\s/g,"").toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Address
                    if((installation.street + installation.houseNumber + "," + installation.city).replace(/\s/g,"").toLowerCase().indexOf(searchQuery) !== -1)
                        continue;

                    // Warnings
                    if(installation.warnings && installation.warnings.length > 0) {
                        if("warning".indexOf(searchQuery) !== -1 || "waarschuwing".indexOf(searchQuery) !== -1)
                            continue;

                        let foundWarning = false;
                        for(const warning of installation.warnings) {
                            if (warning.replace(/\s/g,"").indexOf(searchQuery) !== -1) {
                                foundWarning = true;
                                break;
                            }
                        }
                        if(foundWarning) {
                            continue;
                        }
                    }

                    return false;
                }
            }
        }
        return true;
    }

    render() {
        if(!this.searchActive()) {
            return null;
        }

        const installation = this.props.installation;

        return (
            <tr className={"table-clickable-row" + (installation.warnings && installation.warnings.length > 0 ? " table-warning" : "")}>
                <th scope="row" className="align-middle text-center">
                    <Link to={ this.props.to }>
                        { installation.id }
                    </Link>
                </th>
                <td className="align-middle text-center">
                    <WarningTooltip warnings={ installation.warnings }/>
                </td>
                { this.props.invoicing && (
                    <td className="align-middle">
                        <Link to={ this.props.to }>
                            <TagPill color={ installation.invoicingStatus.color }>
                                { installation.invoicingStatus.name }
                            </TagPill>
                        </Link>
                    </td>
                )}
                <td className="align-middle">
                    <Link to={ this.props.to }>
                        <TagPill color={ installation.status.color }>
                            { installation.status.name }
                        </TagPill>
                    </Link>
                </td>
                <td className="align-middle">
                    <Link to={ this.props.to }>
                        { installation.lead.name }
                    </Link>
                </td>
                <td className="align-middle">
                    <Link to={ this.props.to }>
                        { installation.street + " " + installation.houseNumber + ", " + installation.city }
                    </Link>
                </td>
                <td className="align-middle">
                    <Link to={ this.props.to }>
                        { installation.hasVATRefund ? (
                            <i className="fas fa-check text-success"/>
                        ) : (
                            <i className="fas fa-times"/>
                        )}
                    </Link>
                </td>
                <td className="align-middle">
                    <Link to={ this.props.to }>
                        <CurrentUserHighlight user={ installation.author } emptyState="Automatisch"/>
                    </Link>
                </td>
                <td className="align-middle">
                    <Link to={ this.props.to }>
                        <DateFormatter
                            date={ installation.creationDate }
                            format={ "DD-MM-YYYY HH:mm" }
                        />
                    </Link>
                </td>

            </tr>
        )
    }
}

export default InstallationRow;

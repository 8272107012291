import React, {
    useMemo
} from "react";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

function AbsenceCircle({ absenceItem }) {
    return (
        <div className="m-1" key={ absenceItem.id }>
            <OverlayTrigger placement="top" overlay={
                <Tooltip id="absenceItem">
                    <b>{ absenceItem.type.name }</b>
                    <br/>
                    { absenceItem.description }
                </Tooltip>
            }>
                <div style={{
                    width: "12px",
                    height: "12px",
                    borderRadius: "50%",
                    backgroundColor: absenceItem.type.color
                }}/>
            </OverlayTrigger>
        </div>
    );
}

function PlanningDayCalendarAbsenceCell({ user, absenceItems }) {
    const userAbsenceItems = useMemo(() => {
        return absenceItems.filter((absenceItem) => {
            if(absenceItem.editable) {
                return absenceItem.user.id === user.id;
            } else {
                return absenceItem.user.id === user.urenlijstId;
            }
        });
    }, [user, absenceItems]);
    return (
        <td style={{ verticalAlign: "middle" }}>
            <div className="mt-auto d-flex flex-wrap h-100 align-items-center justify-content-center">
                { userAbsenceItems.map((absenceItem) => (
                    <AbsenceCircle
                        absenceItem={ absenceItem }
                        key={ absenceItem.id }
                    />
                ))}
            </div>
        </td>
    );
}

function PlanningDayCalendarAbsenceRow({ users, absenceItems }) {
    return (
        <tr>
            <td>Absenties</td>
            { users.map((user) => (
                <PlanningDayCalendarAbsenceCell
                    user={ user }
                    absenceItems={ absenceItems }
                    key={ user.id }
                />
            ))}
        </tr>
    )
}

export default React.memo(PlanningDayCalendarAbsenceRow);

import React from "react";
import axios from "axios";
import {
    Form
} from "react-bootstrap";

import DetailSetStatusModal from "../../../../components/detail/modal/DetailSetStatusModal";
import RadioChip from "../../../../components/RadioChip";

class InstallationDetailSetStatusModal extends DetailSetStatusModal {
    onShow() {
        super.onShow();
        this.setState({
            sendReviewRequest: null,
            issuesFound: null,
            issuesDescription: ""
        });
    }

    getInitialStatus() {
        return this.props.installation.status.id;
    }

    getStatuses() {
        this.setState({ statuses: null, error: null });
        axios.get("/getInstallationStatuses")
            .then((response) => {
                if(response.data.valid) {
                    let statuses = response.data.data;
                    statuses.sort((status1, status2) => {
                        if(status1.priority > status2.priority) return -1;
                        if(status1.priority < status2.priority) return 1;
                        return 0;
                    });
                    this.setState({ statuses });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    getError(errorCode = null) {
        switch(errorCode) {
            case "PRODUCTS_NOT_AVAILABLE":
                return "Er is niet genoeg voorraad beschikbaar om de gereserveerde producten af te nemen!";
            case "RESERVED_PRODUCTS_REMAINING":
                return "Kan niet op afgerond zetten want er zijn nog gereserveerde producten in deze installatie.";
            default:
                if(errorCode) {
                    return "Er ging iets fout. Probeer het later opnieuw. (" + errorCode + ")";
                } else {
                    return "Er ging iets fout. Probeer het later opnieuw.";
                }
        }
    }

    setStatus(onSuccess, onError) {
        const {
            selectedStatus,
            sendReviewRequest,
            issuesFound,
            issuesDescription
        } = this.state;
        axios.post("/setInstallationStatus", {
            installationId: this.props.installation.id,
            statusId: selectedStatus,
            sendReviewRequest: sendReviewRequest ? 1 : 0,
            issuesFound: issuesFound ? 1 : 0,
            issuesDescription
        })
            .then((response) => {
                if(response.data.valid) {
                    onSuccess(() => {
                        this.props.handleStatusChanged(response.data.status);
                    });
                } else {
                    onError(this.getError(response.data.error));
                }
            })
            .catch((error) => {
                console.error(error);
                onError(this.getError());
            });
    }

    overrideSize() {
        if(this.showInstallationReviewFields()) {
            return "lg";
        }
        return "md";
    }

    getSelectedStatus() {
        const selectedStatusId = parseInt(this.state.selectedStatus, 10);
        if(!selectedStatusId) {
            return null;
        }
        return this.state.statuses?.find((status) => status.id === selectedStatusId);
    }

    showInstallationReviewFields() {
        const {
            installation
        } = this.props;

        const selectedStatus = this.getSelectedStatus();
        const currentStatus = installation.status;

        if(!selectedStatus || !currentStatus) {
            return false;
        }
        if(!selectedStatus.installationFinished || currentStatus.installationFinished) {
            return false;
        }
        return true;
    }

    renderAdditionalFields() {
        const {
            loading,
            sendReviewRequest,
            issuesFound,
            issuesDescription
        } = this.state;

        const showInstallationReviewFields = this.showInstallationReviewFields();
        if(!showInstallationReviewFields) {
            return null;
        }

        return (
            <div className="mt-3">
                <div className="mb-3">
                    <p className="mb-1">Verzoek voor beoordeling versturen?</p>
                    <RadioChip
                        checked={ sendReviewRequest === false }
                        onClick={ () => this.setState({ sendReviewRequest: false }) }
                        disabled={ loading }
                    >
                        <i className="fas fa-comment-slash fa-fw mr-2"/>
                        Niet versturen
                    </RadioChip>
                    <RadioChip
                        checked={ sendReviewRequest === true }
                        onClick={ () => this.setState({ sendReviewRequest: true }) }
                        className="ml-2"
                        disabled={ loading }
                    >
                        <i className="fas fa-comment fa-fw mr-2"/>
                        Verstuur
                    </RadioChip>
                </div>
                <div className="mb-3">
                    <p className="mb-1">Waren/zijn er problemen bij deze installatie?</p>
                    <RadioChip
                        checked={ issuesFound === true }
                        onClick={ () => this.setState({ issuesFound: true }) }
                        disabled={ loading }
                    >
                        <i className="fas fa-exclamation-circle fa-fw mr-2"/>
                        Problemen bij deze installatie
                    </RadioChip>
                    <RadioChip
                        checked={ issuesFound === false }
                        onClick={ () => this.setState({ issuesFound: false }) }
                        className="ml-2"
                        disabled={ loading }
                    >
                        <i className="fas fa-check-circle fa-fw mr-2"/>
                        Installatie was vlekkenloos
                    </RadioChip>
                </div>
                { issuesFound && (
                    <Form.Group controlId="issuesDescription" className="mb-0">
                        <Form.Label>Beschrijf de problemen</Form.Label>
                        <Form.Control
                            as="textarea"
                            value={ issuesDescription }
                            onChange={ (event) => this.setState({ issuesDescription: event.target.value }) }
                            disabled={ loading }
                            style={{ height: "200px" }}
                        />
                    </Form.Group>
                )}
            </div>
        )
    }

}

export default InstallationDetailSetStatusModal;

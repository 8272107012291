import React, {
    useMemo
} from "react";
import {
    Alert,
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import {
    ErrorBoundary
} from "@sentry/react";
import {
    useWindowSize
} from "@zandor300/backoffice-framework";
import byteSize from "byte-size";

import DateFormatter from "../formatters/DateFormatter";
import DeviceTypeIcon from "./DeviceTypeIcon";
import ReactMarkdownGFM from "../ReactMarkdownGFM";
import ResizableImage from "../ResizableImage";
import NumberFormatter from "../formatters/NumberFormatter";
import FileIcon from "../FileIcon";

function CommentButton({ icon, title, className, ...props }) {
    const windowSize = useWindowSize();
    const show = useMemo(() => {
        // Don't show tooltips if window width is < 992. undefined = hover, false = don't show
        return windowSize.width < 992 ? undefined : false;
    }, [windowSize]);
    const classNames = useMemo(() => {
        let newClassNames = ["card-link"];
        if(className) {
            newClassNames.push(className);
        }
        return newClassNames;
    }, [className]);
    return (
        <OverlayTrigger show={show} overlay={
            <Tooltip id="comment-button">{ title }</Tooltip>
        }>
            <Button { ...props } size="sm" className={ classNames.join(" ") }>
                <span className="mr-lg-2">
                    <i className={ icon }/>
                </span>
                <span className="d-none d-lg-inline-block">
                    { title }
                </span>
            </Button>
        </OverlayTrigger>
    )
}

function CommentFooter({ comment, noButtons, showRevisions, editComment, deleteComment }) {
    return (
        <div className="card-footer text-muted d-flex flex-row flex-sm-column flex-md-row flex-print-row align-items-center align-items-sm-start align-items-lg-center">
            <div className="flex-grow-1 d-flex flex-column flex-md-row flex-print-row align-items-md-center mr-2">
                <div className="d-flex flex-column flex-xl-row flex-print-row">
                    <div className="mr-2">
                        <DeviceTypeIcon deviceType={ comment.deviceType } className="mr-2"/>
                        <b className="card-text">
                            { comment.user ? comment.user.name : "Automatisch" }
                        </b>
                    </div>
                    <div>
                        <DateFormatter date={ comment.date }/>
                    </div>
                </div>
                { comment.revisions && comment.revisions.length > 1 && (
                    <div>
                        <span className="badge badge-pill badge-secondary ml-md-2">
                            <i className="fas fa-history"/> <NumberFormatter number={comment.revisions.length - 1}/>x bewerkt
                        </span>
                    </div>
                )}
            </div>
            { !noButtons && (
                <div className="d-print-none mt-sm-2 mt-lg-0">
                    <div className="btn-group mb-lg-0" role="group">
                        { comment.revisions && comment.revisions.length > 1 && (
                            <CommentButton
                                variant="secondary"
                                onClick={ showRevisions }
                                icon="fas fa-history fa-fw"
                                title="Geschiedenis"
                            />
                        )}
                        <CommentButton
                            variant="primary"
                            onClick={ editComment }
                            icon="fas fa-pen fa-fw"
                            title="Bewerken"
                        />
                        <CommentButton
                            variant="danger"
                            onClick={ deleteComment }
                            icon="fas fa-trash-alt fa-fw"
                            title="Verwijderen"
                        />
                    </div>
                </div>
            )}
        </div>
    )
}

function Comment({ comment, className, customCardHeader, selectImage, noButtons = false, showRevisions, editComment, deleteComment }) {
    return (
        <div className="card-comment-container">
            <div className={"card " + (className ? className : "")}>
                { customCardHeader }
                <div className="card-body" style={{ paddingBottom: "4px" }}>
                    <ErrorBoundary fallback={
                        <Alert variant="danger">Er is een fout opgetreden bij het inladen van deze notitie.</Alert>
                    }>
                        <ReactMarkdownGFM message={ comment.message.replaceAll("\\n", "\n") }/>
                    </ErrorBoundary>
                    { comment.files?.length > 0 && (
                        <div className="mb-3">
                            { comment.files.map((file) => {
                                const fileSize = byteSize(file.fileSize);
                                return (
                                    <div className="card mb-1" key={ file.id }>
                                        <a
                                            href={ file.url }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="card-link text-black-50"
                                        >
                                            <div className="card-body py-2 px-3">
                                                <div className="d-flex flex-row align-items-center">
                                                    <div style={{ fontSize: "1.5rem" }}>
                                                        <FileIcon
                                                            path={ file.path }
                                                            className="mr-2"
                                                        />
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        { file.path.split("/").pop() }
                                                    </div>
                                                    <div>
                                                        {`${fileSize.value} ${fileSize.unit}`}
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    { comment.images && comment.images.length > 0 && selectImage !== undefined && (
                        <div className="row no-gutters row-cols-1 row-cols-print-4 row-cols-md-4 row-cols-lg-5 row-cols-xl-8 mb-3">
                            { comment.images.map( image =>
                                <div className="col image-box" key={ image.id } onClick={ () => { selectImage(image); } }>
                                    <ResizableImage image={ image } alt={ "Image " + image.id } size="315x315"/>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <CommentFooter
                    comment={ comment }
                    noButtons={ noButtons }
                    showRevisions={ showRevisions }
                    editComment={ editComment }
                    deleteComment={ deleteComment }
                />
            </div>
        </div>
    )
}

export default React.memo(Comment);

import React, {
    useEffect,
    useState
} from "react";
import axios from "axios";
import moment from "moment";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Table
} from "react-bootstrap";

import Loading from "../../../components/Loading";
import DateFormatter from "../../../components/formatters/DateFormatter";
import TagPill from "../../../components/tagPill";

function LeadsExpiredStatistics() {
    const [leadSources, setLeadSources] = useState(null);
    const [filterLeadSourceId, setFilterLeadSourceId] = useState(null);
    const [leads, setLeads] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLeadSources(null);
        setFilterLeadSourceId(null);
        axios.get("/getLeadSources")
            .then((response) => {
                if(response.data.valid) {
                    console.log(response.data.data);
                    setLeadSources(response.data.data);
                } else {
                    setError("Er is iets fout gegaan. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setError("Er is iets fout gegaan. Probeer het later opnieuw.");
            });
    }, []);

    useEffect(() => {
        setLeads(null);
        setError(null);
        axios.post("/getExpiredLeads", {
            leadSourceId: filterLeadSourceId ? filterLeadSourceId : undefined
        })
            .then((response) => {
                if(response.data.valid) {
                    console.log(response.data.data);
                    setLeads(response.data.data);
                } else {
                    setError("Er is iets fout gegaan. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((requestError) => {
                console.error(requestError);
                setError("Er is iets fout gegaan. Probeer het later opnieuw.");
            });
    }, [filterLeadSourceId]);

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        )
    }
    if(!leads) {
        return (
            <Loading/>
        )
    }
    return (
        <React.Fragment>
            <div className="d-flex flex-row justify-content-end">
                <div className="form-group">
                    <select
                        className="form-control"
                        value={ filterLeadSourceId ?? "" }
                        onChange={ (event) => setFilterLeadSourceId(event.target.value || null) }
                    >
                        <option value="">Alle bronnen</option>
                        { leadSources?.map((leadSource) => {
                            return (
                                <option
                                    key={ leadSource.id }
                                    value={ leadSource.id }
                                    style={{ color: "white", backgroundColor: leadSource.color }}
                                >
                                    { leadSource.name }
                                </option>
                            )
                        })}
                    </select>
                </div>
            </div>
            <Table hover>
                <thead className="thead-light">
                <tr className="tr-sticky">
                    <th className="text-center">#</th>
                    <th>Naam</th>
                    <th>Status</th>
                    <th>Bron</th>
                    <th>Type</th>
                    <th style={{ minWidth: "120px" }}>Datum ontvangen</th>
                    <th style={{ minWidth: "120px" }}>Datum verlopen</th>
                    <th className="text-center">Aantal dagen open</th>
                    <th>Reden</th>
                    <th style={{ minWidth: "175px" }}>Verlopen door</th>
                </tr>
                </thead>
                <tbody>
                { leads.map((lead) => {
                    const url = "/lead/" + lead.id;
                    return (
                        <tr
                            key={ lead.id }
                            className="table-clickable-row"
                        >
                            <th className="align-middle text-center">
                                <Link to={ url }>
                                    { lead.id }
                                </Link>
                            </th>
                            <td className="align-middle">
                                <Link to={ url }>
                                    { lead.name }
                                </Link>
                            </td>
                            <td className="align-middle">
                                <Link to={ url }>
                                    <TagPill color={ lead.status.color }>
                                        { lead.status.name }
                                    </TagPill>
                                </Link>
                            </td>
                            <td className="align-middle">
                                <Link to={ url }>
                                    <TagPill color={ lead.source.color }>
                                        { lead.source.name }
                                    </TagPill>
                                </Link>
                            </td>
                            <td className="align-middle">
                                <Link to={ url }>
                                    { lead.type ? (
                                        <TagPill color={ lead.type.color }>
                                            { lead.type.name }
                                        </TagPill>
                                    ) : (
                                        <TagPill variant="secondary">
                                            Onbekend
                                        </TagPill>
                                    )}
                                </Link>
                            </td>
                            <td className="align-middle">
                                <Link to={ url }>
                                    <DateFormatter date={ lead.date } format="YYYY-MM-DD"/>
                                </Link>
                            </td>
                            <td className="align-middle">
                                <Link to={ url }>
                                    <DateFormatter date={ lead.expiredDate } format="YYYY-MM-DD"/>
                                </Link>
                            </td>
                            <td className="align-middle text-center">
                                <Link to={ url }>
                                    { moment(lead.expiredDate).diff(moment(lead.date), "days") }
                                </Link>
                            </td>
                            <td className="align-middle">
                                <Link to={ url }>
                                    { lead.expiredReason }
                                </Link>
                            </td>
                            <td className="align-middle">
                                <Link to={ url }>
                                    { lead.expiredUser?.name ?? "Onbekend" }
                                </Link>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
        </React.Fragment>
    );
}

export default React.memo(LeadsExpiredStatistics);

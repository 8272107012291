import React from "react";

import DeviceTypeIcon from "../../../../components/comments/DeviceTypeIcon";
import DateFormatter from "../../../../components/formatters/DateFormatter";

function RestockOrderExpectedDeliveryDateHistoryItemCard({ historyItem }) {
    return (
        <div className="card mb-2">
            <div className="card-body p-3">
                <div className="row">
                    <div className="col-3 text-left d-flex align-items-center">
                        <DeviceTypeIcon deviceType={ historyItem.deviceType } className="mr-2"/>
                        <div>
                            <b>{ historyItem.author.name }</b>
                        </div>
                    </div>

                    <div className="col d-flex flex-column justify-content-center" style={{ fontSize: "1.25rem" }}>
                        <div className="d-flex w-100">
                            <div className="flex-grow-1 text-right" style={{ width: "30%" }}>
                                <DateFormatter
                                    date={ historyItem.prevDeliveryDate }
                                    format="DD-MM-YYYY"
                                />
                            </div>
                            <div className="text-center">
                                <i className="far fa-long-arrow-right mx-4"/>
                            </div>
                            <div className="flex-grow-1 text-left" style={{ width: "30%" }}>
                                <DateFormatter
                                    date={ historyItem.newDeliveryDate }
                                    format="DD-MM-YYYY"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-3 text-right d-flex flex-column justify-content-center">
                        <div>
                            <DateFormatter date={ historyItem.date }/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(RestockOrderExpectedDeliveryDateHistoryItemCard);

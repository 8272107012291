import React, {
    useContext
} from "react";
import {
    Alert,
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import {
    PlanningContext
} from "../context/PlanningContext";

function PlanningFilterNotice() {
    const planningContext = useContext(PlanningContext);

    if(!planningContext.filterActiveNotice) {
        return null;
    }

    return (
        <Alert variant="warning" className="text-center">
            <div className="d-flex w-100 align-items-center">
                <div className="flex-grow-1">
                    { planningContext.filterActiveNotice }
                </div>
                <div>
                    <OverlayTrigger placement="top" overlay={
                        <Tooltip id="address">Filters uitschakelen</Tooltip>
                    }>
                        <Button variant="link" className="ml-3" onClick={ planningContext.resetAllFilters }>
                            <i className="fas fa-times"/>
                        </Button>
                    </OverlayTrigger>
                </div>
            </div>
        </Alert>
    );
}

export default React.memo(PlanningFilterNotice);

import React from "react";
import axios from "axios";

import DetailImages from "../../../components/detail/DetailImages";

class ProductDetailImages extends DetailImages {
    getImages(page) {
        this.setState({ images: null, error: null });
        const amount = this.state.amountPerPage;
        const offset = page * amount;
        axios.post("/getProductImages", { productId: this.props.productId, amount, offset })
            .then((response) => {
                if(response.data.valid) {
                    if(page === this.state.page) {
                        this.setState({ images: response.data.data, totalAmount: response.data.totalAmount });
                    }
                } else {
                    this.setState({ images: null, error: "Er was een fout bij het ophalen van de data. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ images: null, error: "Er was een fout bij het ophalen van de data." });
            });
    }

    setAsThumbnail(image, onComplete) {
        if(!image) {
            return;
        }
        axios.post("/setInventoryProductThumbnail", { productId: this.props.productId, imageId: image.id })
            .then((response) => {
                if(response.data.valid) {
                    onComplete();
                    this.props.refreshProduct();
                    this.getImages(this.state.page);
                } else {
                    this.setState({ images: null, error: "Er was een fout bij het ophalen van de data. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ images: null, error: "Er was een fout bij het ophalen van de data." });
            });
    }

    getEmptyStateDescription() {
        return (
            <p>Er zijn nog geen foto's toegevoegd aan dit product.</p>
        )
    }

    canUploadImages() {
        return this.props.product !== null;
    }

    getUploadRequestParameters() {
        return {
            productId: this.props.product.id
        };
    }

    getCurrentThumbnail() {
        if(!this.props.product) {
            return null;
        }
        return this.props.product.thumbnailImage;
    }
}

export default ProductDetailImages;

import React, {
    createContext,
    useMemo,
    useState
} from "react";
import {
    useRouteMatch,
    withRouter
} from "react-router-dom";

import {
    useLocalStorage
} from "@zandor300/backoffice-framework";
import PlanningFilterModal from "../modal/PlanningFilterModal";

export const PlanningContext = createContext(null);

const showAbsencesLocalStorageKey = "planning-showAbsences";
const showAvailabilityLocalStorageKey = "planning-showAvailability";
const showAllProductsLocalStorageKey = "planning-showAllProducts";
const installationTypeFilterLocalStorageKey = "planning-installationTypeFilter";
const planningItemTypeFilterLocalStorageKey = "planning-planningItemTypeFilter";

const installationTypeFilterOptions = [
    { value: "all", name: "Alles", showsAll: true, allowedLeadTypeIds: [] },
    { value: "residential", name: "Particulieren", showsAll: false, allowedLeadTypeIds: [1] },
    { value: "business", name: "Bedrijven", showsAll: false, allowedLeadTypeIds: [2] }
];
const planningItemTypeFilterOptions = [
    { value: "all", name: "Alles", showsAll: true, allowedPlanningItemTypeIds: [] },
    { value: "installation", name: "Installaties", showsAll: false, allowedPlanningItemTypeIds: [1, 3] },
    { value: "outage", name: "Storingen", showsAll: false, allowedPlanningItemTypeIds: [2] }
];

function PlanningManagerInternal({ children, match }) {
    const [showFilterModal, setShowFilterModal] = useState(false);

    const [showAbsences, setShowAbsences] = useLocalStorage(showAbsencesLocalStorageKey, false);
    const [showAvailability, setShowAvailability] = useLocalStorage(showAvailabilityLocalStorageKey, false);
    const [showAllProducts, setShowAllProducts] = useLocalStorage(showAllProductsLocalStorageKey, false);
    const [installationTypeFilter, setInstallationTypeFilter] = useLocalStorage(installationTypeFilterLocalStorageKey, "all");
    const [planningItemTypeFilter, setPlanningItemTypeFilter] = useLocalStorage(planningItemTypeFilterLocalStorageKey, "all");

    const calendarMatch = useRouteMatch([
        `${match.path}/day`,
        `${match.path}/week`,
        `${match.path}/workweek`,
        `${match.path}/month`
    ]);
    const filterMatch = useRouteMatch([
        `${match.path}/week`,
        `${match.path}/workweek`,
        `${match.path}/month`
    ]);
    const showAvailabilityCheckboxMatch = useRouteMatch([
        `${match.path}/week`,
        `${match.path}/workweek`
    ]);

    const currentInstallationTypeFilter = useMemo(() => {
        return installationTypeFilterOptions.find((searchFilter) => searchFilter.value === installationTypeFilter);
    }, [installationTypeFilter]);
    const currentPlanningItemTypeFilter = useMemo(() => {
        return planningItemTypeFilterOptions.find((searchFilter) => searchFilter.value === planningItemTypeFilter);
    }, [planningItemTypeFilter]);

    const filterActiveNotice = useMemo(() => {
        let activeFilters = [];
        if(!currentInstallationTypeFilter.showsAll) {
            activeFilters.push(currentInstallationTypeFilter.name);
        }
        if(!currentPlanningItemTypeFilter.showsAll) {
            activeFilters.push(currentPlanningItemTypeFilter.name);
        }
        if(activeFilters.length === 0) {
            return null;
        }
        return "Filter(s) ingeschakeld: " + activeFilters.join(", ");
    }, [currentInstallationTypeFilter, currentPlanningItemTypeFilter]);
    const resetAllFilters = useMemo(() => {
        return () => {
            setInstallationTypeFilter(installationTypeFilterOptions[0].value);
            setPlanningItemTypeFilter(planningItemTypeFilterOptions[0].value);
        }
    }, [setInstallationTypeFilter, setPlanningItemTypeFilter]);

    const contextState = useMemo(() => {
        return {
            calendarMatch,

            showAbsences, setShowAbsences,
            showAbsenceCheckboxMatch: calendarMatch,

            showAvailability, setShowAvailability,
            showAvailabilityCheckboxMatch,

            showAllProducts, setShowAllProducts,

            installationTypeFilter, setInstallationTypeFilter, installationTypeFilterOptions, currentInstallationTypeFilter,
            planningItemTypeFilter, setPlanningItemTypeFilter, planningItemTypeFilterOptions, currentPlanningItemTypeFilter,
            showFilterSelect: filterMatch,

            setShowFilterModal,
            filterActiveNotice, resetAllFilters
        }
    }, [
        calendarMatch, filterMatch, showAvailabilityCheckboxMatch,
        showAbsences, setShowAbsences,
        showAvailability, setShowAvailability,
        showAllProducts, setShowAllProducts,
        installationTypeFilter, setInstallationTypeFilter, currentInstallationTypeFilter,
        planningItemTypeFilter, setPlanningItemTypeFilter, currentPlanningItemTypeFilter,
        setShowFilterModal,
        filterActiveNotice, resetAllFilters
    ]);

    const handeCloseFilterModal = useMemo(() => {
        return () => setShowFilterModal(false);
    }, []);

    return (
        <PlanningContext.Provider value={ contextState }>
            <PlanningFilterModal
                show={ showFilterModal }
                handleClose={ handeCloseFilterModal }
            />
            { children }
        </PlanningContext.Provider>
    )
}

const PlanningManager = withRouter(PlanningManagerInternal);

export function withPlanningContext(Component) {
    return function contextComponent(props) {
        return (
            <PlanningContext.Consumer>
                {context => <Component {...props} planningContext={context} />}
            </PlanningContext.Consumer>
        )
    }
}

export default PlanningManager;

import React, {
    useMemo
} from "react";
import moment from "moment";
import {
    Button
} from "react-bootstrap";

import TagPill from "../../../components/tagPill";

function WeekCellContent({ currentDate, selectedDate, showDate }) {
    return (
        <div className="d-flex flex-column">
            <div>
                Week
            </div>
            { showDate && (
                <div style={{ fontWeight: 400 }}>
                    { selectedDate.format("GGGG-WW") === currentDate.format("GGGG-WW") ? (
                        <TagPill>
                            { selectedDate.format("W") }
                        </TagPill>
                    ) : (
                        <React.Fragment>
                            { selectedDate.format("W") }
                        </React.Fragment>
                    )}
                </div>
            )}
        </div>
    );
}

function DateCellContent({ selectedDate, currentDate, showDate, showMapButton, onOpenMap }) {
    return (
        <div className="d-flex flex-row align-items-center">
            <div className="d-flex flex-column flex-grow-1">
                <div>
                    { selectedDate.format("dddd") }
                </div>
                { showDate && (
                    <div style={{ fontWeight: 400 }}>
                        { selectedDate.format("YYYY-MM-DD") === currentDate.format("YYYY-MM-DD") ? (
                            <TagPill>
                                { selectedDate.format("D MMM") }
                            </TagPill>
                        ) : (
                            <React.Fragment>
                                { selectedDate.format("D MMM") }
                            </React.Fragment>
                        )}
                    </div>
                )}
            </div>
            { showDate && (
                <div>
                    { showMapButton && (
                        <Button
                            variant="link"
                            className="p-0 text-secondary"
                            onClick={ () => onOpenMap(selectedDate.format("YYYY-MM-DD")) }
                        >
                            <i className="fas fa-map"/>
                        </Button>
                    )}
                </div>
            )}
        </div>
    );
}

function PlanningHeader({ selectedDate, viewType, onOpenMap }) {
    const numberOfDates = useMemo(() => {
        switch(viewType) {
            case "workweek":
                return 5;
            default:
                return 7;
        }
    }, [viewType]);
    const showDate = useMemo(() => {
        switch(viewType) {
            case "week":
            case "workweek":
                return true;
            default:
                return false;
        }
    }, [viewType]);
    const showMapButton = useMemo(() => {
        switch(viewType) {
            case "week":
            case "workweek":
                return true;
            default:
                return false;
        }
    }, [viewType]);

    const momentDates = useMemo(() => {
        if(!selectedDate) {
            return null;
        }
        const startDate = selectedDate.clone().startOf("week");
        let dates = [];
        for(let dateIndex = 0; dateIndex < numberOfDates; dateIndex++) {
            dates.push(startDate.clone().weekday(dateIndex));
        }
        return dates;
    }, [selectedDate, numberOfDates]);

    const currentMoment = useMemo(() => {
        return moment();
    }, []);

    if(!momentDates) {
        return null;
    }
    return (
        <thead className="thead-light">
            <tr className="tr-sticky">
                <th className="text-center" style={{ width: "75px" }}>
                    <WeekCellContent
                        selectedDate={ selectedDate }
                        currentDate={ currentMoment }
                        showDate={ showDate }
                    />
                </th>
                { momentDates.map((momentDate) => (
                    <th className="text-left">
                        <DateCellContent
                            selectedDate={ momentDate }
                            currentDate={ currentMoment }
                            showDate={ showDate }
                            showMapButton={ showMapButton }
                            onOpenMap={ onOpenMap }
                        />
                    </th>
                ))}
            </tr>
        </thead>
    )
}

export default React.memo(PlanningHeader);

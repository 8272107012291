import React, {
    useMemo
} from "react";
import {
    Button,
    Card,
    Modal
} from "react-bootstrap";

import Loading from "../../../components/Loading";
import PlanningItemCard from "./PlanningItemCard";

function PlanningItemHistoryModal({ show, handleClose, planningItem }) {
    const history = useMemo(() => {
        if(!planningItem) {
            return null;
        }
        let historyItems = [];
        let prevDate = planningItem.dateCreated;
        let prevAuthor = planningItem.author;
        for(const historyItem of planningItem.history) {
            historyItems.push({
                ...historyItem,
                editDate: prevDate,
                author: prevAuthor
            });
            prevDate = historyItem.editDate;
            prevAuthor = historyItem.author;
        }
        historyItems.push({
            ...planningItem,
            history: null,
            editDate: prevDate,
            author: prevAuthor
        });
        return historyItems.reverse();
    }, [planningItem]);
    return (
        <Modal size="lg" show={ show } onHide={ handleClose }>
            <Modal.Header closeButton>
                <Modal.Title>Geschiedenis</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { !history ? (
                    <Loading/>
                ) : (
                    <React.Fragment>
                        { history.map((historyItem, index) => {
                            let type = "between";
                            if(index + 1 === history.length) {
                                type = "original";
                            }
                            if(index === 0) {
                                type = "current"
                            }
                            return (
                                <PlanningItemCard
                                    key={ historyItem.id }
                                    planningItem={ historyItem }
                                    className={ type === "original" ? "border-primary mb-0" : type === "current" ? "border-success" : "" }
                                    noHeader
                                    noSuffix
                                    customCardHeader={
                                        type === "original" ? (
                                            <Card.Header>
                                                <b className="text-primary">Originele versie</b>
                                            </Card.Header>
                                        ) : type === "current" && (
                                            <Card.Header>
                                                <b className="text-success">Huidige versie</b>
                                            </Card.Header>
                                        )
                                    }
                                />
                            )
                        })}
                    </React.Fragment>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose }>
                    Sluiten
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default React.memo(PlanningItemHistoryModal);

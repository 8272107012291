import React, {
    useContext,
    useEffect,
    useState
} from "react";
import axios from "axios";
import moment from "moment";
import {
    Alert
} from "react-bootstrap";
import {
    Chart
} from "react-charts";

import ChartLoading from "../components/ChartLoading";
import ChartNoData from "../components/ChartNoData";
import {
    ChartCardContext
} from "../components/ChartCard";

function LeadStatusChangesPerStatusChart({ startDate, endDate }) {
    const chartCardContext = useContext(ChartCardContext);
    const [data, setData] = useState(null);
    const [colors, setColors] = useState(undefined);
    const [error, setError] = useState(null);

    useEffect(() => {
        if(!chartCardContext) {
            return;
        }
        const startDateFormat = startDate.format("YYYY-MM-DD");
        const endDateFormat = endDate.format("YYYY-MM-DD");
        const userId = chartCardContext.selectedUser ? chartCardContext.selectedUser : undefined;
        setData(null);
        setColors(null);
        setError(null);
        axios.post("/charts/leadStatusChangesPerStatusChart.php", { userId, startDate: startDateFormat, endDate: endDateFormat })
            .then((response) => {
                if(response.data.valid) {
                    setData(response.data.data);
                    setColors(response.data.colors);
                } else {
                    setError("Er ging iets fout. (" + response.data.error + ")");
                }
            })
            .catch((error) => {
                console.error(error);
                setError("Er ging iets fout.");
            })
    }, [chartCardContext, startDate, endDate]);

    const primaryAxis = React.useMemo(
        () => ({
            getValue: (datum) => moment(datum.key).format("DD-MM-YYYY")
        }), []
    );
    const secondaryAxes = React.useMemo(
        () => [
            {
                getValue: (datum) => datum.value,
                stacked: true
            },
        ], []
    );

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        )
    }
    if(!data) {
        return (
            <ChartLoading/>
        )
    }
    if(data.length === 0) {
        return (
            <ChartNoData/>
        )
    }
    return (
        <Chart
            options={{
                data,
                defaultColors: colors,
                primaryAxis,
                secondaryAxes,
            }}
        />
    )
}

export default React.memo(LeadStatusChangesPerStatusChart);

import React from "react";
import axios from "axios";

import DetailImages from "../../../components/detail/DetailImages";

class UserDetailImages extends DetailImages {
    getImages(page) {
        this.setState({ images: null, error: null });
        const amount = this.state.amountPerPage;
        const offset = page * amount;
        axios.post("/getUserImages", { userId: this.props.userId, amount, offset })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ images: response.data.data, totalAmount: response.data.totalAmount });
                } else {
                    this.setState({ images: null, error: "Er was een fout bij het ophalen van de data. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ images: null, error: "Er was een fout bij het ophalen van de data." });
            });
    }

    getEmptyStateDescription() {
        return (
            <p>Deze gebruiker heeft nog nooit foto's geupload.</p>
        )
    }

    canUploadImages() {
        return false;
    }

    getUploadRequestParameters() {
        return {};
    }
}

export default UserDetailImages;

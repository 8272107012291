import React, {
    useMemo,
    useRef,
    useState
} from "react";
import copy from "copy-to-clipboard";
import {
    CSSTransition
} from "react-transition-group";
import {
    Overlay,
    Tooltip
} from "react-bootstrap";

function ClickCopy({ children, text, variant = "text" }) {
    const [copied, setCopied] = useState(false);
    const target = useRef(null);

    const onClick = useMemo(() => {
        return () => {
            copy(text, { format: "text/plain" });
            setCopied(true);
            setTimeout(() => {
                setCopied(false);
            }, 2000);
        }
    }, [text]);

    return (
        <span ref={ target } onClick={ onClick }>
            { children }
            { variant === "text" ? (
                <CSSTransition in={ copied } timeout={200} classNames="copy-fade" unmountOnExit>
                    <small className="text-primary mx-2">
                        Gekopieërd
                    </small>
                </CSSTransition>
            ) : variant === "tooltip" && (
                <Overlay target={ target.current } show={ copied } placement="top">
                    {(props) => (
                        <Tooltip id="overlay-example" {...props}>
                            Gekopieërd
                        </Tooltip>
                    )}
                </Overlay>
            )}
        </span>
    );

}

export default React.memo(ClickCopy);

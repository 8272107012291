import React from "react";
import {
    Link
} from "react-router-dom";

import DateFormatter from "../../../../components/formatters/DateFormatter";
import NumberFormatter from "../../../../components/formatters/NumberFormatter";

function ProductDetailReservedRow({ date, values, toOrder }) {
    const allFulfilled = undefined === values.find((value) => !value.fulfilled);
    return values.map((row, index) => {
        const showDate = index === 0;
        return (
            <tr key={ index }>
                { showDate && (
                    <th rowSpan={ values.length } style={{ minWidth: "120px" }} className={ allFulfilled ? "table-success" : "" }>
                        <DateFormatter date={ date } format="DD-MM-YYYY"/>
                    </th>
                )}
                { showDate && (
                    <td rowSpan={ values.length } style={{ minWidth: "120px" }} className={ "text-right" + (allFulfilled ? " table-success" : "") }>
                        { toOrder === 0 ? "" : toOrder }
                    </td>
                )}
                <td className={ "text-right" + (row.fulfilled ? " table-success" : "") } >
                    <NumberFormatter number={ row.amount }/>
                </td>
                <td className={ row.fulfilled ? " table-success" : "" }>
                    { row.fulfilledByInventoryAlone ? (
                        <div className="text-success">
                            <i className="fas fa-check fa-fw mr-2"/>
                            Is op voorraad
                        </div>
                    ) : row.fulfilled ? (
                        <div className="text-success">
                            <i className="fas fa-truck fa-fw mr-2"/>
                            Besteld bij leverancier
                        </div>
                    ) : (
                        <div className="text-danger">
                            <i className="fas fa-times fa-fw mr-2"/>
                            Niet op voorraad
                        </div>
                    )}
                </td>
                <td className={ row.fulfilled ? "table-success" : "" }>
                    { row.installations.map((installation, index) => (
                        <div className={ index === 0 ? "" : "mt-2" }>
                            <Link to={ "/installation/" + installation.id }>
                                { installation.lead ? installation.lead.name : installation.outage ? installation.outage.name : "Unknown name" }
                                { " #" + installation.id }
                            </Link>
                        </div>
                    ))}
                </td>
            </tr>
        )
    })
}

export default React.memo(ProductDetailReservedRow);

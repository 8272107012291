import React, {
    useEffect,
    useState
} from "react";
import axios from "axios";
import moment from "moment";
import {
    Alert
} from "react-bootstrap";
import {
    Chart
} from "react-charts";

import ChartLoading from "../components/ChartLoading";
import ChartNoData from "../components/ChartNoData";

function ReminderCountPerUserChart({ startDate, endDate }) {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const startDateFormat = startDate.format("YYYY-MM-DD");
        const endDateFormat = endDate.format("YYYY-MM-DD");
        axios.post("/charts/reminderCountPerUserChart.php", { startDate: startDateFormat, endDate: endDateFormat })
            .then((response) => {
                if(response.data.valid) {
                    setData(response.data.data);
                } else {
                    setError("Er ging iets fout. (" + response.data.error + ")");
                }
            })
            .catch((error) => {
                console.error(error);
                setError("Er ging iets fout.");
            })
    }, [startDate, endDate]);

    const primaryAxis = React.useMemo(
        () => ({
            getValue: (datum) => moment(datum.key).toDate(),
            tickCount: 20,
            scaleType: "time",
            hardMin: (() => {
                if(!data || data.length <= 0 || data[0].data.length <= 0) return null;
                return moment(data[0].data[0].key).toDate();
            })(),
            formatters: {
                scale: (date) => moment(date).format("DD-MM-YYYY")
            }
        }), [data]
    );
    const secondaryAxes = React.useMemo(
        () => [
            {
                getValue: (datum) => datum.value,
                elementType: "line"
            },
        ], []
    );

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        )
    }
    if(!data) {
        return (
            <ChartLoading/>
        )
    }
    if(data.length === 0) {
        return (
            <ChartNoData/>
        )
    }
    return (
        <Chart
            options={{
                data,
                primaryAxis,
                secondaryAxes,
            }}
        />
    )
}

export default React.memo(ReminderCountPerUserChart);

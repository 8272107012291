import React from "react";

import PrintFormTextLine from "../../../../components/print/PrintFormTextLine";
import PrintFormCheckbox from "../../../../components/print/PrintFormCheckbox";
import PrintFormPanelLayoutDrawing from "../../../../components/print/PrintFormPanelLayoutDrawing";

function LeadDetailPrintForm() {
    return (
        <React.Fragment>
            <div className="card mb-4">
                <div className="card-body">
                    <p className="card-text">
                        Gebruik de scan functie in de Sales app om dit ingevulde document te scannen en in het intranet
                        op te slaan.
                    </p>
                    <p className="card-text">
                        Scan de QR Code aan de andere kant om de Lead te openen in de Sales app. Ga naar{" "}
                        <b>Notities</b>. Voeg een notitie toe. Voeg een foto toe aan je notitie en selecteer hierbij de{" "}
                        <b>Scan document</b> optie.
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-5">
                    <div className="mb-3">
                        <h5>Type aansluiting</h5>
                        <div className="row mb-3">
                            <div className="col">
                                <PrintFormCheckbox title="1 fase"/>
                            </div>
                            <div className="col">
                                <PrintFormCheckbox title="3 fase"/>
                            </div>
                        </div>
                    </div>

                    <h5>Plaats omvormer</h5>
                    <PrintFormTextLine lines={ 2 }/>

                    <h5>Omvormer(s)</h5>
                    <div className="row mb-3">
                        <div className="col">
                            <PrintFormCheckbox title="Afore"/>
                            <PrintFormCheckbox title="SolarEdge"/>
                            <PrintFormCheckbox title="Solax"/>
                        </div>
                        <div className="col">
                            <PrintFormCheckbox title="Solis"/>
                            <PrintFormCheckbox title="APS"/>
                            <PrintFormCheckbox title="Enphase"/>
                        </div>
                    </div>
                    <p>Bijzonderheden:</p>
                    <PrintFormTextLine lines={ 2 }/>

                    <h5>Hoe gaat bekabeling van panelen naar omvormer?</h5>
                    <PrintFormTextLine lines={ 3 }/>

                    <h5>Hoe gaat bekabeling van omvormer naar meterkast?</h5>
                    <PrintFormCheckbox title="Wasmachinegroep"/>
                    <PrintFormCheckbox title="Via loze leiding"/>
                    <PrintFormCheckbox title="Anders:"/>
                    <PrintFormTextLine lines={ 3 }/>

                    <h5>Groepenkast vervangen</h5>
                    <div className="row mb-3">
                        <div className="col">
                            <PrintFormCheckbox title="8 groepen"/>
                            <PrintFormCheckbox title="12 groepen"/>
                        </div>
                        <div className="col">
                            <PrintFormCheckbox title="1 fase"/>
                            <PrintFormCheckbox title="3 fase"/>
                        </div>
                    </div>
                    <PrintFormCheckbox title="Kookgroep"/>
                    <p>Bijzonderheden:</p>
                    <PrintFormTextLine lines={ 3 }/>

                    <h5>Veiligheid</h5>
                    <PrintFormCheckbox title="Ladderlift"/>
                    <PrintFormCheckbox title="RSS dakrandbeveiliging"/>
                    <PrintFormCheckbox title="Hoogwerker"/>
                    <PrintFormCheckbox title="Anders:"/>
                    <PrintFormTextLine lines={ 2 }/>
                    <PrintFormCheckbox title="Geen van bovenstaande"/>
                </div>
                <div className="col">
                    <h5>Legplan tekening</h5>
                    <PrintFormPanelLayoutDrawing/>

                    <h5>Overige bijzonderheden</h5>
                    <PrintFormTextLine lines={ 10 }/>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(LeadDetailPrintForm);

import React from "react";
import axios from "axios";
import moment from "moment";
import {
    Alert
} from "react-bootstrap";

import ChartCard from "../../statistics/components/ChartCard";
import ProductAvailabilityChart from "./components/ProductAvailabilityChart";
import ProductTakenAmountChart from "./components/ProductTakenAmountChart";

class ProductDetailStatistics extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            amountAvailable: null,
            amountNotPlannedIn: null,
            amountTakenWeekly: null,
            amountTakenMonthly: null,
            error: null
        }
    }

    componentDidMount() {
        this.getReserved();
        this.getTakenWeekly();
        this.getTakenMonthly();
    }

    getReserved() {
        axios.post("/getAmountAvailableThroughTimeForProduct", { productId: this.props.productId })
            .then((response) => {
                if(response.data.valid) {
                    const {
                        amountAvailable,
                        amountNotPlannedIn
                    } = response.data;
                    this.setState({
                        amountAvailable,
                        amountNotPlannedIn
                    });
                } else {
                    this.setState({ error: "Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ error: "Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw." });
                console.error(error);
            });
    }

    getTakenWeekly() {
        const startDate = moment("2022-01-01").format("YYYY-MM-DD");
        const endDate = moment().format("YYYY-MM-DD");
        axios.post("/charts/takenProductCountOfProductPerWeekChart.php", { productId: this.props.productId, startDate, endDate })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ amountTakenWeekly: response.data.data });
                } else {
                    this.setState({ error: "Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ error: "Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw." });
                console.error(error);
            });
    }

    getTakenMonthly() {
        const startDate = moment("2022-01-01").format("YYYY-MM-DD");
        const endDate = moment().format("YYYY-MM-DD");
        axios.post("/charts/takenProductCountOfProductPerMonthChart.php", { productId: this.props.productId, startDate, endDate })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ amountTakenMonthly: response.data.data });
                } else {
                    this.setState({ error: "Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ error: "Er ging iets fout bij het ophalen van de data. Probeer het later opnieuw." });
                console.error(error);
            });
    }

    render() {
        const {
            amountAvailable,
            amountTakenWeekly,
            amountTakenMonthly,
            error
        } = this.state;
        const {
            product
        } = this.props;
        if(error) {
            return (
                <Alert variant="danger">{ error }</Alert>
            )
        }
        return (
            <React.Fragment>
                <ChartCard title="Aantal beschikbaar in de toekomst">
                    <ProductAvailabilityChart
                        amountAvailable={ amountAvailable }
                        preferredMinAmount={ product?.preferredMinAmount }
                    />
                </ChartCard>
                <ChartCard title="Aantal afgenomen per week">
                    <ProductTakenAmountChart data={ amountTakenWeekly }/>
                </ChartCard>
                <ChartCard title="Aantal afgenomen per maand">
                    <ProductTakenAmountChart data={ amountTakenMonthly }/>
                </ChartCard>
            </React.Fragment>
        );
    }

}

export default ProductDetailStatistics;

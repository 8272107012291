import React, {
    useContext,
    useMemo
} from "react";
import moment from "moment";
import {
    Button
} from "react-bootstrap";

import TagPill from "../../../components/tagPill";
import ReactMarkdownBreaks from "../../../components/ReactMarkdownBreaks";
import Loading from "../../../components/Loading";
import PlanningItemCalendarCard from "./PlanningItemCalendarCard";
import {
    PlanningContext
} from "../context/PlanningContext";

function PlanningCalendarCell(props) {
    const planningContext = useContext(PlanningContext);
    const today = moment().startOf("day");
    const beforeToday = props.moment < today;
    const dayString = props.moment.format(props.viewType === "month" ? "D" : "D MMM");
    const openMap = useMemo(() => {
        return () => {
            props.onClickOpenMapModal(props.moment.format("YYYY-MM-DD"));
        }
    }, [props]);

    const showDate = useMemo(() => {
        switch(props.viewType) {
            case "month":
                return true;
            default:
                return false;
        }
    }, [props.viewType]);

    return (
        <td>
            { showDate && (
                <div className="mb-2 d-flex flex-row">
                    <div className="flex-grow-1">
                        { props.moment.format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") ? (
                            <TagPill>
                                { dayString }
                            </TagPill>
                        ) : (
                            dayString
                        )}
                    </div>
                    { props.planningItems && props.planningItems.length > 0 && (
                        <Button variant="link" className="p-0 text-secondary" onClick={ openMap }>
                            <i className="fas fa-map"/>
                        </Button>
                    )}
                </div>
            )}
            <div
                className={ "card mb-2 pointer-cursor" + (beforeToday ? " text-muted" : "") }
                onClick={ () => props.onClickPlanningComment(props.planningComment) }
            >
                <div className="card-body p-1">
                    { props.planningComment && props.planningComment.description.trim().length > 0 ? (
                        <ReactMarkdownBreaks message={ props.planningComment.description }/>
                    ) : (
                        <i className="text-muted">Geen notitie</i>
                    )}
                </div>
            </div>
            { planningContext.showAbsences && (
                <div className={ "card mb-2" + (props.moment < today ? " text-muted" : "") }>
                    <div className="card-body p-1">
                        <div>
                            <b>Absenties</b>
                        </div>
                        { !props.absenceItems ? (
                            <Loading/>
                        ) : props.absenceItems.length === 0 ? (
                            <i className="text-muted">Geen absenties</i>
                        ) : props.absenceItems.map((absenceItem) => (
                            <div className="pt-2" style={{ fontSize: "0.9rem" }} key={ absenceItem.id }>
                                <b>{ absenceItem.user.name } </b>
                                <br/>
                                <span>{ absenceItem.description }</span>
                            </div>
                        ))}
                    </div>
                </div>
            )}
            { props.planningItems.map((planningItem) => (
                <PlanningItemCalendarCard
                    key={ planningItem.id }
                    planningItem={ planningItem }
                    beforeToday={ beforeToday }
                    onClick={ () => props.onClickPlanningItem(planningItem) }
                />
            ))}
        </td>
    )
}

export default React.memo(PlanningCalendarCell);

import React, {
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert
} from "react-bootstrap";
import {
    Chart
} from "react-charts";

import ChartLoading from "../components/ChartLoading";
import ChartNoData from "../components/ChartNoData";

function LeadsCreatedCountMonthlyChart({ groupBy }) {
    const [data, setData] = useState(null);
    const [colors, setColors] = useState(undefined);
    const [error, setError] = useState(null);

    useEffect(() => {
        setData(null);
        setColors(null);
        setError(null);
        axios.post("/charts/leadsCreatedCountMonthlyChart.php", {
            groupBy
        })
            .then((response) => {
                if(response.data.valid) {
                    setData(response.data.data);
                    setColors(response.data.colors);
                } else {
                    setError("Er ging iets fout. (" + response.data.error + ")");
                }
            })
            .catch((error) => {
                console.error(error);
                setError("Er ging iets fout.");
            })
    }, [groupBy]);

    const primaryAxis = React.useMemo(
        () => ({
            getValue: (datum) => datum.key
        }), []
    );
    const secondaryAxes = React.useMemo(
        () => [
            {
                getValue: (datum) => datum.value,
                stacked: true
            },
        ], []
    );

    if(error) {
        return (
            <Alert variant="danger">{ error }</Alert>
        )
    }
    if(!data) {
        return (
            <ChartLoading/>
        )
    }
    if(data.length === 0) {
        return (
            <ChartNoData/>
        )
    }
    return (
        <Chart
            options={{
                data,
                defaultColors: colors,
                primaryAxis,
                secondaryAxes,
                tooltip: {
                    showDatumInTooltip: (datum) => {
                        return datum.originalDatum.value !== 0;
                    }
                },
            }}
        />
    )
}

export default React.memo(LeadsCreatedCountMonthlyChart);

import React from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Button,
    Card,
    Modal,
    Spinner
} from "react-bootstrap";

import TagPill from "../../../components/tagPill";
import PlanningItemDeleteModal from "./PlanningItemDeleteModal";
import PlanningItemModalForm from "./PlanningItemModalForm";
import Loading from "../../../components/Loading";
import PlanningItemHistoryModal from "../components/PlanningItemHistoryModal";

class PlanningItemEditModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            loading: false,

            planningItem: null,

            showDeleteConfirmation: false,
            showHistory: false
        }
        this.onShow = this.onShow.bind(this);
        this.setPlaningItemState = this.setPlaningItemState.bind(this);
    }

    onShow() {
        this.setState({
            error: null,
            planningItem: { ...this.props.planningItem }
        });
    }

    setPlaningItemState(state, callback) {
        this.setState((prevState) => {
            return { planningItem: { ...prevState.planningItem, ...state }}
        }, callback);
    }

    setPlanningItem() {
        const planningItem = this.state.planningItem;
        this.setState({ loading: true });

        const assignedUsers = planningItem.assignedUsers ? planningItem.assignedUsers.map((user) => user.id).join(",") : null;

        axios.post("/setPlanningItem", {
            planningItemId: planningItem.id,
            date: planningItem.date,
            time: planningItem.time,
            description: planningItem.description,
            assignedUsers
        })
            .then((response) => {
                if(response.data.valid) {
                    if(this.props.planningItemUpdated) {
                        this.props.planningItemUpdated(response.data.planningItem);
                    }
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    planningItemDeleted() {
        this.setState({ showDeleteConfirmation: false });
        this.props.handleClose();
        this.props.planningItemDeleted();
    }

    render() {
        const planningItem = this.state.planningItem;
        let parentButtonText = null;
        let parentUrl = null;
        let address = null;
        if(planningItem) {
            address = planningItem.street + " " + planningItem.houseNumber + ", " + planningItem.city;
            if(planningItem.lead) {
                parentButtonText = "Lead openen";
                parentUrl = "/lead/" + planningItem.lead.id;
            } else if(planningItem.outage) {
                parentButtonText = "Storing openen";
                parentUrl = "/outage/" + planningItem.outage.id;
            } else if(planningItem.installation) {
                parentButtonText = "Installatie openen";
                parentUrl = "/installation/" + planningItem.installation.id;
            }
        }

        const showHistory = this.props.showParent && planningItem && planningItem.history && planningItem.history.length > 0;

        return (
            <React.Fragment>
                { this.props.planningItemDeleted !== undefined && (
                    <PlanningItemDeleteModal
                        show={ this.state.showDeleteConfirmation }
                        planningItemDeleted={ this.planningItemDeleted.bind(this) }
                        planningItem={ planningItem }
                        handleClose={ () => this.setState({ showDeleteConfirmation: false }) }
                    />
                )}
                { showHistory && (
                    <PlanningItemHistoryModal
                        show={ this.state.showHistory }
                        planningItem={ planningItem }
                        handleClose={ () => this.setState({ showHistory: false }) }
                    />
                )}
                <Modal
                    show={ !this.state.showDeleteConfirmation && !this.state.showHistory && this.props.show }
                    onHide={ !this.state.showDeleteConfirmation ? this.props.handleClose : () => {} }
                    onShow={ this.onShow }
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Planning bewerken</Modal.Title>
                    </Modal.Header>
                    { !planningItem ? (
                        <Modal.Body>
                            <Loading/>
                        </Modal.Body>
                    ) : (
                        <React.Fragment>
                            <Modal.Body>
                                { this.state.error !== null && (
                                    <Alert variant="danger">{ this.state.error }</Alert>
                                )}
                                { this.props.showParent ? (
                                    <Card className="mb-3">
                                        <Card.Body className="d-flex" style={{ padding: "6px 12px" }}>
                                            <div className="flex-grow-1">
                                                <TagPill color={ planningItem.type.color }>
                                                    { planningItem.type.name }
                                                </TagPill>
                                                { planningItem.parentStatus && (
                                                    <TagPill color={ planningItem.parentStatus.color } className="ml-2">
                                                        { planningItem.parentStatus.name }
                                                    </TagPill>
                                                )}
                                                <br/>
                                                <b>{ planningItem.name }</b>
                                                <br/>
                                                { address }
                                            </div>
                                            { parentButtonText && parentUrl && (
                                                <div className="flex-shrink-1 d-flex align-items-center">
                                                    <Link to={ parentUrl } className="btn btn-secondary btn-sm float-right">
                                                        { parentButtonText }
                                                    </Link>
                                                </div>
                                            )}
                                        </Card.Body>
                                    </Card>
                                ) : this.props.showParent && (
                                    <Card className="mb-3">
                                        <Card.Body className="d-flex" style={{ padding: "6px 12px" }}>
                                            <b className="text-danger">Lead is null</b>
                                        </Card.Body>
                                    </Card>
                                )}
                                <PlanningItemModalForm
                                    planningItem={ planningItem }
                                    setState={ this.setPlaningItemState }
                                    loading={ this.state.loading }
                                />
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="flex-grow-1">
                                    { this.props.planningItemDeleted !== undefined && (
                                        <Button variant="danger" className="mr-2" onClick={ () => this.setState({ showDeleteConfirmation: true }) } disabled={ this.state.loading }>
                                            Verwijderen
                                        </Button>
                                    )}
                                    { showHistory && (
                                        <React.Fragment>
                                            <Button variant="secondary" className="mr-2" onClick={ () => this.setState({ showHistory: true }) } disabled={ this.state.loading }>
                                                Geschiedenis
                                            </Button>
                                            <span className="badge badge-pill badge-secondary">
                                                <i className="fas fa-history"/> { planningItem.history.length } keer bewerkt
                                            </span>
                                        </React.Fragment>
                                    )}
                                </div>
                                <Button variant="secondary" onClick={ this.props.handleClose } disabled={ this.state.loading }>
                                    Annuleer
                                </Button>
                                <Button variant="primary" onClick={ this.setPlanningItem.bind(this) } disabled={ this.state.loading }>
                                    { this.state.loading && (
                                        <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                                    )}
                                    Opslaan
                                </Button>
                            </Modal.Footer>
                        </React.Fragment>
                    )}
                </Modal>
            </React.Fragment>
        );
    }
}

export default PlanningItemEditModal;

import React, {
    useMemo
} from "react";
import {
    Link
} from "react-router-dom";
import {
    Card,
    Col,
    Row
} from "react-bootstrap";

import DateFormatter from "../../../components/formatters/DateFormatter";
import DeviceTypeIcon from "../../../components/comments/DeviceTypeIcon";
import numberFormatter from "../../../components/formatters/NumberFormatter";
import StatusDot from "../../../components/status/StatusDot";
import ReactMarkdownBreaks from "../../../components/ReactMarkdownBreaks";

function InventoryHistoryAction({ historyItem }) {
    const text = useMemo(() => {
        switch(historyItem.action) {
            case "manual": return "Handmatig aangepast";
            case "installationTakeProduct": return "Installatie afgenomen materiaal";
            case "installationUndoTakeProduct": return "Installatie ongedaan afgenomen materiaal";
            case "restockOrderDeliveredProduct": return "Bestelling afgeleverd materiaal";
            case "undoRestockOrderDeliveredProduct": return "Bestelling ongedaan afgeleverd materiaal";
            default: return "Unknown";
        }
    }, [historyItem]);
    const icon = useMemo(() => {
        switch(historyItem.action) {
            case "manual":
                return <i className="fas fa-hand-paper fa-fw"/>;
            case "installationTakeProduct":
            case "installationUndoTakeProduct":
                return <i className="fas fa-hard-hat fa-fw"/>;
            case "restockOrderDeliveredProduct":
            case "undoRestockOrderDeliveredProduct":
                return <i className="fas fa-pallet fa-fw"/>;
            default: return <i className="fas fa-question fa-fw"/>;
        }
    }, [historyItem]);
    return (
        <div className="d-flex flex-column flex-md-row">
            <div className="flex-grow-1 d-flex justify-content-start align-items-center text-left">
                <span className="mr-2">
                    { icon }
                </span>
                { text }
            </div>
            <div>
                { historyItem.installation ? (
                    <div className="d-flex align-items-center">
                        <Link to={`/installation/${historyItem.installation.id}`}>
                            { historyItem.installation.lead.name } #{ historyItem.installation.id }
                        </Link>
                        <StatusDot
                            name={ historyItem.installation.status.name }
                            color={ historyItem.installation.status.color }
                            className="ml-2"
                        />
                    </div>
                ) : historyItem.restockOrder && (
                    <div className="d-flex align-items-center">
                        <Link to={`/order/${historyItem.restockOrder.id}`}>
                            Bestelling #{ historyItem.restockOrder.id }
                        </Link>
                        <StatusDot
                            name={ historyItem.restockOrder.delivered ? "Geleverd" : "In afwachting" }
                            color={ historyItem.restockOrder.delivered ? "#28a745" : "#dc3545" }
                            className="ml-2"
                        />
                    </div>
                )}
            </div>
        </div>
    );
}

function InventoryProductHistoryItemCard({ historyItem }) {
    return (
        <Card className="mb-2">
            <Card.Body className="py-3">
                <Row>
                    <Col md={ 4 } className="text-left d-flex align-items-center">
                        <DeviceTypeIcon deviceType={ historyItem.deviceType } className="mr-2"/>
                        <div>
                            <b>{ historyItem.author.name }</b>
                        </div>
                    </Col>

                    <Col md={ 4 } className="d-flex flex-column justify-content-center" style={{ fontSize: "1.25rem", maxWidth: "300px" }}>
                        { historyItem.oldAmount === historyItem.newAmount ? (
                            <div className="text-center">
                                { historyItem.newAmount }
                            </div>
                        ) : (
                            <div className="d-flex w-100">
                                <div className="flex-grow-1 text-right" style={{ width: "35%" }}>
                                    { numberFormatter({ number: historyItem.oldAmount }) }
                                </div>
                                <div className="text-center" style={{ width: "20%" }}>
                                    <i className="far fa-long-arrow-right"/>
                                </div>
                                <div className="flex-grow-1 text-left" style={{ width: "35%" }}>
                                    { numberFormatter({ number: historyItem.newAmount }) }
                                </div>
                                <div className="d-flex justify-content-end" style={{ minWidth: "100px" }}>
                                    { historyItem.oldAmount > historyItem.newAmount ? (
                                        <div className="text-danger">
                                            <b>
                                                -{ numberFormatter({ number: historyItem.oldAmount - historyItem.newAmount }) }
                                            </b>
                                            <i className="fas fa-arrow-down fa-fw ml-2"/>
                                        </div>
                                    ) : (
                                        <div className="text-success">
                                            <b>
                                                +{ numberFormatter({ number: historyItem.newAmount - historyItem.oldAmount }) }
                                            </b>
                                            <i className="fas fa-arrow-up fa-fw ml-2"/>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </Col>

                    <Col className="text-right d-flex flex-column justify-content-center">
                        <div>
                            <DateFormatter date={ historyItem.date }/>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
            <Card.Footer className="text-muted">
                <InventoryHistoryAction historyItem={ historyItem }/>
                { historyItem.remark && (
                    <div className="mt-2 text-left">
                        <ReactMarkdownBreaks message={ historyItem.remark }/>
                    </div>
                )}
            </Card.Footer>
        </Card>
    );
}

export default React.memo(InventoryProductHistoryItemCard);

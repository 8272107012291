import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Form,
    Modal,
    Spinner
} from "react-bootstrap";

function ProductSetSafeInventorySettingsModal({ product, show, handleClose, onSaved }) {
    const [settingEnabled, setSettingEnabled] = useState(false);
    const [preferredMinAmount, setPreferredMinAmount] = useState("");
    const [safeDeliveryTimeDays, setSafeDeliveryTimeDays] = useState("");

    const [error, setError] = useState(null);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if(!show || !product) {
            return;
        }
        setSettingEnabled(product.preferredMinAmount !== null && product.safeDeliveryTimeDays !== null);
        setPreferredMinAmount(product.preferredMinAmount !== null ? product.preferredMinAmount : "");
        setSafeDeliveryTimeDays(product.safeDeliveryTimeDays !== null ? product.safeDeliveryTimeDays : "");

        setSaving(false);
        setError(null);
    }, [product, show]);

    const onSave = useCallback(async () => {
        if(settingEnabled && !(preferredMinAmount >= 0 && safeDeliveryTimeDays > 0)) {
            setError("Vul een voorkeurs minimale voorraad en veilige levertijd in.");
            return;
        }
        setSaving(true);
        setError(null);
        try {
            const response = await axios.post("/setProductSafeInventorySettings", {
                productId: product.id,
                enabled: settingEnabled ? 1 : 0,
                preferredMinAmount,
                safeDeliveryTimeDays
            });
            const newProduct = response.data.product;
            onSaved(newProduct);
            handleClose();
        } catch(error) {
            setError("Er is een fout opgetreden bij het opslaan van de bestelsignalen. Probeer het later opnieuw.");
            setSaving(false);
        }
    }, [product, handleClose, onSaved, preferredMinAmount, safeDeliveryTimeDays, settingEnabled]);

    return (
        <Modal show={ show } onHide={ handleClose }>
            <Modal.Header closeButton>
                <Modal.Title>
                    Bestelsignalen instellen
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                <Form.Group controlId="settingEnabled">
                    <Form.Check
                        type="switch"
                        label="Bestelsignalen aanzetten"
                        checked={ settingEnabled }
                        disabled={ saving }
                        onChange={ (event) => setSettingEnabled(event.target.checked) }
                    />
                </Form.Group>
                <Form.Group controlId="preferredMinAmount">
                    <Form.Label>Voorkeurs minimale voorraad</Form.Label>
                    <Form.Control
                        type="number"
                        value={ preferredMinAmount }
                        disabled={ saving || !settingEnabled }
                        onChange={ (event) => setPreferredMinAmount(event.target.value) }
                    />
                </Form.Group>
                <Form.Group controlId="safeDeliveryTimeDays">
                    <Form.Label>Veilige levertijd in dagen</Form.Label>
                    <Form.Control
                        type="number"
                        value={ safeDeliveryTimeDays }
                        disabled={ saving || !settingEnabled }
                        onChange={ (event) => setSafeDeliveryTimeDays(event.target.value) }
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose } disabled={ saving }>
                    Annuleren
                </Button>
                <Button variant="primary" onClick={ onSave } disabled={ saving }>
                    { saving && (
                        <Spinner animation="border" size="sm" className="mr-2"/>
                    )}
                    Opslaan
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default React.memo(ProductSetSafeInventorySettingsModal);

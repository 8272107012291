import React, {
    useCallback,
    useEffect,
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    OverlayTrigger,
    Spinner,
    Tooltip
} from "react-bootstrap";
import {
    useWindowSize
} from "@zandor300/backoffice-framework";
import {
    closestCenter,
    DndContext
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy
} from "@dnd-kit/sortable";
import {
    restrictToFirstScrollableAncestor
} from "@dnd-kit/modifiers";
import {
    CSS
} from "@dnd-kit/utilities";

import Loading from "../../../../components/Loading";
import TagPill from "../../../../components/tagPill";

function ProductSupplier({ supplier, onRemove, onAdd }) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging
    } = useSortable({ id: supplier.id, disabled: !onRemove });

    return (
        <div
            className="d-flex flex-row align-items-center"
            ref={ setNodeRef }
            style={{
                transform: CSS.Transform.toString(transform),
                transition,
                opacity: isDragging ? 0.5 : 1
            }}
        >
            { onRemove && (
                <OverlayTrigger placement="left" overlay={
                    <Tooltip id={ "supplier-" + supplier.id }>Leverancier verwijderen</Tooltip>
                }>
                    <Button variant="link" className="text-danger" onClick={ () => onRemove(supplier) }>
                        <i className="fas fa-times"/>
                    </Button>
                </OverlayTrigger>
            )}
            { onAdd && (
                <OverlayTrigger placement="left" overlay={
                    <Tooltip id={ "supplier-" + supplier.id }>Leverancier toevoegen</Tooltip>
                }>
                    <Button variant="link" className="text-success" onClick={ () => onAdd(supplier) }>
                        <i className="fas fa-plus"/>
                    </Button>
                </OverlayTrigger>
            )}
            <div
                className="flex-grow-1"
                style={{
                    cursor: onRemove ? (isDragging ? "grabbing" : "grab") : "default"
                }}
                { ...attributes }
                { ...listeners }
            >
                <TagPill color={ supplier.color }>
                    { supplier.name }
                </TagPill>
            </div>
        </div>
    )
}

function ProductSetSuppliersModal({product, show, handleClose, onSaved}) {
    const windowSize = useWindowSize();
    const [allSuppliers, setAllSuppliers] = useState(null);
    const [suppliers, setSuppliers] = useState(null);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState(null);

    const otherSuppliers = useMemo(() => {
        if (!allSuppliers) {
            return null;
        }
        if (!suppliers) {
            return allSuppliers;
        }
        return allSuppliers.filter((searchSupplier) => !suppliers.find((selectedSupplier) => selectedSupplier.id === searchSupplier.id));
    }, [allSuppliers, suppliers]);

    useEffect(() => {
        if (!show || !product) {
            return;
        }
        setSaving(false);
        setError(null);
        setSuppliers(product.suppliers);

        const refreshSuppliers = async () => {
            try {
                const response = await axios.get("/getSuppliers");
                setAllSuppliers(response.data.data.sort((supplier1, supplier2) => {
                    return supplier1.name.localeCompare(supplier2.name);
                }));
            } catch(error) {
                console.error(error);
                setError("Er is een fout opgetreden bij het ophalen van de leveranciers. Probeer het later opnieuw.");
            }
        };
        refreshSuppliers();
    }, [show, product]);

    const addSupplier = useCallback((supplier) => {
        setSuppliers([...suppliers, supplier]);
    }, [suppliers]);
    const removeSupplier = useCallback((supplier) => {
        setSuppliers(suppliers.filter((searchSupplier) => searchSupplier.id !== supplier.id));
    }, [suppliers]);

    const onDragEnd = useCallback(({ active, over}) => {
        if(!active || !over || active.id === over.id) {
            return;
        }
        setSuppliers((prevSuppliers) => {
            const oldIndex = prevSuppliers.findIndex((supplier) => supplier.id === active.id);
            const newIndex = prevSuppliers.findIndex((supplier) => supplier.id === over.id);
            return arrayMove(prevSuppliers, oldIndex, newIndex);
        });
    }, []);

    const onSave = useCallback(async () => {
        setSaving(true);
        setError(null);
        try {
            const response = await axios.post("/setProductSuppliers", {
                productId: product.id,
                supplierIds: suppliers.map((supplier) => supplier.id).join(",")
            });
            onSaved(response.data.product);
            handleClose();
        } catch(error) {
            console.error(error);
            setSaving(false);
            setError("Er is een fout opgetreden bij het opslaan van de leveranciers. Probeer het later opnieuw.");
        }
    }, [product, suppliers, onSaved, handleClose]);

    return (
        <Modal show={ show } onHide={ handleClose } size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    Leveranciers aanpassen
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                { !suppliers || !allSuppliers ? (
                    <Loading/>
                ) : (
                    <React.Fragment>
                        <p className="text-muted">
                            Klik op leveranciers om ze toe te voegen of te verwijderen.
                            Versleep de geselecteerde leveranciers om de voorkeursvolgorde aan te passen.
                        </p>

                        <h5>Geselecteerde leveranciers</h5>
                        <DndContext
                            collisionDetection={ closestCenter }
                            modifiers={ [restrictToFirstScrollableAncestor] }
                            onDragEnd={ onDragEnd }
                        >
                            <SortableContext
                                items={ suppliers }
                                strategy={ verticalListSortingStrategy }
                            >
                                { suppliers.length === 0 ? (
                                    <p>Geen leveranciers geselecteerd</p>
                                ) : suppliers.map((supplier) => (
                                    <ProductSupplier
                                        key={ supplier.id }
                                        supplier={ supplier }
                                        onRemove={ removeSupplier }
                                    />
                                ))}
                            </SortableContext>
                        </DndContext>

                        <h5 className="mt-3">Overige leveranciers</h5>
                        <div
                            className="d-flex flex-column flex-wrap"
                            style={{
                                maxHeight: (() => {
                                    const itemHeight = 38;
                                    if(windowSize.width >= 992) {
                                        return Math.ceil(otherSuppliers.length / 4) * itemHeight + "px";
                                    }
                                    if(windowSize.width >= 484) {
                                        return Math.ceil(otherSuppliers.length / 2) * itemHeight + "px";
                                    }
                                    return undefined;
                                })()
                            }}
                        >
                            { otherSuppliers.length === 0 ? (
                                <div>
                                    <p>Alle leveranciers geselecteerd</p>
                                </div>
                            ) : otherSuppliers.map((supplier) => (
                                <div
                                    key={ supplier.id }
                                    style={{
                                        width: (() => {
                                            if(windowSize.width >= 992) {
                                                return "25%";
                                            }
                                            if(windowSize.width >= 484) {
                                                return "50%";
                                            }
                                            return "100%";
                                        })()
                                    }}
                                >
                                    <ProductSupplier
                                        supplier={ supplier }
                                        onAdd={ addSupplier }
                                    />
                                </div>
                            ))}
                        </div>
                    </React.Fragment>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose } disabled={ saving }>
                    Annuleren
                </Button>
                <Button variant="primary" onClick={ onSave } disabled={ saving }>
                    { saving && (
                        <Spinner animation="border" size="sm" className="mr-2"/>
                    )}
                    Opslaan
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default React.memo(ProductSetSuppliersModal);

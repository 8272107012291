import React, {
    useCallback,
    useEffect,
    useRef,
    useState
} from "react";
import axios from "axios";
import moment from "moment";
import {
    useHistory
} from "react-router-dom";
import {
    Alert,
    Button,
    Spinner
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import Loading from "../../components/Loading";

function LeadAddSolarEdge() {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
    const [solarEdgeSiteId, setSolarEdgeSiteId] = useState("");
    const prevSolarEdgeSiteId = useRef();
    const [message, setMessage] = useState("");

    const [solarEdgeSiteDetails, setSolarEdgeSiteDetails] = useState(null);

    const [street, setStreet] = useState("");
    const [houseNumber, setHouseNumber] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [city, setCity] = useState("");

    const onSubmit = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.post("/addLeadInternal", {
                name,
                email,
                phoneNumber,
                date,
                solarEdgeSiteId,
                street,
                houseNumber,
                postalCode,
                city,
                message,

                typeId: 8,
                sourceId: 21,
            });
            if(response.data.valid) {
                history.push("/lead/" + response.data.leadId);
            } else {
                setError("Er is iets fout gegaan. Probeer het later opnieuw. (" + response.data.error + ")");
            }
        } catch(requestError) {
            console.error(requestError);
            setError("Er is iets fout gegaan. Probeer het later opnieuw.");
        }
    }, [name, email, phoneNumber, solarEdgeSiteId, message, date, street, houseNumber, postalCode, city, history]);

    const loadSolarEdgeSiteDetails = useCallback(async (siteId) => {
        try {
            const response = await axios.post("/getSolarEdgeSiteDetails", { solarEdgeSiteId: siteId });
            if(prevSolarEdgeSiteId.current !== siteId) {
                return;
            }
            setSolarEdgeSiteDetails(response.data.siteDetails);
        } catch(requestError) {
            console.error(requestError);
            setSolarEdgeSiteDetails(null);
        }
    }, []);
    useEffect(() => {
        const solarEdgeSiteIdInt = parseInt(solarEdgeSiteId, 10);
        if(isNaN(solarEdgeSiteIdInt)) {
            setSolarEdgeSiteDetails(null);
            return;
        }
        prevSolarEdgeSiteId.current = solarEdgeSiteIdInt;
        loadSolarEdgeSiteDetails(solarEdgeSiteIdInt);
    }, [solarEdgeSiteId, loadSolarEdgeSiteDetails]);

    const applySolarEdgeSiteDetails = useCallback(() => {
        if(!solarEdgeSiteDetails) {
            return;
        }
        setStreet(solarEdgeSiteDetails.details.location.address.split(" ")[0]);
        setHouseNumber(solarEdgeSiteDetails.details.location.address.split(" ")?.[1]);
        setPostalCode(solarEdgeSiteDetails.details.location.zip);
        setCity(solarEdgeSiteDetails.details.location.city);
    }, [solarEdgeSiteDetails]);
    const applyUnknownAddress = useCallback(() => {
        setStreet("Onbekend");
        setHouseNumber("Onbekend");
        setPostalCode("Onbekend");
        setCity("Onbekend");
    }, []);

    return (
        <React.Fragment>
            <Helmet title="Lead toevoegen - SolarEdge" />
            <Title preTitle="Overzicht">
                Lead toevoegen via SolarEdge
            </Title>
            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}
            <div className="card mb-3">
                <div className="card-body">
                    <h5 className="card-title">Contactgegevens</h5>
                    <div className="form-group">
                        <label htmlFor="name">Naam</label>
                        <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                            disabled={loading}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">E-mailadres</label>
                        <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            disabled={loading}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="phone">Telefoonnummer</label>
                        <input
                            type="tel"
                            className="form-control"
                            id="phone"
                            name="phone"
                            value={phoneNumber}
                            onChange={(event) => setPhoneNumber(event.target.value)}
                            disabled={loading}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="date">Datum</label>
                        <input
                            type="date"
                            className="form-control"
                            id="date"
                            name="date"
                            value={date}
                            onChange={(event) => setDate(event.target.value)}
                            disabled={loading}
                        />
                    </div>
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-body">
                    <h5 className="card-title">SolarEdge</h5>
                    <div className="form-group">
                        <label htmlFor="siteId">Site ID</label>
                        <input
                            type="text"
                            className="form-control"
                            id="siteId"
                            name="siteId"
                            value={solarEdgeSiteId}
                            onChange={(event) => setSolarEdgeSiteId(event.target.value)}
                            disabled={loading}
                        />
                    </div>
                    { solarEdgeSiteDetails === undefined ? (
                        <Loading/>
                    ) : solarEdgeSiteDetails ? (
                        <React.Fragment>
                            <p>{ solarEdgeSiteDetails.details.location.address }</p>
                            <p>{ solarEdgeSiteDetails.details.location.zip } { solarEdgeSiteDetails.details.location.city }</p>
                            <div className="d-flex flex-row">
                                <Button
                                    variant="primary"
                                    className="mr-2"
                                    onClick={applySolarEdgeSiteDetails}
                                    disabled={loading}
                                >
                                    Adres overnemen
                                </Button>
                                <Button
                                    variant="secondary"
                                    onClick={applyUnknownAddress}
                                    disabled={loading}
                                >
                                    Onbekend adres
                                </Button>
                            </div>
                        </React.Fragment>
                    ) : solarEdgeSiteId.length > 0 && (
                        <Button
                            variant="secondary"
                            onClick={applyUnknownAddress}
                            disabled={loading}
                        >
                            Onbekend adres
                        </Button>
                    )}
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-body">
                    <h5 className="card-title">Adres</h5>
                    <div className="row">
                        <div className="col-sm-8">
                            <div className="form-group">
                                <label htmlFor="street">Straat</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="street"
                                    name="street"
                                    value={street}
                                    onChange={(event) => setStreet(event.target.value)}
                                    disabled={loading}
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="houseNumber">Huisnummer</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="houseNumber"
                                    name="houseNumber"
                                    value={houseNumber}
                                    onChange={(event) => setHouseNumber(event.target.value)}
                                    disabled={loading}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group">
                                <label htmlFor="postalCode">Postcode</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="postalCode"
                                    name="postalCode"
                                    value={postalCode}
                                    onChange={(event) => setPostalCode(event.target.value)}
                                    disabled={loading}
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="city">Plaats</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="city"
                                    name="city"
                                    value={city}
                                    onChange={(event) => setCity(event.target.value)}
                                    disabled={loading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-body">
                    <h5 className="card-title">Beschrijving</h5>
                    <div className="form-group">
                        <label htmlFor="message">Beschrijving</label>
                        <textarea
                            className="form-control"
                            id="message"
                            name="message"
                            value={message}
                            onChange={(event) => setMessage(event.target.value)}
                            disabled={loading}
                            style={{ height: "150px" }}
                        />
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-end">
                <Button
                    variant="primary"
                    disabled={loading}
                    onClick={onSubmit}
                >
                    { loading && (
                        <Spinner animation="border" size="sm" className="mr-2"/>
                    )}
                    Opslaan
                </Button>
            </div>
        </React.Fragment>
    );
}

export default React.memo(LeadAddSolarEdge);

import React, {
    useEffect,
    useState
} from "react";
import axios from "axios";

import SearchFilter from "../../../components/overview/search/SearchFilter";

function LeadStatusSearchFilter({ searchQuery, setSearchQuery }) {
    const [items, setItems] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get("/getLeadStatuses")
            .then((response) => {
                if(response.data.valid) {
                    setItems(response.data.data.sort((status1, status2) => {
                        if(status1.priority > status2.priority) return -1;
                        if(status1.priority < status2.priority) return 1;
                        return 0;
                    }).map((status) => {
                        return {
                            name: status.name,
                            value: status.name.toLowerCase().replace(/\s/g, ""),
                            tagPill: true,
                            color: status.color
                        }
                    }));
                } else {
                    setError("Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((error) => {
                console.error(error);
                setError("Er ging iets fout. Probeer het later opnieuw.");
            });
    }, []);

    return (
        <SearchFilter
            title="Status"
            filterKey="status"
            searchQuery={ searchQuery }
            setSearchQuery={ setSearchQuery }
            values={ items }
            error={ error }
        />
    )
}

export default React.memo(LeadStatusSearchFilter);

import React from "react";

import ChartCard from "../components/ChartCard";
import LeadStatusSelectionChartCard from "../components/LeadStatusSelectionChartCard";

import LeadsCreatedCountChart from "../charts/LeadsCreatedCountChart";
import LeadsPickedUpCountChart from "../charts/LeadsPickedUpCountChart";
import LeadsNewCountChart from "../charts/LeadsNewCountChart";
import LeadStatusChangesChart from "../charts/LeadStatusChangesChart";
import LeadStatusChangesPerStatusChart from "../charts/LeadStatusChangesPerStatusChart";
import LeadsCreatedCountWeeklyChart from "../charts/LeadsCreatedCountWeeklyChart";
import LeadStatusChangesPerUserChart from "../charts/LeadStatusChangesPerUserChart";
import LeadsCreatedCountMonthlyChart from "../charts/LeadsCreatedCountMonthlyChart";
import LeadsConversionRateMonthlyChart from "../charts/LeadsConversionRateMonthlyChart";

function LeadStatistics({ startDate, endDate }) {
    return (
        <React.Fragment>
            <ChartCard title="Aantal leads ontvangen per dag per bron">
                <LeadsCreatedCountChart
                    startDate={ startDate }
                    endDate={ endDate }
                    groupBy="LeadSource"
                />
            </ChartCard>
            <ChartCard title="Aantal leads ontvangen per week per bron">
                <LeadsCreatedCountWeeklyChart
                    startDate={ startDate }
                    endDate={ endDate }
                    groupBy="LeadSource"
                />
            </ChartCard>
            <ChartCard title="Aantal leads ontvangen per maand per bron">
                <LeadsCreatedCountMonthlyChart
                    startDate={ startDate }
                    endDate={ endDate }
                    groupBy="LeadSource"
                />
            </ChartCard>
            <ChartCard title="Lead conversiepercentage per maand per bron">
                <LeadsConversionRateMonthlyChart
                    startDate={ startDate }
                    endDate={ endDate }
                    groupBy="LeadSource"
                />
            </ChartCard>
            <ChartCard title="Aantal leads ontvangen per dag per type">
                <LeadsCreatedCountChart
                    startDate={ startDate }
                    endDate={ endDate }
                    groupBy="LeadType"
                />
            </ChartCard>
            <ChartCard title="Aantal leads ontvangen per week per type">
                <LeadsCreatedCountWeeklyChart
                    startDate={ startDate }
                    endDate={ endDate }
                    groupBy="LeadType"
                />
            </ChartCard>
            <ChartCard title="Aantal leads ontvangen per maand per type">
                <LeadsCreatedCountMonthlyChart
                    startDate={ startDate }
                    endDate={ endDate }
                    groupBy="LeadType"
                />
            </ChartCard>
            <ChartCard title="Aantal nieuwe leads opgepakt per dag">
                <LeadsPickedUpCountChart
                    startDate={ startDate }
                    endDate={ endDate }
                />
            </ChartCard>
            <ChartCard title="Aantal nieuwe leads door de tijd heen">
                <LeadsNewCountChart
                    startDate={ startDate }
                />
            </ChartCard>
            <ChartCard title="Aantal nieuwe leads door de tijd heen exclusief te laat opgepakte leads">
                <LeadsNewCountChart
                    startDate={ startDate }
                    excludeExpired
                />
            </ChartCard>
            <ChartCard title="Aantal keer status van leads veranderd per dag">
                <LeadStatusChangesChart
                    startDate={ startDate }
                    endDate={ endDate }
                />
            </ChartCard>
            <ChartCard title="Aantal keer status van leads veranderd per dag per status" userSelectable>
                <LeadStatusChangesPerStatusChart
                    startDate={ startDate }
                    endDate={ endDate }
                />
            </ChartCard>
            <LeadStatusSelectionChartCard title="Aantal keer status van leads veranderd per dag per gebruiker">
                <LeadStatusChangesPerUserChart
                    startDate={ startDate }
                    endDate={ endDate }
                />
            </LeadStatusSelectionChartCard>
        </React.Fragment>
    )
}

export default React.memo(LeadStatistics);

import React from "react";
import moment from "moment";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

function QuarterFormatter({ date }) {
    const tooltip = (
        <Tooltip id={ "date-" + date }>
            { moment(date).format("Q[e kwartaal van] YYYY") }
        </Tooltip>
    )
    return (
        <OverlayTrigger placement="top" overlay={ tooltip }>
                <span>
                    { moment(date).format("[Q]Q YYYY") }
                </span>
        </OverlayTrigger>
    )
}

export default React.memo(QuarterFormatter);

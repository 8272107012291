import React from "react";
import axios from "axios";
import {
    Link,
    Route,
    Switch
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    Title
} from "@zandor300/backoffice-framework";

import Loading from "../../components/Loading";
import ChartCard from "../statistics/components/ChartCard";
import ProductAvailabilityChart from "./product-detail/components/ProductAvailabilityChart";
import TagPill from "../../components/tagPill";
import Helmet from "../../components/Helmet";
import NumberFormatter from "../../components/formatters/NumberFormatter";

function ProductsAmountAvailableSwitch() {
    return (
        <Switch>
            <Route path="/products-available/:productIds" component={ProductsAmountAvailableChart}/>
            <Route path="/products-available" component={ProductsAmountAvailableChart}/>
        </Switch>
    )
}

function ProductCard({ product }) {
    return (
        <div className="card mb-2">
            <div className="card-body">
                <div className="d-flex w-100 align-items-center">
                    <div className="mr-2 text-muted">
                        <b>{ product.id }</b>
                    </div>
                    <div className="flex-grow-1">
                        <h5 className="m-0">
                            { product.brand.name } { product.name }
                            <small className="ml-2">
                                <TagPill color={ product.type.color }>
                                    { product.type.name }
                                </TagPill>
                            </small>
                        </h5>
                    </div>
                    <div className="mr-3 text-muted">
                        Voorradig: <NumberFormatter number={ product.amount }/>
                    </div>
                    <div>
                        <Link
                            to={ `/products/${product.brand.id}/product/${product.id}/reserved` }
                            className="btn btn-sm btn-primary"
                        >
                            <i className="fas fa-info fa-fw"/>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

class ProductsAmountAvailableChart extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            allProducts: null,
            selectedProductIds: [],
            selectedProducts: [],

            amountAvailable: null,
            amountNotPlannedIn: null,
            error: null
        }
    }

    componentDidMount() {
        this.getProducts();
        this.updateSelectedProducts(this.props.match.params.productIds);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.match.params.productIds !== prevProps.match.params.productIds) {
            this.updateSelectedProducts(this.props.match.params.productIds);
        }
        if(this.state.allProducts !== prevState.allProducts || this.state.selectedProductIds !== prevState.selectedProductIds) {
            this.setState({ selectedProducts: this.getSelectedProducts() });
        }
    }

    updateSelectedProducts(productIdsString) {
        if(!productIdsString) {
            this.setState({ selectedProductIds: [] });
            return;
        }
        let productIds = [];
        for(const productIdString of productIdsString.split(",")) {
            const productId = parseInt(productIdString, 10);
            if(productId) {
                productIds.push(productId);
            }
        }
        this.setState({
            selectedProductIds: productIds
        }, () => {
            this.getAmountAvailable();
        });
    }

    getSelectedProducts(selectedProductIdsInput, allProductsInput) {
        const {
            allProducts,
            selectedProductIds
        } = this.state;
        if(!allProducts || allProducts.length === 0 || !selectedProductIds || selectedProductIds.length === 0) {
            return [];
        }
        const productIds = selectedProductIdsInput ? selectedProductIdsInput : selectedProductIds;
        const products = allProductsInput ? allProductsInput : allProducts;
        return products.filter((product) => {
            return productIds.includes(product.id);
        });
    }

    getProducts() {
        axios.get("/getProducts")
            .then((response) => {
                if(response.data.valid) {
                    this.setState({
                        allProducts: response.data.data
                    });
                } else {
                    this.setState({ error: "Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er is een fout opgetreden. Probeer het later opnieuw." });
            });
    }

    getAmountAvailable() {
        const {
            selectedProductIds
        } = this.state;
        if(!selectedProductIds || selectedProductIds.length === 0) {
            return;
        }
        axios.post("/getAmountAvailableThroughTimeForProducts", { productIds: selectedProductIds.join(",") })
            .then((response) => {
                if(response.data.valid) {
                    const {
                        amountAvailable,
                        amountNotPlannedIn
                    } = response.data;
                    this.setState({
                        amountAvailable,
                        amountNotPlannedIn
                    });
                } else {
                    this.setState({ error: "Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er is een fout opgetreden. Probeer het later opnieuw." });
            });
    }

    render() {
        const {
            allProducts,
            selectedProductIds,
            selectedProducts,
            amountAvailable,
            error
        } = this.state;
        return (
            <React.Fragment>
                <Helmet title="Aantal beschikbaar in toekomst"/>
                <Title preTitle="Detail">
                    Aantal beschikbaar in toekomst
                </Title>

                { error ? (
                    <Alert variant="danger">{ error }</Alert>
                ) : !allProducts ? (
                    <Loading/>
                ) : (
                    <React.Fragment>
                        { !selectedProductIds || selectedProductIds.length === 0 ? (
                            <React.Fragment>
                                <h1>Selecteer producten</h1>
                            </React.Fragment>
                        ) : (
                            <div className="mb-3">
                                { selectedProducts.map((product) => (
                                    <ProductCard
                                        key={ product.id }
                                        product={ product }
                                    />
                                ))}
                            </div>
                        )}
                        { selectedProductIds && selectedProductIds.length > 0 && (
                            <ChartCard title="Aantal beschikbaar in de toekomst">
                                <ProductAvailabilityChart amountAvailable={ amountAvailable }/>
                            </ChartCard>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

export default ProductsAmountAvailableSwitch;

import React from "react";

import TagPill from "../../../components/tagPill.js";

function AbsenceUserItem({ absenceItem, startDay, endDay, onClick, moreOnPrevWeek, moreOnNextWeek }) {
    const absenceSpanDays = Math.max(Math.min(endDay - startDay, 5), 1);
    let cardClassNames = ["card flex-grow-1"];
    if(moreOnPrevWeek) {
        cardClassNames.push("left-border-0");
    }
    if(moreOnNextWeek) {
        cardClassNames.push("right-border-0");
    }
    if(onClick) {
        cardClassNames.push("pointer-cursor");
    }
    return (
        <td colSpan={ absenceSpanDays } onClick={ onClick }>
            <div className={ "d-flex" + (onClick ? "" : " absence-external") }>
                { moreOnPrevWeek && (
                    <div className="card-arrow-left" />
                )}
                <div className={ cardClassNames.join(" ") }>
                    <div className="card-body p-1">
                        <TagPill color={ absenceItem.type.color }>
                            { absenceItem.type.name }
                        </TagPill>

                        <br/>
                        <b>{ absenceItem.user.name}</b>
                        <br/>
                        { absenceItem.description }
                    </div>
                </div>
                { moreOnNextWeek && (
                    <div className="card-arrow-right" />
                )}
            </div>
        </td>
    )
}

export default React.memo(AbsenceUserItem);

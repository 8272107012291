import React from "react";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

function StatusDot({ name, color, ...restProps }) {
    if(!name || !color) {
        return null;
    }
    return (
        <OverlayTrigger placement="top" overlay={
            <Tooltip id="planningItemParentStatus">
                { name }
            </Tooltip>
        }>
            <div
                style={{
                    width: "12px",
                    height: "12px",
                    borderRadius: "50%",
                    backgroundColor: color
                }}
                { ...restProps }
            />
        </OverlayTrigger>
    )
}

export default React.memo(StatusDot);

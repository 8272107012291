import React, {
    useMemo
} from "react";
import {
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

function CoordinatesMapButtons({ object, onOpenAppleMaps }) {
    const coordinatesString = useMemo(() => {
        if(!object.latitude || !object.longitude) {
            return null;
        }
        return `${object.latitude},${object.longitude}`;
    }, [object]);
    if(!coordinatesString) {
        return null;
    }
    return (
        <React.Fragment>
            <OverlayTrigger placement="top" overlay={
                <Tooltip id="address">Coördinaten openen in Google Maps</Tooltip>
            }>
                <a href={ "https://google.com/maps?q=" + coordinatesString + "&t=k" }
                   className="btn btn-link btn-sm py-0" target="_blank" rel="noreferrer noopener">
                    <i className="fab fa-google fa-fw"/>
                </a>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={
                <Tooltip id="address">Coördinaten openen in Google Earth</Tooltip>
            }>
                <a href={ "https://earth.google.com/web/search/" + coordinatesString }
                   className="btn btn-link btn-sm py-0" target="_blank" rel="noreferrer noopener">
                    <i className="fas fa-globe-europe fa-fw"/>
                </a>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={
                <Tooltip id="address">Coördinaten openen in Apple Maps</Tooltip>
            }>
                <Button onClick={ onOpenAppleMaps } variant="link" size="sm" className="py-0">
                    <i className="fab fa-apple fa-fw"/>
                </Button>
            </OverlayTrigger>
        </React.Fragment>
    )
}

export default React.memo(CoordinatesMapButtons);

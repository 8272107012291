import React from "react";
import Skeleton from "react-loading-skeleton";
import {
    Alert,
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import TagPill from "../../../components/tagPill";
import DateFormatter from "../../../components/formatters/DateFormatter";
import DetailOverviewRow from "../../../components/detail/DetailOverviewRow";

import DetailOverviewQRCode from "../../../components/detail/DetailOverviewQRCode";
import RestockOrderDetailProducts from "./RestockOrderDetailProducts";
import ReactMarkdownBreaks from "../../../components/ReactMarkdownBreaks";
import ClickCopy from "../../../components/copy/ClickCopy";
import RestockOrderExpectedDeliveryDateHistoryModal from "./modal/RestockOrderExpectedDeliveryDateHistoryModal";
import RestockOrderRemoveModal from "./components/RestockOrderRemoveModal";

class RestockOrderDetailOverview extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showHistoryModal: false,
            showRemoveModal: false
        };
        this.onShowHistoryModal = this.onShowHistoryModal.bind(this);
        this.onShowRemoveModal = this.onShowRemoveModal.bind(this);
        this.handleHistoryModalClose = this.handleHistoryModalClose.bind(this);
        this.handleRemoveModalClose = this.handleRemoveModalClose.bind(this);
    }

    onShowHistoryModal() {
        this.setState({ showHistoryModal: true });
    }

    onShowRemoveModal() {
        this.setState({ showRemoveModal: true });
    }

    handleHistoryModalClose() {
        this.setState({ showHistoryModal: false });
    }

    handleRemoveModalClose() {
        this.setState({ showRemoveModal: false });
    }

    setRestockOrderProps(props) {
        this.props.setRestockOrder((prevRestockOrder) => {
            return { ...prevRestockOrder, ...props };
        })
    }

    render() {
        const {
            restockOrder
        } = this.props;
        const {
            showHistoryModal,
            showRemoveModal
        } = this.state;

        return (
            <React.Fragment>
                <RestockOrderExpectedDeliveryDateHistoryModal
                    restockOrder={ restockOrder }
                    show={ showHistoryModal }
                    handleClose={ this.handleHistoryModalClose }
                />
                <RestockOrderRemoveModal
                    restockOrder={ restockOrder }
                    show={ showRemoveModal }
                    handleClose={ this.handleRemoveModalClose }
                />

                { restockOrder && restockOrder.warnings && restockOrder.warnings.map((warning, index) => (
                    <Alert variant="warning" key={ index } className="d-print-none">{ warning }</Alert>
                ))}

                <DetailOverviewQRCode
                    content={ restockOrder ? `restockOrder=${restockOrder.id}` : null }
                    caption="Scan deze code in de Voorraad app om deze bestelling direct te openen op je telefoon."
                />

                <div className="card">
                    <div className="card-body">
                        <table className="table table-borderless mb-0">
                            <tbody>
                            <DetailOverviewRow title="Afgeleverd">
                                { restockOrder ? (
                                    <React.Fragment>
                                        { restockOrder.delivered ? (
                                            <TagPill variant="success">
                                                Geleverd
                                            </TagPill>
                                        ) : (
                                            <TagPill variant="danger">
                                                In afwachting
                                            </TagPill>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <Skeleton width={200}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Leverancier">
                                { restockOrder ? (
                                    <TagPill color={restockOrder.supplier.color}>
                                        {restockOrder.supplier.name}
                                    </TagPill>
                                ) : (
                                    <Skeleton width={200}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Ordernummer leverancier">
                                { restockOrder ? (
                                    restockOrder.supplierOrderNumber.trim().length === 0 ? (
                                        <i className="text-muted">Niet ingevuld</i>
                                    ) : (
                                        <ClickCopy text={ restockOrder.supplierOrderNumber }>
                                            { restockOrder.supplierOrderNumber }
                                        </ClickCopy>
                                    )
                                ) : (
                                    <Skeleton width={200}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Besteldatum">
                                { restockOrder ? (
                                    <DateFormatter date={restockOrder.date}/>
                                ) : (
                                    <Skeleton width={300}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title={ (restockOrder && restockOrder.delivered) ? "Leverdatum" : "Verwachte leverdatum" }>
                                { restockOrder ? (
                                    <React.Fragment>
                                        <DateFormatter date={restockOrder.delivered ? restockOrder.deliveryDate : restockOrder.expectedDeliveryDate}/>
                                        <OverlayTrigger placement="top" overlay={
                                            <Tooltip id="history">Geschiedenis</Tooltip>
                                        }>
                                            <Button variant="link" size="sm" className="py-0" onClick={ this.onShowHistoryModal }>
                                                <i className="fas fa-history"/>
                                            </Button>
                                        </OverlayTrigger>
                                    </React.Fragment>
                                ) : (
                                    <Skeleton width={300}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Aangemaakt door">
                                { restockOrder ? (
                                    restockOrder.author ? restockOrder.author.name : (
                                        <i className="text-muted">Automatisch</i>)
                                ) : (
                                    <Skeleton width={150}/>
                                )}
                            </DetailOverviewRow>
                            <DetailOverviewRow title="Beschrijving">
                                { restockOrder ? (
                                    restockOrder.description && restockOrder.description.length > 0 ? (
                                        <ReactMarkdownBreaks message={ restockOrder.description }/>
                                    ) : (
                                        <i className="text-muted">Leeg</i>
                                    )
                                ) : (
                                    <Skeleton count={3}/>
                                )}
                            </DetailOverviewRow>
                            { restockOrder && !restockOrder.delivered && restockOrder.expectedProductsAmount === 0 && (
                                <DetailOverviewRow>
                                    <Button variant="danger" size="sm" onClick={ this.onShowRemoveModal }>
                                        Verwijderen
                                    </Button>
                                </DetailOverviewRow>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="mt-3">
                    <RestockOrderDetailProducts
                        restockOrderId={ this.props.restockOrderId }
                        onChange={ this.props.getRestockOrder }
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default RestockOrderDetailOverview;

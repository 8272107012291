import React, {
    useMemo
} from "react";
import {
    Chart
} from "react-charts";

import ChartLoading from "../../../statistics/components/ChartLoading";
import ChartNoData from "../../../statistics/components/ChartNoData";

function ProductTakenAmountChart({ data }) {
    const primaryAxis = useMemo(
        () => ({
            getValue: (datum) => datum.key
        }), []
    );
    const secondaryAxes = useMemo(
        () => [
            {
                getValue: (datum) => datum.value,
                stacked: true
            },
        ], []
    );

    if(!data) {
        return (
            <ChartLoading/>
        )
    }
    if(data.length === 0) {
        return (
            <ChartNoData/>
        )
    }
    return (
        <Chart
            options={{
                data,
                primaryAxis,
                secondaryAxes,
            }}
        />
    )
}

export default React.memo(ProductTakenAmountChart);

import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Route,
    Switch,
    useRouteMatch,
    withRouter
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    TabBar,
    TabBarItem,
    TabBarLabel,
    Title
} from "@zandor300/backoffice-framework";
import Skeleton from "react-loading-skeleton";

import Helmet from "../../../components/Helmet";
import Page404 from "../../Page404";

import ProductDetailOverview from "./ProductDetailOverview";
import ProductDetailHistory from "./ProductDetailHistory";
import ProductDetailReserved from "./ProductDetailReserved";
import ProductDetailRestockOrders from "./ProductDetailRestockOrders";
import ProductDetailRestockOrderSuppliers from "./ProductDetailRestockOrderSuppliers";
import ProductDetailStatistics from "./ProductDetailStatistics";
import ProductDetailInstallationsReserved from "./ProductDetailInstallationsReserved";
import ProductDetailInstallationsTaken from "./ProductDetailInstallationsTaken";
import ProductDetailImages from "./ProductDetailImages";

function ProductDetail(props) {
    const productId = props.match.params.productId;
    const brandId = props.match.params.brandId;
    const urlPrefix = `/products/${brandId}/product/${productId}`;

    const [product, setProduct] = useState(null);
    const [error, setError] = useState(null);
    const [errorCode, setErrorCode] = useState(null);

    const noTabBarBottom = useRouteMatch([
        `${props.match.url}/restock-orders`,
        `${props.match.url}/restock-orders-suppliers`,
        `${props.match.url}/installations-reserved`,
        `${props.match.url}/installations-taken`
    ]) !== null;

    const refreshProduct = useCallback(() => {
        setProduct(null);
        setError(null);
        setErrorCode(null);
        axios.post("/getProduct", { productId })
            .then((response) => {
                if(response.data.valid) {
                    setProduct(response.data.data);
                } else {
                    setProduct(null);
                    setError("Er was een fout bij het ophalen van de data. (" + response.data.error + ")");
                    setErrorCode(response.data.error);
                }
            })
            .catch((error) => {
                console.error(error);
                setProduct(null);
                setError("Er was een fout bij het ophalen van de data.");
            });
    }, [productId]);

    useEffect(() => {
        if(isNaN(parseInt(productId))) {
            return;
        }
        refreshProduct();
    }, [productId, refreshProduct]);

    const childProps = {
        productId,
        product,
        error,
        setProduct,
        refreshProduct,
        urlPrefix
    };

    if(isNaN(parseInt(productId)) || productId < 1 || errorCode === "DOESNT_EXIST") {
        return <Page404/>
    }
    if(product && product.brand.id !== parseInt(brandId)) {
        return <Page404/>
    }

    const name = !product ? null : `${product.brand.name} ${product.name}`;

    return (
        <React.Fragment>
            <Helmet title={ "Product #" + productId + (name ? ` - ${name}` : "") }/>

            <Title preTitle="Detail" noBottom>
                { product ? (
                    <React.Fragment>
                        { product.brand.name + " " + product.name } <small className="text-muted">#{productId}</small>
                    </React.Fragment>
                ) : (
                    <Skeleton width={ 300 }/>
                )}
            </Title>

            { error? (
                <Alert variant="danger">{ error }</Alert>
            ) : (
                <React.Fragment>
                    <TabBar noBottom={ noTabBarBottom }>
                        <TabBarItem to={ urlPrefix }>
                            Overview
                        </TabBarItem>
                        <TabBarItem to={ `${urlPrefix}/images` }>
                            Foto's
                        </TabBarItem>
                        <TabBarItem to={ `${urlPrefix}/history` }>
                            Geschiedenis
                        </TabBarItem>
                        <TabBarItem to={ `${urlPrefix}/reserved` }>
                            Gereserveerd
                        </TabBarItem>
                        <TabBarItem to={ `${urlPrefix}/restock-orders` }>
                            Bestellingen
                        </TabBarItem>
                        <TabBarItem to={ `${urlPrefix}/restock-orders-suppliers` }>
                            Leveranciers
                        </TabBarItem>
                        <TabBarItem to={ `${urlPrefix}/statistics` }>
                            Statistieken
                        </TabBarItem>

                        <TabBarLabel className="ml-4">Installaties</TabBarLabel>
                        <TabBarItem to={ `${urlPrefix}/installations-reserved` }>
                            Gereserveerd
                        </TabBarItem>
                        <TabBarItem to={ `${urlPrefix}/installations-taken` }>
                            Geïnstalleerd
                        </TabBarItem>
                    </TabBar>

                    <Switch>
                        <Route path={ urlPrefix } exact>
                            <ProductDetailOverview { ...childProps }/>
                        </Route>
                        <Route path={ `${urlPrefix}/images` } exact>
                            <ProductDetailImages { ...childProps }/>
                        </Route>
                        <Route path={ `${urlPrefix}/history` } exact>
                            <ProductDetailHistory { ...childProps }/>
                        </Route>
                        <Route path={ `${urlPrefix}/reserved` } exact>
                            <ProductDetailReserved { ...childProps }/>
                        </Route>
                        <Route path={ `${urlPrefix}/restock-orders` } exact>
                            <ProductDetailRestockOrders { ...childProps }/>
                        </Route>
                        <Route path={ `${urlPrefix}/restock-orders-suppliers` } exact>
                            <ProductDetailRestockOrderSuppliers { ...childProps }/>
                        </Route>
                        <Route path={ `${urlPrefix}/statistics` } exact>
                            <ProductDetailStatistics { ...childProps }/>
                        </Route>
                        <Route path={ `${urlPrefix}/installations-reserved` } exact>
                            <ProductDetailInstallationsReserved { ...childProps }/>
                        </Route>
                        <Route path={ `${urlPrefix}/installations-taken` } exact>
                            <ProductDetailInstallationsTaken { ...childProps }/>
                        </Route>
                    </Switch>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default withRouter(ProductDetail);

import React, {
    createContext
} from "react";
import axios from "axios";
import {
    parsePhoneNumber
} from "libphonenumber-js";

import {
    withAuthenticatedUserContext
} from "./AuthenticatedUserContext";

const CallMonitorContext = createContext(null);

function getPhoneNumberFromCall(call) {
    const member = call.members.find((searchMember) => {
        return searchMember.outbound || searchMember.inbound;
    });
    if(!member) {
        return null;
    }
    if(member.outbound) {
        return member.outbound.to;
    }
    if(member.inbound) {
        return member.inbound.from;
    }
    return null;
}

function getTypeOfCall(call) {
    if(!call) {
        return null;
    }
    const member = call.members.find((searchMember) => {
        return searchMember.outbound || searchMember.inbound;
    });
    if(member.inbound) {
        return "inbound";
    }
    if(member.outbound) {
        return "outbound";
    }
    return null;
}

class CallMonitorManagerInternal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            calls: null,
            error: null,

            calling: false,
            phoneNumber: null,
            parsedPhoneNumber: null,
            displayedPhoneNumber: null,
            type: null,
            searchLink: null,
            showCallInfo: false
        }
        this.getCurrentCallInfo = this.getCurrentCallInfo.bind(this);
        this.onVisibilityChange = this.onVisibilityChange.bind(this);
    }

    componentDidMount() {
        this.inFocus = true;
        this.getCurrentCallInfo();
        this.timer = setInterval(this.getCurrentCallInfo, 5000);
        window.addEventListener("visibilitychange", this.onVisibilityChange);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        window.removeEventListener("visibilitychange", this.onVisibilityChange);
    }

    onVisibilityChange() {
        this.inFocus = document.visibilityState === "visible";
        this.getCurrentCallInfo();
    }

    getCurrentCallInfo() {
        if(!this.inFocus) {
            return;
        }
        if(!this.props.authenticatedUserContext.user || !this.props.authenticatedUserContext.user.pbxExtension) {
            return;
        }
        axios.get("/getCurrentCallInfo", { noAnimatedLogo: true })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ error: null, calls: response.data.calls });
                    this.updateCallInfo(response.data.calls);
                } else {
                    this.setState({ error: "Er is een fout opgetreden. (" + response.data.error + ")", calls: null });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er is een fout opgetreden.", calls: null });
            });
    }

    getCurrentCall(calls) {
        if(!calls) {
            return null;
        }
        return calls.find((searchCall) => {
            return searchCall.members.find((searchMember) => {
                return searchMember.outbound || searchMember.inbound;
            });
        });
    }

    updateCallInfo(calls) {
        const call = this.getCurrentCall(calls);
        if(!call) {
            this.setNotCalling();
            return;
        }
        const phoneNumber = getPhoneNumberFromCall(call);
        if(!phoneNumber) {
            this.setNotCalling();
            return;
        }
        const parsedPhoneNumber = parsePhoneNumber(phoneNumber, "NL");
        this.setState({
            calling: true,
            phoneNumber,
            parsedPhoneNumber,
            displayedPhoneNumber: parsedPhoneNumber ? parsedPhoneNumber.formatInternational() : phoneNumber,
            type: getTypeOfCall(call),
            searchLink: "/leads?search=phoneNumber=" + (parsedPhoneNumber ? parsedPhoneNumber.nationalNumber : phoneNumber),
            showCallInfo: true
        });
    }

    setNotCalling() {
        this.setState((prevState) => {
            if(prevState.calling) {
                setTimeout(() => {
                    this.setState((prevState) => {
                        const call = this.getCurrentCall(prevState.calls);
                        if(call) {
                            return {};
                        }
                        return { showCallInfo: false };
                    });
                }, 30 * 1000);
            }
            return { calling: false };
        });
    }

    render() {
        return (
            <CallMonitorContext.Provider value={ this.state }>
                { this.props.children }
            </CallMonitorContext.Provider>
        )
    }
}

export const CallMonitorManager = withAuthenticatedUserContext(CallMonitorManagerInternal);

export default CallMonitorContext;

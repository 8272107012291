import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    FormControl,
    InputGroup,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import TagPill from "../../../../components/tagPill";
import numberFormatter from "../../../../components/formatters/NumberFormatter";
import ResizableImage from "../../../../components/ResizableImage";

class InstallationProductCard extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            saving: false,
            amount: ""
        }
        this.onSave = this.saveAmount.bind(this);
        this.onEditStart = this.onEditStart.bind(this);
        this.onEditCancel = this.onEditCancel.bind(this);
        this.onAmountChange = this.onAmountChange.bind(this);
    }

    saveAmount() {
        this.setState({ saving: true });
        if(!parseInt(this.state.amount) || this.state.amount < 1) {
            this.setState({ errorSave: "Ongeldig aantal. Vul een nummer zonder decimalen in." });
            return;
        }
        this.setState({ saving: true, errorSave: null });
        axios.post("/setInstallationProductAmount", {
            installationId: this.props.installationId,
            productId: this.props.product.id,
            amount: parseInt(this.state.amount)
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.onSave(response.data.reserved, response.data.taken);
                    this.setState({ editing: false });
                } else {
                    this.setState({ errorSave: "Er ging iets fout bij het opslaan van het aantal. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ errorSave: "Er ging iets fout bij het opslaan van het aantal." });
                console.error(error);
            })
            .finally(() => {
                this.setState({ saving: false });
            })
    }

    onEditStart() {
        this.setState({ editing: true, amount: this.props.product.installationAmount.toString() })
    }

    onEditCancel() {
        this.setState({ editing: false });
    }

    onAmountChange(event) {
        this.setState({ amount: event.target.value });
    }

    render() {
        const product = this.props.product;
        return (
            <div className="product-card-container">
                <div className="product-thumbnail-card d-none d-md-flex">
                    { product.thumbnailImage ? (
                        <ResizableImage
                            image={ product.thumbnailImage }
                            size="original"
                        />
                    ) : (
                        <i className="fas fa-image"/>
                    )}
                </div>
                <div className="card flex-grow-1">
                    <div className="card-body d-flex align-items-center">
                        { this.state.errorSave && (
                            <Alert variant="danger">{ this.state.errorSave }</Alert>
                        )}
                        <div className="d-flex align-items-center w-100">
                            { !this.props.readOnly && (
                                <div className="d-print-none">
                                    { !this.state.editing ? (
                                        <OverlayTrigger overlay={
                                            <Tooltip id="edit">Aantal wijzigen</Tooltip>
                                        }>
                                            <Button
                                                variant="light"
                                                onClick={ this.onEditStart }
                                                className="py-1 px-2 mr-2"
                                            >
                                                <b>{ product.installationAmount }x</b>
                                            </Button>
                                        </OverlayTrigger>
                                    ) : (
                                        <InputGroup controlId="amount" style={{ width: "200px" }} className="mb-0 mr-2">
                                            <FormControl
                                                type="number"
                                                value={ this.state.amount }
                                                onChange={ this.onAmountChange }
                                                placeholder="Aantal"
                                                disabled={ this.state.saving }
                                            />
                                            <InputGroup.Append>
                                                <Button variant="primary" onClick={ this.onSave } disabled={ this.state.saving }>
                                                    <i className="fas fa-save"/>
                                                </Button>
                                                <Button variant="secondary" onClick={ this.onEditCancel } disabled={ this.state.saving }>
                                                    <i className="fas fa-trash-alt"/>
                                                </Button>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    )}
                                </div>
                            )}

                            <div className="flex-grow-1">
                                <h5 className="card-title mb-0">
                                    <span className={ this.props.readOnly ? "" : "d-none d-print-inline-block mr-2" }>
                                        { numberFormatter({ number: product.installationAmount }) }x{" "}
                                    </span>
                                    <span className="mt-2">
                                        { product.brand.name } { product.name }
                                    </span>
                                    <small className="ml-2">
                                        <TagPill color={ product.type.color } className="mr-2">
                                            { product.type.name }
                                        </TagPill>
                                    </small>
                                </h5>
                            </div>

                            { product.sku && (
                                <div className="text-muted nowrap mr-3">{ product.sku }</div>
                            )}

                            <div className="text-muted nowrap">Voorradig: { numberFormatter({ number: product.amount }) }</div>

                            { this.props.openTakeProductModal && (
                                <div className="ml-3 mr-2 d-print-none">
                                    <Button variant="primary" size="sm" onClick={ this.props.openTakeProductModal }>
                                        Afnemen
                                    </Button>
                                </div>
                            )}

                            { this.props.onReplace && (
                                <div>
                                    <OverlayTrigger overlay={
                                        <Tooltip id="edit">Materiaal vervangen</Tooltip>
                                    }>
                                        <Button variant="link" className="text-secondary" onClick={ this.props.onReplace }>
                                            <i className="fas fa-exchange"/>
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                            )}
                            { this.props.onRemove && (
                                <div>
                                    <OverlayTrigger overlay={
                                        <Tooltip id="edit">Materiaal verwijderen</Tooltip>
                                    }>
                                        <Button variant="link" className="text-danger" onClick={ this.props.onRemove }>
                                            <i className="fas fa-trash"/>
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default InstallationProductCard;

import React, {
    useContext
} from "react";
import {
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import AuthenticatedUserContext from "../../../context/AuthenticatedUserContext";

function ExportProductsButton({ onClick }) {
    const authenticatedUser = useContext(AuthenticatedUserContext);
    if(!authenticatedUser.user || !authenticatedUser.user.admin) {
        return null;
    }
    return (
        <OverlayTrigger overlay={
            <Tooltip id="reset">Producten export</Tooltip>
        }>
            <Button variant="secondary" onClick={ onClick }>
                <i className="fas fa-download fa-fw"/>
            </Button>
        </OverlayTrigger>
    )
}

export default React.memo(ExportProductsButton);

import React, {
    useMemo
} from "react";
import {
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

function AddressMapButtons({ object, onOpenAppleMaps }) {
    const address = useMemo(() => {
        if(!object.street || !object.houseNumber || !object.city) {
            return null;
        }
        return `${object.street} ${object.houseNumber}, ${object.city}`;
    }, [object]);
    const addressUri = useMemo(() => {
        if(!address) {
            return null;
        }
        return encodeURIComponent(address);
    }, [address]);
    if(!addressUri) {
        return null;
    }
    return (
        <React.Fragment>
            <OverlayTrigger placement="top" overlay={
                <Tooltip id="address">Adres openen in Google Maps</Tooltip>
            }>
                <a href={ `https://google.com/maps?q=${ addressUri }&t=k` }
                   className="btn btn-link btn-sm py-0" target="_blank" rel="noreferrer noopener">
                    <i className="fab fa-google fa-fw"/>
                </a>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={
                <Tooltip id="address">Adres openen in Google Earth</Tooltip>
            }>
                <a href={ `https://earth.google.com/web/search/${ addressUri }` }
                   className="btn btn-link btn-sm py-0" target="_blank" rel="noreferrer noopener">
                    <i className="fas fa-globe-europe fa-fw"/>
                </a>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={
                <Tooltip id="address">Adres openen in Apple Maps</Tooltip>
            }>
                <Button onClick={ onOpenAppleMaps } variant="link" size="sm" className="py-0">
                    <i className="fab fa-apple fa-fw"/>
                </Button>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={
                <Tooltip id="address">Adres openen in Kadaster BAG</Tooltip>
            }>
                <a href={ `https://bagviewer.kadaster.nl/lvbag/bag-viewer/index.html#?searchQuery=${ addressUri }` }
                   className="btn btn-link btn-sm py-0" target="_blank" rel="noreferrer noopener">
                    <i className="fas fa-map fa-fw"/>
                </a>
            </OverlayTrigger>
            <OverlayTrigger placement="top" overlay={
                <Tooltip id="address">Adres openen in StreetSmart</Tooltip>
            }>
                <a href={ `https://streetsmart.cyclomedia.com/streetsmart/?q=${ addressUri }` }
                   className="btn btn-link btn-sm py-0" target="_blank" rel="noreferrer noopener">
                    <span className="fa-stack">
                        <i className="fas fa-circle fa-stack-1x"/>
                        <i className="fab fa-s fa-stack-075x fa-inverse"/>
                    </span>
                </a>
            </OverlayTrigger>
        </React.Fragment>
    )
}

export default React.memo(AddressMapButtons);

import React, {
    useMemo
} from "react";
import {
    Button,
    Modal
} from "react-bootstrap";

import Loading from "../../../../components/Loading";
import RestockOrderExpectedDeliveryDateHistoryItemCard from "../components/RestockOrderExpectedDeliveryDateHistoryItemCard";

function RestockOrderExpectedDeliveryDateHistoryModal({ restockOrder, show, handleClose }) {
    const history = useMemo(() => {
        if(!restockOrder) {
            return null;
        }
        return restockOrder.expectedDeliveryDateHistory;
    }, [restockOrder]);

    return (
        <Modal show={ show } onHide={ handleClose } size="lg">
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title">
                    Verwachte leverdatum geschiedenis
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                { !history ? (
                    <Loading/>
                ) : history.length === 0 ? (
                    <div className="text-center">
                        <h1><i className="fas fa-history"/></h1>
                        <h3>Geen geschiedenis</h3>
                        <p className="mb-0">Er zijn nog geen wijzigingen aan de verwachte leverdatum.</p>
                    </div>
                ) : history.map((historyItem) => (
                    <RestockOrderExpectedDeliveryDateHistoryItemCard
                        historyItem={ historyItem }
                        key={ historyItem.id }
                    />
                ))}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose }>
                    Sluiten
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default React.memo(RestockOrderExpectedDeliveryDateHistoryModal);

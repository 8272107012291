import React from "react";
import axios from "axios";

class ViewNotifier extends React.PureComponent {
    constructor(props) {
        super(props);

        this.inactivityTimeout = 15 * 60;
        this.inactivityCountdown = this.inactivityTimeout;
        this.viewToken = null;

        this.registerView = this.registerView.bind(this);
        this.handleCountdown = this.handleCountdown.bind(this);
        this.onActivityDetected = this.onActivityDetected.bind(this);
        this.onVisibilityChange = this.onVisibilityChange.bind(this);
    }

    componentDidMount() {
        this.inFocus = true;
        this.registerViewTimer = setInterval(this.registerView, 10000);
        this.handleCountdownTimer = setInterval(this.handleCountdown, 1000);

        window.addEventListener("mousemove", this.onActivityDetected);
        window.addEventListener("keypress", this.onActivityDetected);
        window.addEventListener("visibilitychange", this.onVisibilityChange);

        this.registerView();
    }

    componentWillUnmount() {
        clearInterval(this.registerViewTimer);
        clearInterval(this.handleCountdownTimer);

        window.removeEventListener("mousemove", this.onActivityDetected);
        window.removeEventListener("keypress", this.onActivityDetected);
        window.removeEventListener("visibilitychange", this.onVisibilityChange);
    }

    handleCountdown() {
        this.inactivityCountdown--;
    }

    onActivityDetected() {
        this.inactivityCountdown = this.inactivityTimeout;
    }

    onVisibilityChange() {
        this.inFocus = document.visibilityState === "visible";
        this.registerView();
    }

    registerView() {
        if(this.inactivityCountdown <= 0) {
            this.viewToken = null;
            return;
        }
        if(!this.inFocus) {
            return;
        }
        axios.post("/registerLeadView", {
            viewToken: this.viewToken,
            ...this.props
        }, { noAnimatedLogo: true })
            .then((response) => {
                if(response.data.valid) {
                    if(response.data.viewToken) {
                        this.viewToken = response.data.viewToken;
                    }
                } else {
                    console.error(response.data.error);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    render() {
        return null;
    }
}

export default ViewNotifier;

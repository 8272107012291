import React, {
    useMemo,
    useState
} from "react";
import axios from "axios";
import {
    useHistory
} from "react-router-dom";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";

function RestockOrderRemoveModal({ restockOrder, show, handleClose }) {
    const history = useHistory();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const onRemove = useMemo(() => {
        return async () => {
            if(!restockOrder) {
                return;
            }
            setLoading(true);
            try {
                await axios.post("/removeRestockOrder", { restockOrderId: restockOrder.id });
                history.push("/orders");
            } catch(error) {
                setError("Er is een fout opgetreden, probeer het later opnieuw.");
            }
            setLoading(false);
        }
    }, [restockOrder, history]);

    return (
        <Modal show={ show } onHide={ handleClose }>
            <Modal.Header closeButton>
                <Modal.Title className="text-danger">
                    Bestelling verwijderen
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                <p className="mb-0">Weet je zeker dat je deze bestelling wilt verwijderen?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose } disabled={ loading }>
                    Annuleren
                </Button>
                <Button variant="danger" onClick={ onRemove } disabled={ loading }>
                    { loading && (
                        <Spinner animation="border" size="sm" className="mr-2"/>
                    )}
                    Verwijderen
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default React.memo(RestockOrderRemoveModal);

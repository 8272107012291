import React, {
    useEffect,
    useState
} from "react";
import moment from "moment";
import {
    Chart
} from "react-charts";

import ChartLoading from "../../../statistics/components/ChartLoading";
import ChartNoData from "../../../statistics/components/ChartNoData";

function ProductAvailabilityChart({ amountAvailable, preferredMinAmount }) {
    const [data, setData] = useState(null);

    useEffect(() => {
        if(!amountAvailable) {
            setData(null);
            return;
        }
        const chartData = Object.entries(amountAvailable).map(([date, value]) => {
            return {
                date,
                value
            }
        });
        const zeroData = Object.keys(amountAvailable).map((date) => {
            return {
                date,
                value: 0
            }
        });
        let newData = [{
            label: "Aantal beschikbaar",
            data: chartData
        }, {
            label: "Nul",
            data: zeroData
        }];
        if(preferredMinAmount) {
            const preferredMinAmountData = Object.keys(amountAvailable).map((date) => {
                return {
                    date,
                    value: preferredMinAmount
                }
            });
            newData.push({
                label: "Voorkeurs minimale voorraad",
                data: preferredMinAmountData
            });
        }
        setData(newData);
    }, [amountAvailable, preferredMinAmount]);

    const primaryAxis = React.useMemo(
        () => ({
            getValue: (datum) => moment(datum.date).toDate(),
            tickCount: 20,
            scaleType: "time",
            hardMin: (() => {
                if(!data || data.length <= 0 || data[0].data.length <= 0) return null;
                return moment(data[0].data[0].date).toDate();
            })(),
            formatters: {
                scale: (date) => moment(date).format("MMM YYYY"),
                cursor: (date) => moment(date).format("DD-MM-YYYY")
            }
        }), [data]
    );
    const secondaryAxes = React.useMemo(
        () => [
            {
                getValue: (datum) => datum.value,
                elementType: "line"
            },
        ], []
    );

    if(!data) {
        return (
            <ChartLoading/>
        )
    }
    if(data.length === 0) {
        return (
            <ChartNoData/>
        )
    }
    return (
        <Chart
            options={{
                data,
                primaryAxis,
                secondaryAxes,
                getSeriesStyle: (series) => {
                    if(series.index === 1) {
                        return {
                            color: "red"
                        };
                    }
                    if(series.index === 2) {
                        return {
                            color: "#d6a800"
                        };
                    }
                    return null;
                }
            }}
        />
    )
}

export default React.memo(ProductAvailabilityChart);

import React, {
    useContext,
    useState
} from "react";
import {
    Redirect,
    Route,
    Switch
} from "react-router-dom";
import {
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import {
    TabBar,
    TabBarItem,
    TabBarLabel,
    Title
} from "@zandor300/backoffice-framework";

import PlanningCalendar from "./PlanningCalendar";
import PlanningInstallationsToPlan from "./PlanningInstallationsToPlan";
import PlanningInstallationsPlanned from "./PlanningInstallationsPlanned";
import PlanningLeadsToPlan from "./PlanningLeadsToPlan";
import PlanningLeadsPlanned from "./PlanningLeadsPlanned";
import PlanningDayCalendar from "./PlanningDayCalendar";
import PlanningManager, {
    PlanningContext
} from "./context/PlanningContext";

function PlanningPage(props) {
    return (
        <PlanningManager>
            <PlanningPageInternal { ...props }/>
        </PlanningManager>
    )
}

function PlanningPageInternal(props) {
    const planningContext = useContext(PlanningContext);
    const [selectedDate, setSelectedDate] = useState(null);
    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
    }

    return (
        <React.Fragment>
            <Title
                preTitle="Overzicht"
                noBottom
                preChildren={
                    planningContext.calendarMatch && (
                        <div className="float-right mt-2">
                            <OverlayTrigger placement="top" overlay={
                                <Tooltip id="address">Filters</Tooltip>
                            }>
                                <Button variant="secondary" onClick={ () => planningContext.setShowFilterModal(true) }>
                                    <i className="fas fa-filter fa-fw"/>
                                </Button>
                            </OverlayTrigger>
                        </div>
                    )
                }
            >
                Planning
            </Title>
            <TabBar>
                <TabBarLabel>Kalender</TabBarLabel>
                <TabBarItem
                    to={ selectedDate ? `${props.match.path}/day/` + selectedDate.format("YYYY-MM-DD") : `${props.match.path}/day` }
                    exact={ false }
                    activeUrl={ `${props.match.path}/day` }
                >
                    Dag
                </TabBarItem>
                <TabBarItem
                    to={ selectedDate ? `${props.match.path}/week/` + selectedDate.format("GGGG/WW") : `${props.match.path}/week` }
                    exact={ false }
                    activeUrl={ `${props.match.path}/week` }
                >
                    Week
                </TabBarItem>
                <TabBarItem
                    to={ selectedDate ? `${props.match.path}/workweek/` + selectedDate.format("GGGG/WW") : `${props.match.path}/workweek` }
                    exact={ false }
                    activeUrl={ `${props.match.path}/workweek` }
                >
                    Werkweek
                </TabBarItem>
                <TabBarItem
                    to={ selectedDate ? `${props.match.path}/month/` + selectedDate.format("YYYY/MM") : `${props.match.path}/month` }
                    exact={ false }
                    activeUrl={ `${props.match.path}/month` }
                >
                    Maand
                </TabBarItem>

                <TabBarLabel className="ml-4">Installaties</TabBarLabel>
                <TabBarItem to={`${props.match.path}/installations/to-plan`}>
                    In te plannen
                </TabBarItem>
                <TabBarItem to={`${props.match.path}/installations/planned-in`}>
                    Ingepland
                </TabBarItem>

                <TabBarLabel className="ml-4">Leads</TabBarLabel>
                <TabBarItem to={`${props.match.path}/leads/to-plan`}>
                    In te plannen
                </TabBarItem>
                <TabBarItem to={`${props.match.path}/leads/planned-in`}>
                    Ingepland
                </TabBarItem>
            </TabBar>

            <Switch>
                <Route path={`${props.match.path}`} exact>
                    <Redirect to={`${props.match.path}/week`}/>
                </Route>
                <Route
                    path={[
                        `${props.match.path}/day`,
                        `${props.match.path}/day/:date`,
                    ]}
                    exact
                >
                    <PlanningDayCalendar
                        handleDateChange={ handleDateChange }
                        selectedDate={ selectedDate }
                    />
                </Route>
                <Route
                    path={[
                        `${props.match.path}/week`,
                        `${props.match.path}/week/:year/:weekNumber`,
                        `${props.match.path}/workweek`,
                        `${props.match.path}/workweek/:year/:weekNumber`,
                        `${props.match.path}/month`,
                        `${props.match.path}/month/:year/:month`
                    ]}
                    exact
                >
                    <PlanningCalendar
                        handleDateChange={ handleDateChange }
                        selectedDate={ selectedDate }
                    />
                </Route>
                <Route path={`${props.match.path}/installations/to-plan`} exact component={PlanningInstallationsToPlan}/>
                <Route path={`${props.match.path}/installations/planned-in`} exact component={PlanningInstallationsPlanned}/>
                <Route path={`${props.match.path}/leads/to-plan`} exact component={PlanningLeadsToPlan}/>
                <Route path={`${props.match.path}/leads/planned-in`} exact component={PlanningLeadsPlanned}/>
            </Switch>

        </React.Fragment>
    )
}

export default PlanningPage;

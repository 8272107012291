import React, {
    useEffect,
    useState
} from "react";

const sizes = {
    "150x150": {
        1: "150x150",
        2: "300x300",
        3: "450x450"
    },
    "315x315": {
        1: "315x315",
        2: "630x630"
    }
}

function ResizableImage({ image, size = "original", alt, ...props }) {
    const [imageUrl, setImageUrl] = useState("");
    const [pixelRatio, setPixelRatio] = useState(Math.ceil(window.devicePixelRatio));

    useEffect(() => {
        const updateImageUrl = () => {
            if(!image) {
                setImageUrl(null);
            } else if(size === "original") {
                setImageUrl(image.url);
            } else {
                const sizeData = sizes[size];
                if(image.alternatives && sizeData) {
                    let found = false;
                    for (const [key, value] of Object.entries(sizeData)) {
                        if(parseInt(key) >= window.devicePixelRatio) {
                            const alternativeImage = image.alternatives[value];
                            if(alternativeImage) {
                                setImageUrl(alternativeImage.url);
                                found = true;
                                break;
                            }
                        }
                    }
                    if(!found) {
                        for(const key of Object.keys(sizeData).sort((k1, k2) => { return k1 > k2 ? -1 : 1 })) {
                            const value = sizeData[key];
                            const alternativeImage = image.alternatives[value];
                            if(alternativeImage) {
                                setImageUrl(alternativeImage.url);
                                found = true;
                                break;
                            }
                        }
                    }
                    if(!found) {
                        setImageUrl(image.url);
                    }
                } else {
                    setImageUrl(image.url);
                }
            }
        }

        const updatePixelRatioIfNeeded = () => {
            const currentPixelRatio = Math.ceil(window.devicePixelRatio);
            if(currentPixelRatio !== pixelRatio) {
                setPixelRatio(currentPixelRatio);
                updateImageUrl();
            }
        }

        updatePixelRatioIfNeeded();
        updateImageUrl();

        window.addEventListener("resize", updatePixelRatioIfNeeded);
        return () => window.removeEventListener("resize", updatePixelRatioIfNeeded);
    }, [pixelRatio, image, size]);

    if(!imageUrl) {
        return null;
    }
    return (
        <img
            src={ imageUrl }
            alt={ alt }
            { ...props }
        />
    )
}

export default React.memo(ResizableImage);

import React, {
    useMemo,
    useState
} from "react";
import {
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import {
    parsePhoneNumber
} from "libphonenumber-js";

function CallLink({ phoneNumber, ...restProps }) {
    const [showOriginal, setShowOriginal] = useState(false);

    const onClickShowOriginal = useMemo(() => {
        return () => {
            setShowOriginal((oldValue) => !oldValue);
        }
    }, []);

    const parsedPhoneNumber = useMemo(() => {
        if(!phoneNumber) {
            return null;
        }
        try {
            return parsePhoneNumber(phoneNumber, "NL");
        } catch(error) {
            return null;
        }
    }, [phoneNumber]);

    if(!parsedPhoneNumber || !parsedPhoneNumber.isValid()) {
        return (
            <React.Fragment>
                <a { ...restProps } href={ "tel:" + phoneNumber }>
                    { phoneNumber }
                </a>
                {" "}(Ongeldig)
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            <a { ...restProps } href={ parsedPhoneNumber.getURI() }>
                { showOriginal ? phoneNumber : parsedPhoneNumber.formatInternational() }
            </a>
            <OverlayTrigger placement="top" overlay={
                <Tooltip id="showOriginal">
                    { showOriginal ? (
                        "Hervormd telefoonummer tonen"
                    ) : (
                        "Origineel telefoonnummer tonen"
                    )}
                </Tooltip>
            }>
                <Button
                    variant="link"
                    size="sm"
                    className="py-0"
                    onClick={ onClickShowOriginal }
                >
                    <i className="fas fa-eye fa-fw"/>
                </Button>
            </OverlayTrigger>
        </React.Fragment>
    )
}

export default React.memo(CallLink);

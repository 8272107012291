import React from "react";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

function DeviceTypeTooltip({ deviceType, text, children}) {
    return (
        <OverlayTrigger key={ deviceType } placement="top" overlay={
            <Tooltip id={ "tooltip-" + deviceType }>
                { text }
            </Tooltip>
        }>
            { children }
        </OverlayTrigger>
    )
}

function DeviceTypeIcon({ deviceType, className = "" }) {
    switch (deviceType) {
        case "desktop":
            return (
                <DeviceTypeTooltip deviceType={ deviceType } text="Deze actie is uitgevoerd via desktop">
                    <i className={"fas fa-desktop fa-fw " + className}/>
                </DeviceTypeTooltip>
            )
        case "ios":
            return (
                <DeviceTypeTooltip deviceType={ deviceType } text="Deze actie is uitgevoerd via iOS">
                    <i className={"fas fa-mobile fa-fw " + className}/>
                </DeviceTypeTooltip>
            )
        case "automatic":
            return (
                <DeviceTypeTooltip deviceType={ deviceType } text="Deze actie is automatisch uitgevoerd">
                    <i className={"fas fa-cog fa-fw " + className}/>
                </DeviceTypeTooltip>
            )
        default:
            return (
                <DeviceTypeTooltip deviceType={ deviceType } text="Deze actie is uitgevoerd op een onbekend apparaat">
                    <i className={"fas fa-question-circle fa-fw " + className}/>
                </DeviceTypeTooltip>
            )
    }
}

export default React.memo(DeviceTypeIcon);

import React from "react";
import axios from "axios";
import {
    withRouter
} from "react-router-dom";
import {
    Alert,
    Button,
    Form,
    Modal,
    Spinner
} from "react-bootstrap";

import Loading from "../../../../components/Loading";
import InstallationForm from "../../../installations/detail/components/InstallationForm";

class LeadDetailSetStatusModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            statuses: null,
            hasDeprecatedStatuses: false,
            error: null,
            errorSave: null,
            loading: false,

            selectedStatus: null,

            reason: "",

            useLeadAddress: true,
            street: "",
            houseNumber: "",
            postalCode: "",
            city: "",
            hasVATRefund: null,
            description: ""
        };
        this.didClickSave = this.didClickSave.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.statuses !== this.state.statuses) {
            if(!this.state.statuses) {
                this.setState({ hasDeprecatedStatuses: false });
            } else {
                const deprecatedStatuses = this.state.statuses.filter(status => status.deprecated);
                this.setState({ hasDeprecatedStatuses: deprecatedStatuses.length > 0 });
            }
        }
    }

    getErrorMessage(errorCode = null) {
        switch(errorCode) {
            case "CANT_EXPIRE_LEAD_REMAINING_INSTALLATIONS":
                return "Er staan nog openstaande installaties onder deze lead.";
            case "NOT_ALL_FILLED_IN":
                return "Niet alle vereiste velden zijn ingevuld.";
            case "REASON_REQUIRED":
                return "Voor de huidige geselecteerde status is het opgeven van een reden vereist.";
            default:
                let error = "Er ging iets fout. Probeer het later opnieuw.";
                if(errorCode) {
                    error += ` (${errorCode})`;
                }
                return error;
        }
    }

    getStatuses() {
        this.setState({ statuses: null, error: null });
        axios.get("/getLeadStatuses")
            .then((response) => {
                if(response.data.valid) {
                    let statuses = response.data.data;
                    statuses.sort((status1, status2) => {
                        if(status1.priority > status2.priority) return -1;
                        if(status1.priority < status2.priority) return 1;
                        return 0;
                    });
                    this.setState({ statuses });
                } else {
                    this.setState({ error: this.getErrorMessage(response.data.error) });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: this.getErrorMessage() });
            });
    }

    setStatus(onSuccess, onError) {
        axios.post("/setLeadStatus", { lead: this.props.lead.id, status: this.state.selectedStatus })
            .then((response) => {
                if(response.data.valid) {
                    onSuccess(() => {
                        this.props.handleStatusChanged(response.data.status);
                    });
                } else {
                    onError(this.getErrorMessage(response.data.error));
                }
            })
            .catch((error) => {
                console.error(error);
                onError(this.getErrorMessage());
            });
    }

    addReasonComment(onSuccess, onError) {
        const message = "## Reden voor verlopen\n" + this.state.reason;
        axios.post("/addLeadComment", { lead: this.props.lead.id, message })
            .then((response) => {
                if(response.data.valid) {
                    onSuccess(() => {
                        this.props.handleCommentAdded();
                    });
                } else {
                    onError(this.getErrorMessage(response.data.error));
                }
            })
            .catch((error) => {
                console.error(error);
                onError(this.getErrorMessage());
            });
    }

    addInstallation(onSuccess, onError) {
        if(!this.state.useLeadAddress) {
            if(this.state.street.length === 0 || this.state.houseNumber.length === 0 || this.state.postalCode.length === 0 || this.state.city.length === 0) {
                onError(this.getErrorMessage("NOT_ALL_FILLED_IN"));
                return;
            }
        }
        if(this.state.hasVATRefund === null) {
            onError(this.getErrorMessage("NOT_ALL_FILLED_IN"));
            return;
        }
        axios.post("/addInstallation", {
            leadId: this.props.lead.id,

            useLeadAddress: this.state.useLeadAddress ? 1 : 0,
            street: this.state.street,
            houseNumber: this.state.houseNumber,
            postalCode: this.state.postalCode,
            city: this.state.city,
            hasVATRefund: this.state.hasVATRefund ? 1 : 0,
            description: this.state.description
        })
            .then((response) => {
                if(response.data.valid) {
                    onSuccess(() => {
                        this.props.history.push("/installation/" + response.data.installation.id);
                    });
                } else {
                    onError(this.getErrorMessage(response.data.error));
                }
            })
            .catch((error) => {
                console.error(error);
                onError(this.getErrorMessage());
            });
    }

    getInitialStatus() {
        return this.props.lead.status.id;
    }

    shouldEnterReason() {
        if(!this.state.statuses) {
            return false;
        }
        const selectedStatus = this.state.statuses.find((status) => {
            return status.id === parseInt(this.state.selectedStatus);
        });
        return !this.props.lead.status.expired && selectedStatus.expired;
    }

    shouldCreateInstallation() {
        if(!this.state.statuses) {
            return false;
        }
        const selectedStatus = this.state.statuses.find((status) => {
            return status.id === parseInt(this.state.selectedStatus);
        });
        return !this.props.lead.status.readyForPlanning && selectedStatus.readyForPlanning;
    }

    didClickSave() {
        const reasonRequired = this.shouldEnterReason();
        const createInstallation = this.shouldCreateInstallation();
        if(reasonRequired) {
            if(this.state.reason.trim().length === 0) {
                this.setState({ errorSave: this.getErrorMessage("REASON_REQUIRED") });
                return;
            }
        }
        this.setState({ loading: true, errorSave: null });
        this.setStatus((callbackStatus) => {
            if(reasonRequired) {
                this.addReasonComment((callbackComment) => {
                    if(callbackStatus) {
                        callbackStatus();
                    }
                    if(callbackComment) {
                        callbackComment();
                    }
                }, (error) => {
                    this.setState({ loading: false, errorSave: error });
                });
            } else if(createInstallation) {
                this.addInstallation((callbackComment) => {
                    if(callbackStatus) {
                        callbackStatus();
                    }
                    if(callbackComment) {
                        callbackComment();
                    }
                }, (error) => {
                    this.setState({ loading: false, errorSave: error });
                });
            } else {
                if(callbackStatus) {
                    callbackStatus();
                }
            }
        }, (error) => {
            this.setState({ loading: false, errorSave: error });
        });
    }

    onShow() {
        this.setState({ selectedStatus: this.getInitialStatus(), reason: "", statuses: null, errorSave: null, loading: false });
        this.getStatuses();
    }

    onStatusSelected(event) {
        this.setState({ selectedStatus: event.target.value });
    }

    render() {
        const showReasonField = this.shouldEnterReason();
        const shouldCreateInstallation = this.shouldCreateInstallation();
        return (
            <Modal show={ this.props.show } onHide={ this.props.handleClose } onShow={ this.onShow.bind(this) } size={ shouldCreateInstallation ? "xl" : "md" }>
                <Modal.Header closeButton>
                    <Modal.Title>
                        { shouldCreateInstallation ? "Installatie aanmaken" : "Status veranderen" }
                    </Modal.Title>
                </Modal.Header>
                <form className="mb-0">
                    <Modal.Body>
                        { this.state.error ? (
                            <Alert variant="danger">{ this.state.error }</Alert>
                        ) : this.state.statuses === null ? (
                            <Loading/>
                        ) : (
                            <React.Fragment>
                                { this.state.errorSave && (
                                    <Alert variant="danger">{ this.state.errorSave }</Alert>
                                )}
                                <Form.Group controlId="assignedUser" className={ showReasonField || shouldCreateInstallation ? "" : "mb-0" }>
                                    <Form.Label>Status</Form.Label>
                                    <Form.Control
                                        as="select" disabled={ this.state.loading }
                                        value={ this.state.selectedStatus }
                                        onChange={ this.onStatusSelected.bind(this) }
                                    >
                                        { this.state.hasDeprecatedStatuses ? (
                                            <React.Fragment>
                                                <optgroup label="Statussen">
                                                    { this.state.statuses.filter(status => !status.deprecated).map( status => (
                                                        <option key={ status.id } value={ status.id } style={{ backgroundColor: status.color, color: "white" }}>
                                                            { status.name }
                                                        </option>
                                                    ))}
                                                </optgroup>
                                                <optgroup label="Verouderde statussen">
                                                    { this.state.statuses.filter(status => status.deprecated).map( status => (
                                                        <option key={ status.id } value={ status.id } style={{ backgroundColor: status.color, color: "white" }}>
                                                            { status.name }
                                                        </option>
                                                    ))}
                                                </optgroup>
                                            </React.Fragment>
                                        ) : (
                                            this.state.statuses.map( status => (
                                                <option key={ status.id } value={ status.id } style={{ backgroundColor: status.color, color: "white" }}>
                                                    { status.name }
                                                </option>
                                            ))
                                        )}
                                    </Form.Control>
                                </Form.Group>
                                { showReasonField && (
                                    <Form.Group controlId="reason" className="mb-0">
                                        <Form.Label>Reden</Form.Label>
                                        <Form.Control
                                            as="textarea" disabled={ this.state.loading }
                                            value={this.state.reason}
                                            onChange={(event) => this.setState({reason: event.target.value})}
                                        />
                                    </Form.Group>
                                )}
                                { shouldCreateInstallation && (
                                    <InstallationForm
                                        fields={ this.state }
                                        setField={ (state) => this.setState({ ...state }) }
                                        lead={ this.props.lead }
                                        saving={ this.state.loading }
                                    />
                                )}
                            </React.Fragment>
                        ) }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={ this.props.handleClose } disabled={ this.state.loading }>
                            Annuleer
                        </Button>
                        <Button variant="primary" onClick={ this.didClickSave } disabled={ this.state.loading }>
                            { this.state.loading && (
                                <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                            )}
                            Opslaan
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }
}

export default withRouter(LeadDetailSetStatusModal);

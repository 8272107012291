import React from "react";
import moment from "moment";
import {
    Link
} from "react-router-dom";
import {
    Button,
    ButtonGroup,
    ButtonToolbar,
    Card
} from "react-bootstrap";

import TagPill from "../../../components/tagPill";
import DateFormatter from "../../../components/formatters/DateFormatter";
import ReactMarkdownBreaks from "../../../components/ReactMarkdownBreaks";

function PlanningItemCard({
                              planningItem,
                              className,
                              linkToPlanning,
                              onClickEdit,
                              onClickDelete,
                              onClickHistory,
                              noHeader,
                              noSuffix,
                              customCardHeader
}) {
    let description = planningItem.description;
    if(planningItem.descriptionSuffix && !noSuffix) {
        description += "\n\n" + planningItem.descriptionSuffix;
    }

    const hasHistory = planningItem.history && planningItem.history.length > 0;

    return (
        <Card className={ "mb-3" + (className !== undefined ? " " + className : "") }>
            { customCardHeader }
            <Card.Body>
                <span className="float-right">
                    { linkToPlanning ? (
                        <Link to={ "/planning/week/" + moment(planningItem.date).format("gggg/WW") } className="no-link-style">
                            <DateFormatter date={ planningItem.date } format="L" message="Klik om in planning te openen"/>
                        </Link>
                    ) : (
                        <DateFormatter date={ planningItem.date } format="L"/>
                    )}
                </span>
                { !noHeader && (
                    <p className="card-text">
                        <TagPill color={ planningItem.type.color }>{ planningItem.type.name }</TagPill>
                    </p>
                )}
                { description.length > 0 && (
                    <ReactMarkdownBreaks message={ description }/>
                )}
            </Card.Body>
            <Card.Footer className="text-muted">
                <ButtonToolbar className="float-right">
                    { linkToPlanning && (
                        <ButtonGroup size="sm" className="ml-2">
                            <Link to={ "/planning/week/" + moment(planningItem.date).format("gggg/WW") } className="btn btn-secondary">
                                <i className="fas fa-calendar-alt mr-1"/>
                                Naar planning
                            </Link>
                        </ButtonGroup>
                    )}
                    { (onClickEdit || onClickDelete || (hasHistory && onClickHistory)) && (
                        <ButtonGroup size="sm" className="float-right ml-2">
                            { hasHistory && onClickHistory && (
                                <Button variant="secondary" onClick={ onClickHistory }>
                                    <i className="fas fa-history mr-1"/>
                                    Geschiedenis
                                </Button>
                            )}
                            { onClickEdit && (
                                <Button variant="primary" onClick={ onClickEdit }>
                                    <i className="fas fa-pen mr-1"/>
                                    Bewerken
                                </Button>
                            )}
                            { onClickDelete && (
                                <Button variant="danger" onClick={ onClickDelete }>
                                    <i className="fas fa-trash-alt mr-1"/>
                                    Verwijderen
                                </Button>
                            )}
                        </ButtonGroup>
                    )}
                </ButtonToolbar>

                <p className="d-inline-block mr-2" style={{ marginBottom: 0, lineHeight: "28px" }}>
                    { planningItem.editDate ? (
                        <React.Fragment>
                            <b>{ planningItem.author ? planningItem.author.name : "onbekend" } </b>
                            <DateFormatter date={ planningItem.editDate }/>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <span>Aangemaakt door <b>{ planningItem.author ? planningItem.author.name : "onbekend" }</b> op </span>
                            <DateFormatter date={ planningItem.dateCreated }/>
                        </React.Fragment>
                    )}
                    { hasHistory && (
                        <span className="badge badge-pill badge-secondary ml-2">
                            <i className="fas fa-history"/> { planningItem.history.length } keer bewerkt
                        </span>
                    )}
                </p>
            </Card.Footer>
        </Card>
    );
}

export default React.memo(PlanningItemCard);

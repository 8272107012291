import React, {
    useMemo,
    useState
} from "react";
import moment from "moment";

import ImageModal from "./modal/ImageModal";
import ResizableImage from "../../components/ResizableImage";

function ImageGalleryCell({ image, onClick, isThumbnail }) {
    const onClickImage = useMemo(() => {
        return () => {
            onClick(image);
        }
    }, [onClick, image]);
    return (
        <div className="col image-box" onClick={ onClickImage }>
            <ResizableImage image={ image } alt={ "Image " + image.id } size="315x315"/>
            <div className="image-box-overlay d-flex flex-column">
                <div className="flex-grow-1">
                    <h4>{ image.author.name }</h4>
                    <p>{ moment(image.date).calendar() }</p>
                </div>
                <div className="d-flex justify-content-end">
                    { image.latitude && image.longitude && (
                        <i className="fas fa-map-marker-alt ml-2" style={{ fontSize: "1.5rem" }}/>
                    )}
                    { isThumbnail && (
                        <i className="fas fa-image-polaroid ml-2" style={{ fontSize: "1.5rem" }}/>
                    )}
                </div>
            </div>
        </div>
    );
}

function ImageGallery({ images, onImageRemoved, showOpenParentButton = false, currentThumbnail, setAsThumbnail }) {
    const [showImageModal, setShowImageModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageModalClose = useMemo(() => {
        return () => {
            setShowImageModal(false);
        }
    }, []);

    const onClickImage = useMemo(() => {
        return (image) => {
            setSelectedImage(image);
            setShowImageModal(true);
        }
    }, []);

    const onSetAsThumbnail = useMemo(() => {
        return (image) => {
            setAsThumbnail(image, handleImageModalClose);
        }
    }, [setAsThumbnail, handleImageModalClose]);

    return (
        <React.Fragment>
            <ImageModal
                image={ selectedImage }
                show={ showImageModal }
                onImageRemoved={ onImageRemoved }
                handleClose={ handleImageModalClose }
                showOpenParentButton={ showOpenParentButton }
                currentThumbnail={ currentThumbnail }
                setAsThumbnail={ onSetAsThumbnail }
            />

            <div className="row no-gutters row-cols-2 row-cols-print-4 row-cols-md-4 row-cols-lg-5 row-cols-xl-6">
                { images.map((image) => {
                    const isThumbnail = currentThumbnail && currentThumbnail.id === image.id;
                    return (
                        <ImageGalleryCell
                            image={ image }
                            onClick={ onClickImage }
                            key={ image.id }
                            isThumbnail={ isThumbnail }
                        />
                    );
                })}
            </div>
        </React.Fragment>
    )
}

export default React.memo(ImageGallery);

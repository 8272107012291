import React from "react";
import SortIcon from "./SortIcon";

function OverviewSortTableHeader({ title, currentSortKey, sortKey, sortFunction, className, minWidth, maxWidth }) {
    return (
        <th scope="col" className={ "clickable" + (className ? " " + className : "") } onClick={ () => sortFunction(sortKey) } style={{ minWidth, maxWidth }}>
            <div className="d-flex flex-row justify-content-between">
                <div className="flex-grow-1">{ title }</div>
                <div className="d-flex align-items-end ml-2">
                    <SortIcon currentSortKey={ currentSortKey } sortKey={ sortKey } />
                </div>
            </div>
        </th>
    )
}

export default React.memo(OverviewSortTableHeader);

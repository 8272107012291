import React, {
    useMemo
} from "react";
import {
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import DetailOverviewRow from "../../../../components/detail/DetailOverviewRow";
import ClickCopy from "../../../../components/copy/ClickCopy";
import TagPill from "../../../../components/tagPill";
import DateFormatter from "../../../../components/formatters/DateFormatter";
import ReactMarkdownBreaks from "../../../../components/ReactMarkdownBreaks";
import LeadExcelCopyButton from "./LeadExcelCopyButton";
import LeadSendEmailButton from "./LeadSendEmailButton";
import CallLink from "../../../../components/detail/CallLink";

function LeadDetailOverviewCard({ lead }) {
    const address = useMemo(() => {
        if(!lead) {
            return null;
        }
        return lead.street + " " + lead.houseNumber + ", " + lead.city;
    }, [lead]);

    return (
        <div className="card">
            <div className="card-body">
                <table className="table table-borderless mb-0">
                    <tbody>
                    <DetailOverviewRow title="Naam">
                        { lead ? (
                            <ClickCopy text={ lead.name }>
                                { lead.name }
                            </ClickCopy>
                        ) : <Skeleton width={200}/> }
                    </DetailOverviewRow>
                    <DetailOverviewRow title="Toegewezen werknemer">
                        { lead ? (
                            <React.Fragment>
                                { lead.assignedUser ? lead.assignedUser.name : "Niemand" }
                            </React.Fragment>
                        ) : (
                            <Skeleton width={150}/>
                        )}
                    </DetailOverviewRow>
                    { lead && lead.completedByUser && (
                        <DetailOverviewRow title="Verkocht door">
                            { lead.completedByUser.name }
                        </DetailOverviewRow>
                    )}
                    <DetailOverviewRow title="Status">
                        { lead ? (
                            <React.Fragment>
                                <TagPill color={ lead.status.color }>
                                    { lead.status.name }
                                </TagPill>
                            </React.Fragment>
                        ) : (
                            <Skeleton width={175}/>
                        )}
                    </DetailOverviewRow>
                    <DetailOverviewRow title="Adres">
                        { lead ? (
                            <React.Fragment>
                                <ClickCopy text={ address }>
                                    { address }
                                </ClickCopy>
                                <OverlayTrigger placement="top" overlay={
                                    <Tooltip id="address">Adres openen in Google Maps</Tooltip>
                                }>
                                    <a href={ "https://google.com/maps?q=" + encodeURIComponent(address) + "&t=k" }
                                       className="btn btn-link btn-sm py-0" target="_blank" rel="noreferrer noopener">
                                        <i className="fas fa-map"/>
                                    </a>
                                </OverlayTrigger>
                                <OverlayTrigger placement="top" overlay={
                                    <Tooltip id="address">Adres openen in Google Earth</Tooltip>
                                }>
                                    <a href={ "https://earth.google.com/web/search/" + encodeURIComponent(address) }
                                       className="btn btn-link btn-sm py-0" target="_blank" rel="noreferrer noopener">
                                        <i className="fas fa-globe-europe"/>
                                    </a>
                                </OverlayTrigger>
                            </React.Fragment>
                        ) : (
                            <Skeleton width={300}/>
                        )}
                    </DetailOverviewRow>
                    <DetailOverviewRow title="Postcode">
                        { lead ? (
                            <ClickCopy text={ lead.postalCode }>
                                { lead.postalCode }
                            </ClickCopy>
                        ) : <Skeleton width={75}/> }
                    </DetailOverviewRow>
                    <DetailOverviewRow title="Telefoonnummer">
                        { lead ? (
                            <CallLink phoneNumber={ lead.phoneNumber }/>
                        ) : (
                            <Skeleton width={100}/>
                        )}
                    </DetailOverviewRow>
                    <DetailOverviewRow title="Email">
                        { lead ? (
                            <a href={"mailto:" + lead.email}>{lead.email}</a>
                        ) : (
                            <Skeleton width={200}/>
                        )}
                    </DetailOverviewRow>
                    <DetailOverviewRow title="Datum">
                        { lead ? (
                            <React.Fragment>
                                <DateFormatter date={lead.date}/>
                                { lead.creationDate !== lead.date && (
                                    <span className="text-muted ml-2">
                                        (Aangemaakt op <DateFormatter date={lead.creationDate}/>)
                                    </span>
                                )}
                            </React.Fragment>
                        ) : (
                            <Skeleton width={300}/>
                        )}
                    </DetailOverviewRow>
                    <DetailOverviewRow title="Type">
                        { lead ? (
                            lead.type ? (
                                <TagPill color={lead.type.color}>
                                    {lead.type.name}
                                </TagPill>
                            ) : (
                                <TagPill color="#595959">
                                    Onbepaald
                                </TagPill>
                            )
                        ) : (
                            <Skeleton width={150}/>
                        )}
                    </DetailOverviewRow>
                    <DetailOverviewRow title="Bron">
                        { lead ? (
                            <TagPill color={lead.source.color}>
                                {lead.source.name}
                            </TagPill>
                        ) : (
                            <Skeleton width={175}/>
                        )}
                    </DetailOverviewRow>
                    <DetailOverviewRow title="Aangemaakt door" hideOnPrint>
                        { lead ? (
                            lead.author ? lead.author.name : (
                                <i className="text-muted">Automatisch</i>)
                        ) : (
                            <Skeleton width={150}/>
                        )}
                    </DetailOverviewRow>
                    <DetailOverviewRow title="Locatie" hideOnPrint>
                        { lead ? (
                            <React.Fragment>
                                { lead.latitude && lead.longitude ? (
                                    <React.Fragment>
                                        { lead.latitude + ", " + lead.longitude }
                                    </React.Fragment>
                                ) : (
                                    <i className="text-muted">Geen locatie beschikbaar</i>
                                )}
                                <OverlayTrigger placement="top" overlay={
                                    <Tooltip id="address">Coördinaten verversen</Tooltip>
                                }>
                                    <Button onClick={ () => this.refreshCoordinates(lead.id) } variant="link" size="sm" className="ml-2 py-0">
                                        <i className="fas fa-sync"/>
                                    </Button>
                                </OverlayTrigger>
                                { lead.latitude && lead.longitude && (
                                    <React.Fragment>
                                        <OverlayTrigger placement="top" overlay={
                                            <Tooltip id="address">Coördinaten openen in Google Maps</Tooltip>
                                        }>
                                            <a href={ "https://google.com/maps?q=" + lead.latitude + "," + lead.longitude + "&t=k" }
                                               className="btn btn-link btn-sm py-0" target="_blank" rel="noreferrer noopener">
                                                <i className="fas fa-map"/>
                                            </a>
                                        </OverlayTrigger>
                                        <OverlayTrigger placement="top" overlay={
                                            <Tooltip id="address">Coördinaten openen in Google Earth</Tooltip>
                                        }>
                                            <a href={ "https://earth.google.com/web/search/" + lead.latitude + "," + lead.longitude }
                                               className="btn btn-link btn-sm py-0" target="_blank" rel="noreferrer noopener">
                                                <i className="fas fa-globe-europe"/>
                                            </a>
                                        </OverlayTrigger>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        ) : (
                            <Skeleton width={150}/>
                        )}
                    </DetailOverviewRow>
                    { lead && lead.customerMessage && lead.customerMessage.length > 0 && (
                        <DetailOverviewRow title="Klant bericht">
                            <ReactMarkdownBreaks message={ lead.customerMessage } noLinks/>
                        </DetailOverviewRow>
                    )}
                    <DetailOverviewRow title="Opmerkingen">
                        { lead ? (
                            lead.message && lead.message.length > 0 ? (
                                <ReactMarkdownBreaks message={ lead.message } noLinks/>
                            ) : (
                                <i className="text-muted">Leeg</i>
                            )
                        ) : (
                            <Skeleton count={3}/>
                        )}
                    </DetailOverviewRow>
                    <DetailOverviewRow hideOnPrint>
                        { lead ? (
                            <React.Fragment>
                                <LeadExcelCopyButton
                                    lead={ lead }
                                    variant="secondary"
                                    size="sm"
                                    className="mr-2"
                                >
                                    Excel calculatie kopie
                                </LeadExcelCopyButton>
                                <LeadSendEmailButton
                                    lead={ lead }
                                    variant="secondary"
                                    size="sm"
                                >
                                    Offerte herinnering email
                                </LeadSendEmailButton>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <span className="mr-2">
                                    <Skeleton width={ 148 } height={ 31 }/>
                                </span>
                                <Skeleton width={ 175 } height={ 31 }/>
                            </React.Fragment>
                        )}
                    </DetailOverviewRow>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default React.memo(LeadDetailOverviewCard);

import React, {
    useCallback,
    useEffect,
    useState
} from "react";
import axios from "axios";
import {
    Route,
    Switch,
    withRouter
} from "react-router-dom";
import {
    Alert
} from "react-bootstrap";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";
import Skeleton from "react-loading-skeleton";

import Page404 from "../../Page404";
import Helmet from "../../../components/Helmet";

import UserDetailOverview from "./UserDetailOverview";
import UserDetailImages from "./UserDetailImages";
import UserDetailLoginAttempts from "./UserDetailLoginAttempts";
import UserDetailLoginAttemptsPincode from "./UserDetailLoginAttemptsPincode";

function UserDetail({ match }) {
    const userId = match.params.userId;
    const urlPrefix = `/user/${userId}`;

    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    const [errorCode, setErrorCode] = useState(null);

    const refreshUser = useCallback(() => {
        setUser(null);
        setError(null);
        setErrorCode(null);
        axios.post("/getUser", { userId })
            .then((response) => {
                if(response.data.valid) {
                    setUser(response.data.data);
                } else {
                    setUser(null);
                    setError("Er was een fout bij het ophalen van de data. (" + response.data.error + ")");
                    setErrorCode(response.data.error);
                }
            })
            .catch((error) => {
                console.error(error);
                setUser(null);
                setError("Er was een fout bij het ophalen van de data.");
            });
    }, [userId]);

    useEffect(() => {
        if(isNaN(parseInt(userId))) {
            return;
        }
        refreshUser();
    }, [userId, refreshUser]);

    const childProps = {
        userId,
        user,
        error,
        setUser,
        refreshUser,
        urlPrefix
    };

    if(isNaN(parseInt(userId)) || userId < 1 || errorCode === "DOESNT_EXIST") {
        return <Page404/>
    }

    const name = !user ? null : user.name;

    return (
        <React.Fragment>
            <Helmet title={ "Gebruiker #" + userId + (name ? ` - ${name}` : "") }/>

            <Title preTitle="Detail" noBottom>
                { user ? (
                    <React.Fragment>
                        { user.name } <small className="text-muted">#{userId}</small>
                    </React.Fragment>
                ) : (
                    <Skeleton width={ 300 }/>
                )}
            </Title>

            { error? (
                <Alert variant="danger">{ error }</Alert>
            ) : (
                <React.Fragment>
                    <TabBar>
                        <TabBarItem to={ urlPrefix }>
                            Overview
                        </TabBarItem>
                        <TabBarItem to={ urlPrefix + "/images" }>
                            Foto's
                        </TabBarItem>
                        <TabBarItem to={ urlPrefix + "/login-attempts" }>
                            Login pogingen
                        </TabBarItem>
                        <TabBarItem to={ urlPrefix + "/login-attempts-pincode" }>
                            Login pogingen pincode
                        </TabBarItem>
                    </TabBar>

                    <Switch>
                        <Route path={ urlPrefix } exact>
                            <UserDetailOverview { ...childProps }/>
                        </Route>
                        <Route path={ urlPrefix + "/images" } exact>
                            <UserDetailImages { ...childProps }/>
                        </Route>
                        <Route path={ urlPrefix + "/login-attempts" } exact>
                            <UserDetailLoginAttempts { ...childProps }/>
                        </Route>
                        <Route path={ urlPrefix + "/login-attempts-pincode" } exact>
                            <UserDetailLoginAttemptsPincode { ...childProps }/>
                        </Route>
                    </Switch>
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default withRouter(React.memo(UserDetail));

import React, {
    useState
} from "react";
import {
    Alert,
    Button,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import DetailOverviewRow from "../../../components/detail/DetailOverviewRow";
import TagPill from "../../../components/tagPill";
import ProductQuickActionSetAssignedBuilding from "./components/ProductQuickActionSetAssignedBuilding";
import SetProductAmountModal from "./modal/SetProductAmountModal";
import ProductActionSetAmount from "./components/ProductActionSetAmount";
import NumberFormatter from "../../../components/formatters/NumberFormatter";
import EuroFormatter from "../../../components/formatters/EuroFormatter";
import ProductSetSuppliersModal from "./modal/ProductSetSuppliersModal";
import ProductSetSafeInventorySettingsModal from "./modal/ProductSetSafeInventorySettingsModal";

function ProductDetailOverview({ product, setProduct }) {
    const [showSetProductAmountModal, setShowSetProductAmountModal] = useState(false);
    const [showSetSuppliersModal, setShowSetSuppliersModal] = useState(false);
    const [showSetSafeInventorySettingsModal, setShowSetSafeInventorySettingsModal] = useState(false);

    const [error, setError] = useState(null);

    return (
        <React.Fragment>
            <SetProductAmountModal
                show={ showSetProductAmountModal }
                handleClose={ () => setShowSetProductAmountModal(false) }
                product={ product }
                setProduct={ setProduct }
            />
            <ProductSetSuppliersModal
                show={ showSetSuppliersModal }
                handleClose={ () => setShowSetSuppliersModal(false) }
                product={ product }
                onSaved={ setProduct }
            />
            <ProductSetSafeInventorySettingsModal
                show={ showSetSafeInventorySettingsModal }
                handleClose={ () => setShowSetSafeInventorySettingsModal(false) }
                product={ product }
                onSaved={ setProduct }
            />
            { error && (
                <Alert variant="danger">{ error }</Alert>
            )}
            <div className="card mb-3">
                <div className="card-body">
                    <table className="table table-borderless mb-0">
                        <tbody>
                        <DetailOverviewRow title="Merk">
                            { product ? product.brand.name : <Skeleton width={100}/> }
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Naam">
                            { product ? product.name : <Skeleton width={200}/> }
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Korte naam">
                            { product ? product.shortName : <Skeleton width={200}/> }
                        </DetailOverviewRow>
                        <DetailOverviewRow title="SKU">
                            { product ? product.sku : <Skeleton width={200}/> }
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Gewicht in kg">
                            { product ? (
                                product.weight ? (
                                    <React.Fragment>
                                        <NumberFormatter number={ product.weight / 1000 }/> kg
                                    </React.Fragment>
                                ) : (
                                    <i className="text-muted">Onbekend</i>
                                )
                            ) : <Skeleton width={200}/> }
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Afvalbeheerbijdrage">
                            { product ? (
                                product.wasteFee && product.wasteFeeTotal ? (
                                    <React.Fragment>
                                        <EuroFormatter price={ product.wasteFeeTotal }/> excl. btw
                                        <span className="ml-2 text-muted">
                                            (<EuroFormatter price={ product.wasteFee }/> per kg)
                                        </span>
                                    </React.Fragment>
                                ) : (
                                    <i className="text-muted">Onbekend</i>
                                )
                            ) : <Skeleton width={200}/> }
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Type">
                            { product ? (
                                <TagPill color={ product.type.color }>
                                    { product.type.name }
                                </TagPill>
                            ) : <Skeleton width={75}/> }
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Toegewezen locatie" titleVerticalCenter>
                            { product ? (
                                <ProductQuickActionSetAssignedBuilding
                                    product={ product }
                                    setProduct={ setProduct }
                                    onError={ setError }
                                />
                            ) : <Skeleton width={150} height={34}/> }
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Voorradig" titleVerticalCenter>
                            { product ? (
                                <ProductActionSetAmount
                                    product={ product }
                                    onClick={ () => setShowSetProductAmountModal(true) }
                                />
                            ) : <Skeleton width={50} height={34}/> }
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Leveranciers" titleVerticalCenter>
                            { product ? (
                                <React.Fragment>
                                    { product.suppliers && product.suppliers.length > 0 ? (
                                        product.suppliers.map((supplier, index) => (
                                            <TagPill key={ index } color={ supplier.color } className="mr-1">
                                                { supplier.name }
                                            </TagPill>
                                        ))
                                    ) : (
                                        <i className="text-muted">Niet ingesteld</i>
                                    )}
                                    <OverlayTrigger overlay={
                                        <Tooltip id="suppliers">Leveranciers aanpassen</Tooltip>
                                    }>
                                        <Button variant="link" size="sm" className="py-0" onClick={() => setShowSetSuppliersModal(true)}>
                                            <i className="fas fa-pen"/>
                                        </Button>
                                    </OverlayTrigger>
                                </React.Fragment>
                            ) : <Skeleton width={ 150 }/> }
                        </DetailOverviewRow>
                        <DetailOverviewRow title="Bestelsignalen">
                            { product ? (
                                <div className="d-flex flex-row align-items-center">
                                    { (product.preferredMinAmount !== null && product.safeDeliveryTimeDays !== null) ? (
                                        <React.Fragment>
                                            Minimale voorraad: <NumberFormatter number={ product.preferredMinAmount }/> stuks
                                            <br/>
                                            Veilige levertijd: <NumberFormatter number={ product.safeDeliveryTimeDays }/> dagen
                                        </React.Fragment>
                                    ) : (
                                        <i className="text-muted">Niet ingesteld</i>
                                    )}
                                    <OverlayTrigger overlay={
                                        <Tooltip id="safeInventorySettings">Bestelsignalen instellen</Tooltip>
                                    }>
                                        <Button variant="link" size="sm" className="py-0 ml-3" onClick={() => setShowSetSafeInventorySettingsModal(true)}>
                                            <i className="fas fa-pen"/>
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                            ) : <Skeleton width={200}/> }
                        </DetailOverviewRow>
                        </tbody>
                    </table>
                </div>
            </div>
        </React.Fragment>
    )
}

export default React.memo(ProductDetailOverview);

import React from "react";
import moment from "moment";
import {
    Button,
    Form,
    Spinner
} from "react-bootstrap";
import {
    electronicFormatIBAN,
    isValidIBAN
} from "ibantools";
import DatePicker from "react-datepicker";

import isValidBSN from "../../../components/formatters/BSNValidate";
import inputDecimalParser from "../../../components/formatters/InputDecimalParser";

function VATRefundForm({ setFormField, formFields, onSave, saving, add = false }) {
    let vatNumberBPart = "";
    if(formFields.vatNumber) {
        const split = formFields.vatNumber.split("B");
        if(split.length === 2) {
            vatNumberBPart = split[1];
        }
    }

    return (
        <React.Fragment>
            <div className="card mb-3">
                <div className="card-body">
                    <h4>Persoonlijke informatie</h4>
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label htmlFor="initials">Initialen</label>
                                <input type="text" className="form-control" id="name" required
                                       value={formFields.initials} onChange={ (event) => setFormField({ initials: event.target.value.toUpperCase().replace(".", "") })}
                                />
                            </div>
                        </div>
                        <div className="col-sm-8">
                            <div className="form-group">
                                <label htmlFor="lastName">Achternaam</label>
                                <input type="text" className="form-control" id="lastName" required
                                       value={formFields.lastName} onChange={ (event) => setFormField({ lastName: event.target.value })}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-group mb-0">
                        <label htmlFor="birthday">Geboortedatum</label>
                        <input type="date" className="form-control" id="birthday" required
                               value={formFields.birthday} onChange={ (event) => setFormField({ birthday: event.target.value })}
                        />
                    </div>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <h4>Contact</h4>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input type="email" className="form-control" id="email" required
                               value={formFields.email} onChange={ (event) => setFormField({ email: event.target.value })}
                        />
                    </div>

                    <div className="form-group mb-0">
                        <label htmlFor="phoneNumber">Telefoonnummer</label>
                        <input type="text" className="form-control" id="phoneNumber" required
                               value={formFields.phoneNumber}
                               onChange={ (event) => {
                                   setFormField({ phoneNumber: event.target.value.replace(/[a-zA-Z]*/g,"") });
                               }}
                        />
                    </div>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <h4>Adres</h4>
                    <div className="row align-items-end">
                        <div className="col-sm-8">
                            <div className="form-group">
                                <label htmlFor="street">Straat</label>
                                <input type="text" className="form-control" id="street" required
                                       value={formFields.street} onChange={ (event) => setFormField({ street: event.target.value })}
                                />
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="form-group">
                                <label htmlFor="houseNumber">Huisnummer + Toevoeging</label>
                                <input type="text" className="form-control" id="houseNumber" required
                                       value={formFields.houseNumber} onChange={ (event) => setFormField({ houseNumber: event.target.value })}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-6">
                            <div className="form-group mb-0">
                                <label htmlFor="postalCode">Postcode</label>
                                <input type="text" className="form-control" id="postalCode" required
                                       value={formFields.postalCode} onChange={ (event) => setFormField({ postalCode: event.target.value })}
                                />
                            </div>
                        </div>

                        <div className="col-sm-6">
                            <div className="form-group mb-0">
                                <label htmlFor="city">Stad</label>
                                <input type="text" className="form-control" id="city" required
                                       value={formFields.city} onChange={ (event) => setFormField({ city: event.target.value })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <h4>Kwartaal</h4>

                    <Form.Check
                        custom
                        type="checkbox"
                        id="emptyQuarterDate"
                        label="Leeg"
                        checked={ formFields.emptyQuarterDate }
                        onChange={ (event) => setFormField({ emptyQuarterDate: event.target.checked })}
                    />

                    <div className="form-group mb-0 mt-3" style={{ maxWidth: "295px" }}>
                        <label htmlFor="quarter">Kwartaal</label>
                        <DatePicker
                            selected={moment(formFields.quarter || new Date()).toDate()}
                            onChange={(date) => setFormField({ quarter: moment(date).format("YYYY-MM-DD") })}
                            dateFormat="QQQ yyyy"
                            showQuarterYearPicker
                            disabled={ formFields.emptyQuarterDate }
                        />
                    </div>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <h4>Details</h4>

                    <div className="form-group">
                        <label htmlFor="entrepreneur">Ondernemer</label>
                        <select className="form-control" id="entrepreneur" required
                                value={formFields.entrepreneur === null ? -1 : (formFields.entrepreneur ? 1 : 0)}
                                onChange={ (event) => {
                                    setFormField({ entrepreneur: parseInt(event.target.value) === 1 });
                                }}
                        >
                            { formFields.entrepreneur === null && (
                                <option value={-1}></option>
                            )}
                            <option value={0}>Nee</option>
                            <option value={1}>Ja</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="bsn">BSN</label>
                        <input type="text" className="form-control" id="bsn" required
                               value={formFields.bsn}
                               onChange={ (event) => setFormField({ bsn: event.target.value.replace(/[^0-9]/g, "") })}
                        />
                        { !formFields.bsn || formFields.bsn.length !== 9 ? (
                            <small className="form-text text-danger">
                                <i className="fas fa-times mr-2"/>
                                BSN moet 9 cijfers lang zijn
                            </small>
                        ) : isValidBSN(formFields.bsn) ? (
                            <small className="form-text text-success">
                                <i className="fas fa-check mr-2"/>
                                BSN geldig
                            </small>
                        ) : (
                            <small className="form-text text-warning">
                                <i className="fas fa-exclamation-triangle mr-2"/>
                                BSN mogelijk niet geldig
                            </small>
                        )}
                    </div>

                    <label htmlFor="vatNumber">BTW nummer</label>
                    <div className="input-group mb-3">
                        { isValidBSN(formFields.bsn) && (
                            <div className="input-group-prepend">
                                <div className="input-group-text">{ formFields.bsn }B</div>
                            </div>
                        )}
                        <input type="text" className="form-control" id="vatNumber" required
                               value={ vatNumberBPart }
                               onChange={ (event) => setFormField({ vatNumber: formFields.bsn + "B" + event.target.value })}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="iban">IBAN</label>
                        <input type="text" className="form-control" id="iban" required
                               value={formFields.iban} onChange={ (event) => setFormField({ iban: event.target.value })}
                        />
                        { isValidIBAN(electronicFormatIBAN(formFields.iban)) ? (
                            <small className="form-text text-success">
                                <i className="fas fa-check mr-2"/>
                                IBAN geldig
                            </small>
                        ) : (
                            <small className="form-text text-warning">
                                <i className="fas fa-exclamation-triangle mr-2"/>
                                IBAN mogelijk niet geldig
                            </small>
                        )}
                    </div>

                    <div className="form-group mb-0">
                        <label htmlFor="ibanName">IBAN naam</label>
                        <input type="text" className="form-control" id="ibanName" required
                               value={formFields.ibanName} onChange={ (event) => setFormField({ ibanName: event.target.value })}
                        />
                    </div>

                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <h4>Factuur</h4>

                    <Form.Check
                        custom
                        type="checkbox"
                        id="emptyInvoiceDate"
                        label="Leeg"
                        checked={ formFields.emptyInvoiceDate }
                        onChange={ (event) => setFormField({ emptyInvoiceDate: event.target.checked })}
                    />

                    <div className="form-group">
                        <label htmlFor="invoiceDate" className="mb-0">Factuurdatum</label>
                        <input
                            type="date" className="form-control" id="invoiceDate" required
                            disabled={ formFields.emptyInvoiceDate }
                            value={ formFields.invoiceDate === null ? "" : formFields.invoiceDate }
                            onChange={ (event) => setFormField({ invoiceDate: event.target.value })}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="invoiceVATPrice">Factuur BTW bedrag</label>
                        <input
                            type="number" className="form-control" id="invoiceVATPrice" required
                            disabled={ formFields.emptyInvoiceDate }
                            value={ formFields.invoiceVATPrice === null ? "" : formFields.invoiceVATPrice }
                            onChange={ (event) => setFormField({ invoiceVATPrice: inputDecimalParser(event) })}
                        />
                    </div>

                    <div className="form-group mb-0">
                        <label htmlFor="wp">Installatie vermogen (Wp)</label>
                        <input
                            type="number" className="form-control" id="wp" required
                            value={formFields.wp}
                            onChange={ (event) => setFormField({ wp: parseInt(event.target.value) })}
                        />
                    </div>
                </div>
            </div>

            <Button variant="primary" className="float-right" onClick={ onSave } disabled={ saving }>
                { saving && (
                    <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                )}
                Opslaan
            </Button>
        </React.Fragment>
    )
}

export default React.memo(VATRefundForm);

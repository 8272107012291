import React, {
    useMemo
} from "react";

function FileIcon({ path, className }) {
    const icon = useMemo(() => {
        const extension = path.split(".").pop().toLowerCase();
        switch(extension) {
            case "xls":
            case "xlsx":
                return "fas fa-file-excel";
            case "doc":
            case "docx":
                return "fas fa-file-word";

            case "pdf":
                return "fas fa-file-pdf";

            default:
                return "fas fa-file";
        }
    }, [path]);

    return (
        <i className={ icon + (className ? ` ${className}` : "") }/>
    );
}

export default React.memo(FileIcon);

import React from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Table
} from "react-bootstrap";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";

import {
    withAuthenticatedUserContext
} from "../../context/AuthenticatedUserContext";
import Helmet from "../../components/Helmet";
import TagPill from "../../components/tagPill";
import Loading from "../../components/Loading";
import DateFormatter from "../../components/formatters/DateFormatter";
import Page404 from "../Page404";

class UsersPage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            users: null,
            error: null
        }
    }

    componentDidMount() {
        this.getUsers();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.authenticatedUserContext.user !== this.props.authenticatedUserContext.user) {
            this.getUsers();
        }
        if(prevProps.match.params.filter !== this.props.match.params.filter) {
            this.getUsers();
        }
    }

    getCurrentFilter() {
        const filter = this.props.match.params.filter;
        switch(filter) {
            case "disabled": return "disabled";
            default: return "enabled";
        }
    }

    getUsers() {
        const {
            authenticatedUserContext
        } = this.props;
        if(!authenticatedUserContext.user || !authenticatedUserContext.user.admin) {
            return;
        }
        this.setState({ users: null, error: null });
        axios.get("/getUsers")
            .then((response) => {
                if(response.data.valid) {
                    const filter = this.getCurrentFilter();
                    const users = response.data.data.filter((user) => {
                        switch(filter) {
                            case "disabled": return !user.enabled;
                            case "enabled": return user.enabled;
                            default: return true;
                        }
                    }).sort((user1, user2) => {
                        if(user1.intranet > user2.intranet) return -1;
                        if(user1.intranet < user2.intranet) return 1;
                        if(user1.name > user2.name) return 1;
                        if(user1.name < user2.name) return -1;
                        return 0;
                    });
                    this.setState({ users });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            })
    }

    render() {
        const {
            users,
            error
        } = this.state;
        const {
            authenticatedUserContext
        } = this.props;
        if(!authenticatedUserContext.user || !authenticatedUserContext.user.admin) {
            return (
                <Page404/>
            );
        }
        return (
            <React.Fragment>
                <Helmet title="Gebruikers"/>

                <Title preTitle="Overzicht" noBottom>
                    Gebruikers
                    <small className="ml-2">
                        <TagPill>
                            { users ? users.length : 0 }
                        </TagPill>
                    </small>
                </Title>

                <TabBar noBottom>
                    <TabBarItem to="/users/enabled">
                        Actief
                    </TabBarItem>
                    <TabBarItem to="/users/disabled">
                        Inactief
                    </TabBarItem>
                </TabBar>

                <Table hover striped size="sm">
                    <thead className="thead-light">
                    <tr className="tr-sticky">
                        <th>#</th>
                        <th>Naam</th>
                        <th>Laatst actief</th>
                        <th>Laatst ingelogd</th>
                        <th>Type</th>
                        <th>Actief</th>
                    </tr>
                    </thead>
                    <tbody>
                    { error ? (
                        <tr>
                            <td colSpan={ 6 }>
                                <Alert variant="danger">{ error }</Alert>
                            </td>
                        </tr>
                    ) : !users ? (
                        <tr>
                            <td colSpan={ 6 }>
                                <Loading/>
                            </td>
                        </tr>
                    ) : users.map((user) => {
                        const url = "/user/" + user.id;
                        return (
                            <tr className="table-clickable-row" key={ user.id }>
                                <th className="align-middle">
                                    <Link to={ url }>
                                        { user.id }
                                    </Link>
                                </th>
                                <td className="align-middle">
                                    <Link to={ url }>
                                        { user.name }
                                    </Link>
                                </td>
                                <td className="align-middle">
                                    <Link to={ url }>
                                        { user.lastActivity ? (
                                            <DateFormatter date={ user.lastActivity }/>
                                        ) : (
                                            <i className="text-muted">Nooit</i>
                                        )}
                                    </Link>
                                </td>
                                <td className="align-middle">
                                    <Link to={ url }>
                                        { user.lastLogin ? (
                                            <DateFormatter date={ user.lastLogin }/>
                                        ) : (
                                            <i className="text-muted">Nooit</i>
                                        )}
                                    </Link>
                                </td>
                                <td className="align-middle">
                                    <Link to={ url }>
                                        { user.intranet ? (
                                            "Kantoor"
                                        ) : (
                                            "Monteur"
                                        )}
                                    </Link>
                                </td>
                                <td className="align-middle">
                                    <Link to={ url }>
                                        { user.enabled ? (
                                            <i className="fas fa-check fa-fw text-success"/>
                                        ) : (
                                            <i className="fas fa-times fa-fw text-danger"/>
                                        )}
                                    </Link>
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </Table>
            </React.Fragment>
        )
    }
}

export default withAuthenticatedUserContext(UsersPage);

import React from "react";

import ChartCard from "../components/ChartCard";

import OutagesCreatedCountWeeklyChart from "../charts/OutagesCreatedCountWeeklyChart";

function OutageStatistics({ startDate, endDate }) {
    return (
        <React.Fragment>
            <ChartCard title="Aantal storingen aangemaakt per week">
                <OutagesCreatedCountWeeklyChart
                    startDate={ startDate }
                    endDate={ endDate }
                />
            </ChartCard>
        </React.Fragment>
    )
}

export default React.memo(OutageStatistics);

import React from "react";
import axios from "axios";
import {
    Alert,
    Button,
    Form,
    Modal,
    Spinner
} from "react-bootstrap";

class SetProductAmountModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            amount: null,
            remark: "",
            saving: false,
            error: null
        }
        this.onSave = this.onSave.bind(this);
        this.onShow = this.onShow.bind(this);
    }

    onShow() {
        const amount = this.props.product.amount;
        this.setState({ amount, remark: "", error: null, loading: false });
    }

    onSave() {
        const {
            remark
        } = this.state;
        const amount = parseInt(this.state.amount);
        if(isNaN(amount) || amount % 1 !== 0 || amount < 0) {
            this.setState({ error: "Ongeldig aantal. Vul een positief nummer zonder decimalen in." });
            return;
        }
        if(!remark || remark.length <= 10) {
            this.setState({ error: "Vul een opmerking in die minimaal 10 karakters lang is." });
            return;
        }
        this.setState({ error: null, saving: true });
        axios.post("/setInventoryProductAmount", {
            productId: this.props.product.id,
            amount,
            remark
        })
            .then((response) => {
                if(response.data.valid) {
                    if(this.props.setProduct) {
                        this.props.setProduct(response.data.product);
                    }
                    this.props.handleClose();
                } else {
                    this.setState({ error: "Er ging iets fout bij het opslaan van de locatie. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout bij het opslaan van de locatie." });
            })
            .finally(() => {
                this.setState({ saving: false });
            });
    }

    render() {
        const {
            amount,
            remark,
            saving,
            error
        } = this.state;
        const {
            show,
            handleClose,
            product
        } = this.props;
        return (
            <Modal
                show={ show }
                onHide={ handleClose }
                onShow={ this.onShow }
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Product aantal handmatig veranderen<br/>
                        <small>{ product != null ? product.brand.name + " " + product.name : "" }</small>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { error && (
                        <Alert variant="danger">{ error }</Alert>
                    )}
                    <Form.Group controlId="amount">
                        <Form.Label>Aantal</Form.Label>
                        <Form.Control
                            type="number"
                            value={ amount }
                            disabled={ saving }
                            onChange={ (event) => this.setState({ amount: event.target.value })}
                        />
                    </Form.Group>
                    <Form.Group controlId="amount" className="mb-0">
                        <Form.Label>Waarom verander je het aantal?</Form.Label>
                        <Form.Control
                            as="textarea"
                            value={ remark }
                            rows={ 4 }
                            disabled={ saving }
                            onChange={ (event) => this.setState({ remark: event.target.value })}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={ handleClose } disabled={ saving }>
                        Annuleer
                    </Button>
                    <Button variant="primary" onClick={ this.onSave } disabled={ saving }>
                        { saving && (
                            <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                        )}
                        Opslaan
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default SetProductAmountModal;

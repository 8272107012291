import React from "react";
import axios from "axios";
import {
    Link
} from "react-router-dom";
import {
    Alert,
    Button,
    Modal, Table
} from "react-bootstrap";

import Loading from "../../../../components/Loading";
import DistanceFormatter from "../../../../components/formatters/DistanceFormatter";
import TagPill from "../../../../components/tagPill";

class LeadDetailNearLeadsModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            leads: null,
            distance: null, // in km
            error: null
        }
        this.onShow = this.onShow.bind(this);
    }

    onShow() {
        this.getNearLeads();
    }

    getNearLeads() {
        this.setState({ leads: null, error: null });
        axios.post("/getNearLeads", {
            leadId: this.props.lead.id
        })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({
                        leads: response.data.data,
                        distance: response.data.distance
                    });
                } else {
                    this.setState({ error: "Er ging iets fout bij het ophalen van de leads. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                this.setState({ error: "Er ging iets fout bij het ophalen van de leads." });
                console.error(error);
            });
    }

    render() {
        const {
            show,
            handleClose
        } = this.props;
        const {
            leads,
            distance,
            error
        } = this.state;
        return (
            <React.Fragment>
                <Modal size="xl" show={ show } onHide={ handleClose } onShow={ this.onShow }>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Leads in de buurt
                            { distance !== null && (
                                <small className="text-muted ml-2">
                                    ({ Math.ceil(distance * 1000) } m)
                                </small>
                            )}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-0">
                        { error !== null ? (
                            <div className="p-2">
                                <Alert variant="danger" className="m-0">{ error }</Alert>
                            </div>
                        ) : !leads ? (
                            <div className="p-2">
                                <Loading/>
                            </div>
                        ) : (
                            <Table hover size="sm" className="mb-0">
                                <thead className="thead-light">
                                <tr className="tr-sticky">
                                    <th className="text-center">#</th>
                                    <th className="text-right">Afstand</th>
                                    <th>Status</th>
                                    <th>Naam</th>
                                    <th>Adres</th>
                                </tr>
                                </thead>
                                <tbody>
                                { leads.map((lead) => {
                                    const url = `/lead/${lead.id}`;
                                    return (
                                        <tr className="table-clickable-row" key={ lead.id }>
                                            <td className="align-middle text-center">
                                                <Link to={ url }>
                                                    <b>{ lead.id }</b>
                                                </Link>
                                            </td>
                                            <td className="align-middle text-right">
                                                <Link to={ url }>
                                                    <DistanceFormatter distance={ lead.distance }/>
                                                </Link>
                                            </td>
                                            <td className="align-middle">
                                                <Link to={ url }>
                                                    <TagPill color={ lead.status.color }>
                                                        { lead.status.name }
                                                    </TagPill>
                                                </Link>
                                            </td>
                                            <td className="align-middle">
                                                <Link to={ url }>
                                                    { lead.name }
                                                </Link>
                                            </td>
                                            <td className="align-middle">
                                                <Link to={ url }>
                                                    { lead.street + " " + lead.houseNumber + ", " + lead.city }
                                                </Link>
                                            </td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </Table>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={ handleClose }>
                            Sluiten
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}

export default LeadDetailNearLeadsModal;

import axios from "axios";

import DetailSetStatusModal from "../../../../components/detail/modal/DetailSetStatusModal";

class OutageDetailSetStatusModal extends DetailSetStatusModal {
    getInitialStatus() {
        return this.props.outage.status.id;
    }

    shouldEnterReason() {
        if(!this.state.statuses) {
            return false;
        }
        const selectedStatus = this.state.statuses.find((status) => {
            return status.id === parseInt(this.state.selectedStatus);
        });
        return this.props.outage.status.id !== 7 && selectedStatus?.id === 7;
    }

    getStatuses() {
        this.setState({ statuses: null, error: null });
        axios.get("/getOutageStatuses")
            .then((response) => {
                if(response.data.valid) {
                    let statuses = response.data.data;
                    statuses.sort((status1, status2) => {
                        if(status1.priority > status2.priority) return -1;
                        if(status1.priority < status2.priority) return 1;
                        return 0;
                    });
                    this.setState({ statuses });
                } else {
                    this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ error: "Er ging iets fout. Probeer het later opnieuw." });
            });
    }

    setStatus(onSuccess, onError) {
        axios.post("/setOutageStatus", { outageId: this.props.outage.id, statusId: this.state.selectedStatus })
            .then((response) => {
                if(response.data.valid) {
                    onSuccess(() => {
                        this.props.handleStatusChanged(response.data.status);
                    });
                } else {
                    onError("Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((error) => {
                console.error(error);
                onError("Er ging iets fout. Probeer het later opnieuw.");
            });
    }

    addReasonComment(onSuccess, onError) {
        const selectedStatus = this.state.statuses.find((status) => {
            return status.id === parseInt(this.state.selectedStatus);
        });
        const name = selectedStatus?.name ?? "verlopen";
        const message = "## Reden voor " + name + "\n" + this.state.reason;
        axios.post("/addOutageComment", { outage: this.props.outage.id, message })
            .then((response) => {
                if(response.data.valid) {
                    onSuccess(() => {
                        this.props.handleCommentAdded();
                    });
                } else {
                    onError("Er ging iets fout. Probeer het later opnieuw. (" + response.data.error + ")");
                }
            })
            .catch((error) => {
                console.error(error);
                onError("Er ging iets fout. Probeer het later opnieuw.");
            });
    }
}

export default OutageDetailSetStatusModal;

import React from "react";

function DistanceFormatter({ distance }) {
    if(distance < 1) {
        return Math.ceil(distance * 1000) + " m";
    }
    return (Math.round(distance * 100) / 100).toFixed(2) + " km";
}

export default React.memo(DistanceFormatter);

import React, {
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import moment from "moment";
import {
    Redirect,
    Route,
    Switch
} from "react-router-dom";
import {
    TabBar,
    TabBarItem,
    Title
} from "@zandor300/backoffice-framework";

import Helmet from "../../components/Helmet";
import StatisticsDateRangePicker from "./components/StatisticsDateRangePicker";
import AuthenticatedUserContext from "../../context/AuthenticatedUserContext";

import LeadStatistics from "./tabs/LeadStatistics";
import LeadsExpiredStatistics from "./tabs/LeadsExpiredStatistics";
import SalesStatistics from "./tabs/SalesStatistics";
import OutageStatistics from "./tabs/OutageStatistics";
import ReminderStatistics from "./tabs/ReminderStatistics";
import InstallationsStatistics from "./tabs/InstallationsStatistics";

const defaultStartDate = moment().subtract(30, "days");
const defaultEndDate = moment();

function Statistics({ match, history }) {
    const authenticatedUser = useContext(AuthenticatedUserContext)

    const [startDate, setStartDate] = useState(match.params.startDate ? moment(match.params.startDate) : defaultStartDate);
    const [endDate, setEndDate] = useState(match.params.endDate ? moment(match.params.endDate) : defaultEndDate);

    const [focusedInput, setFocusedInput] = useState(null);

    useEffect(() => {
        setStartDate(match.params.startDate ? moment(match.params.startDate) : defaultStartDate);
        setEndDate(match.params.endDate ? moment(match.params.endDate) : defaultEndDate);
    }, [match.params.startDate, match.params.endDate]);

    const onDatesChange = useMemo(() => {
        return ({ startDate, endDate }) => {
            const startDateUrl = startDate ? startDate.format("YYYY-MM-DD") : "select";
            const endDateUrl = endDate ? endDate.format("YYYY-MM-DD") : "select";

            history.push(match.path.replace(":startDate", startDateUrl).replace(":endDate", endDateUrl));
        }
    }, [history, match.path]);
    const onFocusChange = useMemo(() => {
        return (focusedInput) => {
            setFocusedInput(focusedInput);
            if(focusedInput === "endDate") {
                setEndDate(null);
            }
        }
    }, []);

    const urlPrefix = match.url;

    const childProps = {
        startDate,
        endDate
    }

    return (
        <React.Fragment>
            <Helmet title="Statistieken"/>

            <Title
                preTitle="Overzicht"
                noBottom
                preChildren={
                    <div className="float-right mt-2">
                        <StatisticsDateRangePicker
                            startDate={ startDate }
                            endDate={ endDate }
                            focusedInput={ focusedInput }
                            onDatesChange={ onDatesChange }
                            onFocusChange={ onFocusChange }
                        />
                    </div>
                }
            >
                Statistieken
            </Title>

            <TabBar>
                <TabBarItem to={ urlPrefix + "/leads" }>
                    Leads
                </TabBarItem>
                { authenticatedUser.user?.admin && (
                    <TabBarItem to={ urlPrefix + "/expired-leads" }>
                        Verlopen leads
                    </TabBarItem>
                )}
                <TabBarItem to={ urlPrefix + "/sales" }>
                    Sales
                </TabBarItem>
                <TabBarItem to={ urlPrefix + "/outages" }>
                    Storingen
                </TabBarItem>
                <TabBarItem to={ urlPrefix + "/reminders" }>
                    Herinneringen
                </TabBarItem>
                <TabBarItem to={ urlPrefix + "/installations" }>
                    Installaties
                </TabBarItem>
            </TabBar>

            { !startDate && !endDate ? (
                <p>Selecteer start en einddatum</p>
            ) : !startDate ? (
                <p>Selecteer startdatum</p>
            ) : !endDate ? (
                <p>Selecteer einddatum</p>
            ) : (
                <Switch>
                    <Route path={ urlPrefix + "/leads" } exact>
                        <LeadStatistics { ...childProps }/>
                    </Route>
                    { authenticatedUser.user?.admin && (
                        <Route path={ urlPrefix + "/expired-leads" } exact>
                            <LeadsExpiredStatistics { ...childProps }/>
                        </Route>
                    )}
                    <Route path={ urlPrefix + "/sales" } exact>
                        <SalesStatistics { ...childProps }/>
                    </Route>
                    <Route path={ urlPrefix + "/outages" } exact>
                        <OutageStatistics { ...childProps }/>
                    </Route>
                    <Route path={ urlPrefix + "/reminders" } exact>
                        <ReminderStatistics { ...childProps }/>
                    </Route>
                    <Route path={ urlPrefix + "/installations" } exact>
                        <InstallationsStatistics { ...childProps }/>
                    </Route>
                    <Route path={ urlPrefix } exact>
                        <Redirect
                            to={{
                                pathname: urlPrefix + "/leads",
                                state: { from: urlPrefix }
                            }}
                        />
                    </Route>
                </Switch>
            )}
        </React.Fragment>
    )
}

export default React.memo(Statistics);

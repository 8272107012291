import React, {
    useEffect,
    useMemo,
    useState
} from "react";
import moment from "moment";
import axios from "axios";
import {
    Alert,
    Button,
    Modal,
    Spinner
} from "react-bootstrap";
import fileDownload from "js-file-download";

function ModeButton({ currentMode, mode, title, onClick }) {
    return (
        <Button
            variant={ currentMode === mode ? "primary" : "outline-secondary" }
            size="sm"
            className="mr-2 rounded-pill"
            onClick={ () => onClick(mode) }
        >
            { title }
        </Button>
    )
}

function ExportProductsModal({ show, handleClose }) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [mode, setMode] = useState("current"); // current / past
    const [date, setDate] = useState(moment().format("YYYY-MM-DD"));

    useEffect(() => {
        if(mode === "current") {
            setDate(moment().format("YYYY-MM-DD"));
        }
    }, [mode]);

    const onSubmit = useMemo(() => {
        return () => {
            setLoading(true);
            const dateMoment = moment(date);
            const selectedDate = mode === "past" ? dateMoment.format("YYYY-MM-DD") : null;
            axios.post("/exportProducts", { date: selectedDate }, { responseType: "blob" })
                .then((response) => {
                    let fileNameBuilder = ["Producten"];
                    switch(mode) {
                        case "past":
                            fileNameBuilder.push("Verleden " + dateMoment.format("YYYYMMDD"));
                            break;
                        default:
                            fileNameBuilder.push(moment().format("YYYYMMDD HHmmss"));
                            break;
                    }
                    fileDownload(
                        response.data,
                        fileNameBuilder.join(" - ") + ".xlsx"
                    );
                    handleClose();
                })
                .catch((error) => {
                    console.error(error);
                    setError("Er ging iets fout. Probeer het later opnieuw.");
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [date, mode, handleClose]);

    return (
        <Modal show={ show } onHide={ handleClose }>
            <Modal.Header closeButton>
                <Modal.Title>Producten export</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { error && (
                    <Alert variant="danger">{ error }</Alert>
                )}
                <div>
                    <div className="mb-2">Modus</div>
                    <ModeButton
                        currentMode={ mode }
                        mode="current"
                        title="Huidig"
                        onClick={ setMode }
                    />
                    <ModeButton
                        currentMode={ mode }
                        mode="past"
                        title="Verleden"
                        onClick={ setMode }
                    />
                </div>
                <div className="form-group mt-3 mb-0">
                    <label htmlFor="date">Datum</label>
                    <input
                        type="date"
                        className="form-control"
                        id="date"
                        value={ date }
                        disabled={ mode !== "past" }
                        onChange={ (event) => setDate(event.target.value) }
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={ handleClose } disabled={ loading }>
                    Annuleer
                </Button>
                <Button variant="primary" onClick={ onSubmit } disabled={ loading }>
                    { loading && (
                        <Spinner animation="border" variant="light" size="sm" className="mr-2"/>
                    )}
                    Export
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default React.memo(ExportProductsModal);

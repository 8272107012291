import React from "react";
import axios from "axios";
import {
    Button,
    FormControl,
    InputGroup,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import {
    withInventoryContext
} from "../../../../context/InventoryManager";

class ProductQuickActionSetAssignedBuilding extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            editing: false,
            assignedBuildingId: 0,
            saving: false
        }
        this.onSave = this.onSave.bind(this);
        this.onEditStart = this.onEditStart.bind(this);
        this.onEditCancel = this.onEditCancel.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onSave() {
        this.setState({ saving: true });
        this.props.onError(null);
        axios.post("/setInventoryProductAssignedBuilding", {
            productId: this.props.product.id,
            buildingId: parseInt(this.state.assignedBuildingId)
        })
            .then((response) => {
                if(response.data.valid) {
                    this.props.setProduct(response.data.product);
                    this.setState({ editing: false });
                } else {
                    this.props.onError("Er ging iets fout bij het opslaan van de locatie. (" + response.data.error + ")");
                }
            })
            .catch((error) => {
                this.props.onError("Er ging iets fout bij het opslaan van de locatie.");
                console.error(error);
            })
            .finally(() => {
                this.setState({ saving: false });
            })
    }

    onEditStart() {
        const product = this.props.product;
        this.setState({
            editing: true,
            assignedBuildingId: product.assignedBuilding ? product.assignedBuilding.id : 0
        });
        this.props.inventoryContext.refreshBuildingsIfNeeded();
    }

    onEditCancel() {
        this.setState({ editing: false });
        this.props.onError(null);
    }

    onChange(event) {
        this.setState({ assignedBuildingId: event.target.value });
    }

    render() {
        const product = this.props.product;
        const buildings = this.props.inventoryContext.buildings ? this.props.inventoryContext.buildings : [];
        return (
            <React.Fragment>
                <span className="d-none d-print-block">
                    { product.assignedBuilding ? (
                        product.assignedBuilding.name
                    ) : (
                        <i className="text-muted">Geen</i>
                    ) }
                </span>
                <div className="d-print-none">
                    { !this.state.editing ? (
                        <OverlayTrigger overlay={
                            <Tooltip id="edit">Locatie wijzigen</Tooltip>
                        }>
                            <Button
                                variant="editable-blue"
                                onClick={ this.onEditStart }
                                className="py-1 px-2 text-left"
                                style={{ whiteSpace: "nowrap" }}
                            >
                                { product.assignedBuilding ? (
                                    product.assignedBuilding.name
                                ) : (
                                    <i className="text-muted">Geen</i>
                                ) }
                            </Button>
                        </OverlayTrigger>
                    ) : (
                        <InputGroup style={{ width: "300px" }} className="mb-0">
                            <FormControl
                                as="select"
                                value={ this.state.assignedBuildingId }
                                onChange={ this.onChange }
                                disabled={ this.state.saving || !this.props.inventoryContext.buildings }
                            >
                                <option value={ 0 }>Geen</option>
                                { buildings.map((building) => (
                                    <option value={ building.id } key={ building.id }>{ building.name }</option>
                                ))}
                            </FormControl>
                            <InputGroup.Append>
                                <OverlayTrigger overlay={
                                    <Tooltip id="save">Opslaan</Tooltip>
                                }>
                                    <Button variant="primary" onClick={ this.onSave } disabled={ this.state.saving || !this.props.inventoryContext.buildings }>
                                        <i className="fas fa-save"/>
                                    </Button>
                                </OverlayTrigger>
                                <OverlayTrigger overlay={
                                    <Tooltip id="cancel">Annuleer</Tooltip>
                                }>
                                    <Button variant="secondary" onClick={ this.onEditCancel } disabled={ this.state.saving || !this.props.inventoryContext.buildings }>
                                        <i className="fas fa-trash-alt"/>
                                    </Button>
                                </OverlayTrigger>
                            </InputGroup.Append>
                        </InputGroup>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

export default withInventoryContext(ProductQuickActionSetAssignedBuilding);

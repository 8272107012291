import React from "react";
import axios from "axios";
import moment from "moment";
import {
    Alert
} from "react-bootstrap";
import Skeleton from "react-loading-skeleton";

import numberFormatter from "../../../components/formatters/NumberFormatter";
import DashboardCard from "../DashboardCard";

function DashboardPlanningWidgetWarningRow({ title, amount, loading }) {
    return (
        <div className={ "d-flex " + (amount === 0 ? "text-success" : "text-danger") }>
            <div className="flex-grow-1">
                { !loading ? title : (
                    <Skeleton width={ title.length * 8 }/>
                )}
            </div>
            <div className="ml-3">
                { !loading ? numberFormatter({ number: amount }) : (
                    <Skeleton width={ 30 }/>
                )}
            </div>
        </div>
    )
}

class DashboardWarningsWidget extends React.PureComponent {
    state = {
        data: null,
        error: null
    }

    componentDidMount() {
        this.getWarnings();
    }

    getWarnings() {
        this.setState({ data: null, error: null });
        let date = moment().format("YYYY-MM-DD");
        axios.post("/getDashboardWarnings", { startDate: date, endDate: date })
            .then((response) => {
                if(response.data.valid) {
                    this.setState({ data: response.data });
                } else {
                    this.setState({ data: null, error: "Er is een fout opgetreden. Probeer het later opnieuw." });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ data: null, error: "Er is een fout opgetreden. Probeer het later opnieuw." });
            });
    }

    render() {
        const {
            className,
            children,
            title,
            ...rest
        } = this.props;
        return (
            <DashboardCard { ...rest } border="danger" className={ className }>
                <DashboardCard.BodyRegular title={ title } loading={ !this.state.data }>
                    { this.state.error ? (
                        <Alert variant="danger">{ this.state.error }</Alert>
                    ) : (
                        <div className="h-100 d-flex align-items-center">
                            <div className="w-100">
                                <DashboardPlanningWidgetWarningRow
                                    title="Lead waarschuwingen"
                                    amount={ this.state.data ? this.state.data.leadWarnings : 0 }
                                    loading={ !this.state.data }
                                />
                                <DashboardPlanningWidgetWarningRow
                                    title="Storing waarschuwingen"
                                    amount={ this.state.data ? this.state.data.outageWarnings : 0 }
                                    loading={ !this.state.data }
                                />
                                <DashboardPlanningWidgetWarningRow
                                    title="Installatie waarschuwingen"
                                    amount={ this.state.data ? this.state.data.installationWarnings : 0 }
                                    loading={ !this.state.data }
                                />
                            </div>
                        </div>
                    )}
                </DashboardCard.BodyRegular>
                { children }
            </DashboardCard>
        )
    }
}

export default React.memo(DashboardWarningsWidget);

import React, {
    useMemo
} from "react";
import GitInfo from "react-git-info/macro";

function IntranetVersion() {
    const commitHash = useMemo(() => {
        return GitInfo().commit.hash;
    }, []);
    const commitHashShort = useMemo(() => {
        return GitInfo().commit.shortHash;
    }, []);

    const url = useMemo(() => {
        return "https://git.zsinfo.nl/JKSmithHolding/SolarIntranet/-/tree/" + commitHash;
    }, [commitHash]);

    return (
        <React.Fragment>
            Intranet { process.env.REACT_APP_VERSION || "0.0.0" }
            {" "}
            (<a href={ url } target="_blank" rel="noreferrer noopener">{ commitHashShort }</a>)
        </React.Fragment>
    )
}

export default React.memo(IntranetVersion);

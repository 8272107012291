import React from "react";
import {
    SidebarManager
} from "@zandor300/backoffice-framework";
import {
    IntranetSidebarManager
} from "./components/layout/sidebar/IntranetSidebarContext";
import {
    AppleMapsManager
} from "./components/apple-maps/AppleMapsManager";
import {
    InventoryManager
} from "./context/InventoryManager";
import {
    UsersManager
} from "./context/UsersContext";
import {
    CallMonitorManager
} from "./context/CallMonitorManager";

function IntranetManager({ children }) {
    return (
        <SidebarManager>
            <IntranetSidebarManager>
                <AppleMapsManager>
                    <UsersManager>
                        <InventoryManager>
                            <CallMonitorManager>
                                { children }
                            </CallMonitorManager>
                        </InventoryManager>
                    </UsersManager>
                </AppleMapsManager>
            </IntranetSidebarManager>
        </SidebarManager>
    )
}

export default IntranetManager;

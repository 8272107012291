/**
 * @param data string
 * @param value string|[string]
 */
export function searchMatch(data, value) {
    if(typeof value === "string") {
        return data.replace(/\s/g,"").toLowerCase().includes(value.toLowerCase());
    } else if(Array.isArray(value)) {
        return value.some((v) => {
            return data.replace(/\s/g,"").toLowerCase().includes(v.toLowerCase());
        });
    }
    return false;
}

import React from "react";
import moment from "moment";
import axios from "axios";
import {
    withRouter
} from "react-router-dom";

import {
    withUsersContext
} from "../../context/UsersContext";
import Helmet from "../../components/Helmet";
import Loading from "../../components/Loading";
import PlanningItemEditModal from "./modal/PlanningItemEditModal";
import PlanningDayCalendarRow from "./components/PlanningDayCalendarRow";
import PlanningDayCalendarAbsenceRow from "./components/PlanningDayCalendarAbsenceRow";
import DateRangeSwitcher from "../../components/calendar/DateRangeSwitcher";
import {
    withPlanningContext
} from "./context/PlanningContext";

class PlanningDayCalendar extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            date: null,

            planningItems: null,
            absenceItems: null,
            users: null,
            mechanicCount: null,
            error: null,

            showItemEditModal: false,
            editModalPlanningItem: null
        }
    }

    componentDidMount() {
        this.handleDateChange();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.match.path !== this.props.match.path || prevProps.match.params !== this.props.match.params) {
            this.handleDateChange();
        }
        if(prevProps.usersContext.users !== this.props.usersContext.users) {
            this.filterUsers();
        }
        if(!prevProps.planningContext.showAbsences && this.props.planningContext.showAbsences) {
            this.getAbsenceItems();
        }
    }

    handleDateChange() {
        const date = this.getDate();
        if(this.state.date === null || this.state.date.format("YYYY-MM-DD") !== date.format("YYYY-MM-DD")) {
            this.props.handleDateChange(date);
            this.setState({ date });
            this.getPlanningItems();
            if(this.props.planningContext.showAbsences) {
                this.getAbsenceItems();
            }
        }
    }

    getDate() {
        return this.props.match.params.date === undefined ? moment() : moment(this.props.match.params.date);
    }

    getPlanningItems() {
        this.setState({ planningItems: null, error: null });
        const date = this.getDate().format("YYYY-MM-DD");
        axios.post("/getPlanningItems", { startDate: date, endDate: date })
            .then((response) => {
                if(response.data.valid) {
                    const planningItems = response.data.data;
                    this.setState({ planningItems }, () => {
                        this.filterUsers();
                    });
                } else {
                    this.setState({ planningItems: null, error: "Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ planningItems: null, error: "Er is een fout opgetreden. Probeer het later opnieuw." });
            });
    }

    getAbsenceItems() {
        this.setState({ absenceItemsPerUser: null });
        const date = this.getDate().format("YYYY-MM-DD");
        axios.post("/getAbsenceItems", { startDate: date, endDate: date })
            .then((response) => {
                if(response.data.valid) {
                    let absenceItems = response.data.data;
                    absenceItems.sort((absenceItem1, absenceItem2) => {
                        if(absenceItem1.user.name > absenceItem2.user.name) return -1;
                        if(absenceItem1.user.name < absenceItem2.user.name) return 1;
                        return 0;
                    });
                    this.setState({ absenceItems });
                } else {
                    this.setState({ absenceItems: null, error: "Er is een fout opgetreden. Probeer het later opnieuw. (" + response.data.error + ")" });
                }
            })
            .catch((error) => {
                console.error(error);
                this.setState({ absenceItems: null, error: "Er is een fout opgetreden. Probeer het later opnieuw." });
            });
    }

    planningItemUpdated(planningItem) {
        this.setState((state) => {
            let planningItems = [...state.planningItems];
            const index = planningItems.findIndex(arrayPlanningItem => { return arrayPlanningItem.id === planningItem.id });
            planningItems[index] = planningItem;
            return { planningItems: planningItems, showItemEditModal: false };
        }, () => {
            this.filterUsers();
        });
    }

    planningItemDeleted() {
        this.getPlanningItems();
        this.setState({ showItemEditModal: false });
    }

    filterUsers() {
        const allUsers = this.props.usersContext.users;
        if(!allUsers) {
            this.setState({ users: allUsers, mechanicCount: null });
            return;
        }
        const planningItems = this.state.planningItems;
        if(!planningItems) {
            this.setState({ users: allUsers, mechanicCount: null });
            return;
        }
        let mechanicCount = 0;
        const filteredUsers = allUsers.filter((user) => {
            if(!user.intranet) {
                mechanicCount++;
                return true;
            }
            for(const planningItem of planningItems) {
                if(planningItem.assignedUsers && planningItem.assignedUsers.findIndex((assignedUser) => assignedUser.id === user.id) !== -1) {
                    return true;
                }
            }
            return false;
        }).sort((user1, user2) => {
            if(user1.intranet && !user2.intranet) return 1;
            if(!user1.intranet && user2.intranet) return -1;
            if(user1.name > user2.name) return 1;
            if(user1.name < user2.name) return -1;
            return 0;
        })
        this.setState({ users: filteredUsers, mechanicCount });
    }

    getWarningUserIds() {
        const planningItems = this.state.planningItems;
        const users = this.props.usersContext.users;
        if(!users || !planningItems) {
            return [];
        }
        let foundUserIds = [];
        planningItems.forEach((planningItem) => {
            planningItem.assignedUsers.forEach((user) => {
                if(!foundUserIds.includes(user.id)) {
                    foundUserIds.push(user.id);
                }
            });
        });
        return users.filter((user) => {
            return !foundUserIds.includes(user.id);
        }).map((user) => user.id);
    }

    render() {
        const date = this.getDate();
        const previousDate = date.clone().subtract(1, "day");
        const nextDate = date.clone().add(1, "day");

        const baseUrl = "/planning/day";
        const currentText = date.format("dddd DD MMMM YYYY");

        const warningUserIds = this.getWarningUserIds();

        const { planningItems, users, mechanicCount } = this.state;

        return (
            <React.Fragment>
                <Helmet title={ "Planning - " + currentText }/>

                <PlanningItemEditModal
                    show={ this.state.showItemEditModal }
                    planningItem={ this.state.editModalPlanningItem }
                    handleClose={ () => this.setState({ showItemEditModal: false }) }
                    planningItemUpdated={ this.planningItemUpdated.bind(this) }
                    planningItemDeleted={ this.planningItemDeleted.bind(this) }
                    showParent
                />

                <DateRangeSwitcher
                    baseUrl={ baseUrl }
                    previousUrl={ baseUrl + "/" + previousDate.format("YYYY-MM-DD") }
                    nextUrl={ baseUrl + "/" + nextDate.format("YYYY-MM-DD") }
                    currentMoment={ date }
                    previousMoment={ previousDate }
                    nextMoment={ nextDate }
                    displayFormat="dddd DD MMMM YYYY"
                    viewType="day"
                />

                { planningItems === null || users === null || (this.props.planningContext.showAbsences && !this.state.absenceItems) ? (
                    <React.Fragment>
                        <hr/>
                        <Loading/>
                    </React.Fragment>
                ) : (
                    <table className="table results" style={{ tableLayout: "fixed" }}>
                        <thead className="thead-light">
                            { mechanicCount && (
                                <tr className="text-center tr-vertical-border">
                                    <th style={{ width: "250px" }}/>
                                    <th colSpan={ mechanicCount }>Monteur</th>
                                    { users.length !== mechanicCount && (
                                        <th colSpan={ users.length - mechanicCount }>Kantoor</th>
                                    )}
                                </tr>
                            )}
                            <tr className="tr-sticky">
                                <th className="text-left" style={{ width: "250px" }}>Planning Item</th>
                                { users.map((user) => {
                                    const name = user.shortName && user.shortName.length > 0 ? user.shortName : user.name;
                                    const warning = warningUserIds.includes(user.id);
                                    const classNames = ["text-center"];
                                    if(warning && this.state.planningItems.length > 0) {
                                        classNames.push("table-warning");
                                    }
                                    return (
                                        <th className={ classNames.join(" ") } key={ user.id }>
                                            <span className="text-vertical">
                                                { name }
                                            </span>
                                        </th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                        { this.props.planningContext.showAbsences && (
                            <PlanningDayCalendarAbsenceRow
                                users={ users }
                                absenceItems={ this.state.absenceItems }
                            />
                        )}
                            { planningItems.map((planningItem) => (
                                <PlanningDayCalendarRow
                                    key={ planningItem.id }
                                    planningItem={ planningItem }
                                    users={ users }
                                    onClickPlanningItem={ (planningItem) => this.setState({ showItemEditModal: true, editModalPlanningItem: planningItem }) }
                                    onPlanningItemUpdated={ this.planningItemUpdated.bind(this) }
                                    onError={ (error) => { this.setState({ error }) }}
                                    warningUserIds={ [] }
                                />
                            ))}
                        </tbody>
                    </table>
                )}
            </React.Fragment>
        );
    }
}

export default withRouter(withUsersContext(withPlanningContext(PlanningDayCalendar)));
